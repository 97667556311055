import React, { useEffect, useState, useRef } from "react";
import home from "../Assets/Images/home_icon.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { toInteger } from "lodash";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { ThreeDots } from "react-loader-spinner";
import share from "../Assets/Images/sharevoter.svg";
import print from "../Assets/Images/print.svg";
import searchimg from "../Assets/Images/search.svg";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import MarathiFont from "../Utils/MarathiFont";
import MarathiFontBold from "../Utils/MarathiFontBold"; // Bold variant base64
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";

export default function VotersByAge() {
  const navigate = useNavigate();
  const [pollingAddress, setPollingAddress] = useState("");
  const [boothAddress, setBoothAddress] = useState("");
  const [fromAge, setFromAge] = useState("");
  const [toAge, setToAge] = useState("");
  const [, setBoothAddressList] = useState([]);
  const [, setPollingAddressList] = useState([]);
  const [candidateName, setCandidateName] = useState("");
  const [voterDetailsList, setVoterDetailsList] = useState([]);
  const [showVoterInfo, setShowVoterInfo] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const [scrollMore, setScrollMore] = useState(true);
  const [votersListLoading, setVotersListLoading] = useState(false);
  const [, setTotalCount] = useState("");
  const [fromAgeError, setFromAgeError] = useState("");
  const [toAgeError, setToAgeError] = useState("");
  const [fromBoothNumber, setfromBoothNumber] = useState("");
  const [toBoothNumber, settoBoothNumber] = useState("");
  const [ageType, setAgeType] = useState("");
  const [genderType, setGenderType] = useState("");
  const [Age18To35Group, setAge18To35Group] = useState(0);
  const [Age36To60Group, setAge36To60Group] = useState(0);
  const [Age61To75Group, setAge61To75Group] = useState(0);
  const [maleCount, setMaleCount] = useState(0);
  const [femaleCount, setFemaleCount] = useState(0);
  const [thirdGenderCount, setThirdGenderCount] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const userId = localStorage.getItem("userId");
  const toast = useRef("");
  const url = localStorage.getItem("slugUrl");
  const authtoken = localStorage.getItem("token");
  // Load the fonts into vfs
  pdfMake.vfs = {
    ...pdfFonts.pdfMake.vfs, // Keep default fonts
    "AnekDevanagari-Regular.ttf": MarathiFont, // Base64 encoded normal font
    "AnekDevanagari-Bold.ttf": MarathiFontBold, // Base64 encoded bold font
  };

  // Set up custom fonts
  const fonts = {
    AnekDevanagari: {
      normal: "AnekDevanagari-Regular.ttf",
      bold: "AnekDevanagari-Bold.ttf", // Define the bold style
    },
  };

  const generateDocDefinition = () => {
    const tableRows = voterDetailsList.map((item) => [
      { text: item.slipNumber.toString(), font: "AnekDevanagari", fontSize: 8 },
      { text: `${item.firstName}${item.middleName}  ${item.lastName}`, font: "AnekDevanagari", fontSize: 8 },
      { text: item.voterId.toString(), font: "AnekDevanagari", fontSize: 8 },
      { text: item.boothNumber.toString(), font: "AnekDevanagari", fontSize: 8 },
      { text: item.wordName.toString(), font: "AnekDevanagari", fontSize: 8 },
      { text: item.age.toString(), font: "AnekDevanagari", lineHeight: 0.8, fontSize: 7 },
      { text: item.gender.toString(), font: "AnekDevanagari", lineHeight: 0.8, fontSize: 7 },
    ]);

    return {
      content: [
        {
          text: "वयानुसार मतदार",
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 10],
          font: "AnekDevanagari",
        },
        {
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: [35, 115, 59, 40, 100, 30, 30],
            body: [
              [
                { text: "अनु क्र.", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "नाव", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "व्होटर आयडी", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "भाग क्र.", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "प्रभाग", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "वय", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "लिंग", bold: true, font: "AnekDevanagari", fontSize: 9 },
              ],
              ...tableRows,
            ],
          },
          layout: "lightHorizontalLines",
        },
      ],
      defaultStyle: {
        font: "AnekDevanagari",
      },
    };
  };

  const handlePrint = () => {
    const docDefinition = generateDocDefinition();
    pdfMake.createPdf(docDefinition, null, fonts).print();
  };

  useEffect(() => {
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      let candidateName = decoded.UserName;
      setCandidateName(candidateName);
      getBoothAddresses(decoded.UserName);
      getPollingBoothList(decoded.UserName);
      onSearchClick(1, true);
    }
  }, []);

  const getBoothAddresses = (candidateName) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getboothaddress?candidateName=${url}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
    };

    axios(config)
      .then((response) => {
        let boothAddressList = response.data.response;
        setBoothAddressList(boothAddressList);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
      })
      .finally(() => {});
  };

  const onDashboardclick = () => {
    navigate("/candidatedashboard");
  };

  const onFromNameChange = (e) => {
    setFromAge(e.target.value);
  };

  const onToNameChange = (e) => {
    setToAge(e.target.value);
  };

  const checkValidation = () => {
    let returnValue = true;
    setFromAgeError("");
    setToAgeError("");

    if (ageType === "") {
      if (fromAge == null || fromAge === "") {
        setFromAgeError("Please enter from age");
        returnValue = false;
      } else if (fromAge < 18) {
        setFromAgeError("Please enter an age greater than or equal to 18.");
        returnValue = false;
      }

      if (toAge == null || toAge === "") {
        setToAgeError("Please enter to age");
        returnValue = false;
      }
    }

    return returnValue;
  };

  const getvoterinfobyage = (candidateName, pageno, replace) => {
    // e.preventDefault();
    if (isApiCallInProgress) return;
    if (replace) {
      setVoterDetailsList([]);
      setTotalCount(0);
    }
    setIsApiCallInProgress(true);
    setVotersListLoading(true);
    const data = {
      boothAddress: boothAddress,
      pollingAddress: pollingAddress,
      fromValue: toInteger(fromAge),
      toValue: toInteger(toAge),
      isGetAllValue: true,
      searchPageNumber: pageno,
      searchResultCount: 20,
      FromBoothNumber: fromBoothNumber,
      ToBoothNumber: toBoothNumber,
      gender: genderType,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getvoterinfobyage?candidateName=${url}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setVotersListLoading(false);
        if (response.data.response.length > 0) {
          let searchedList = replace ? response.data.response : [...voterDetailsList, ...response.data.response];
          setVoterDetailsList(searchedList);
          setTotalCount(response.data.response[0].totalCount);
          setAge18To35Group(response.data.response[0].age18To35Count);
          setAge36To60Group(response.data.response[0].age36To60Count);
          setAge61To75Group(response.data.response[0].age61To75Count);
          setMaleCount(response.data.response[0].maleCount);
          setFemaleCount(response.data.response[0].femaleCount);
          setThirdGenderCount(response.data.response[0].thirdGenderCount);
          setIsDataLoaded(true);
        }
        setShowVoterInfo(true);
        if (response.data.response.length >= 0) {
          setScrollMore(true);
        } else {
          setScrollMore(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
        setIsApiCallInProgress(false);
        setVotersListLoading(false);
      })
      .finally(() => {
        setIsApiCallInProgress(false);
      });
  };

  const onSearchClick = (pageno, replace) => {
    if (isApiCallInProgress) return;
    setIsApiCallInProgress(true);
    setVotersListLoading(true);
    const data = {
      boothAddress: boothAddress,
      pollingAddress: pollingAddress,
      fromValue: toInteger(fromAge),
      toValue: toInteger(toAge),
      isGetAllValue: true,
      searchPageNumber: pageno,
      searchResultCount: 20,
      FromBoothNumber: parseInt(fromBoothNumber) || 1,
      ToBoothNumber: parseInt(toBoothNumber) || 1,
      gender: genderType,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getvoterinfobyage?candidateName=${url}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setVotersListLoading(false);
        if (response.data.response.length > 0) {
          let searchedList = replace ? response.data.response : [...voterDetailsList, ...response.data.response];
          setVoterDetailsList(searchedList);
          setTotalCount(response.data.response[0].totalCount);
          setAge18To35Group(response.data.response[0].age18To35Count);
          setAge36To60Group(response.data.response[0].age36To60Count);
          setAge61To75Group(response.data.response[0].age61To75Count);
          setMaleCount(response.data.response[0].maleCount);
          setFemaleCount(response.data.response[0].femaleCount);
          setThirdGenderCount(response.data.response[0].thirdGenderCount);
          setIsDataLoaded(true);
        }
        setShowVoterInfo(true);
        if (response.data.response.length > 0) {
          setScrollMore(true);
        } else {
          setScrollMore(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
        setIsApiCallInProgress(false);
        setVotersListLoading(false);
      })
      .finally(() => {
        setIsApiCallInProgress(false);
      });
  };
  const onShowmoreClicked = () => {
    if (!isApiCallInProgress && scrollMore) {
      const nextPageNumber = pageNumber + 1;
      setPageNumber(nextPageNumber);
      onSearchClick(nextPageNumber, false);
    }
  };

  const onSelectFromAddressOfPolling = (e) => {
    var fromboothNo = e.target.value;
    setfromBoothNumber(fromboothNo);
  };
  const onSelectToAddressOfPolling = (e) => {
    var toBoothNo = e.target.value;
    settoBoothNumber(toBoothNo);
  };

  const getPollingBoothList = (candidateNm) => {
    setVotersListLoading(true);
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/GetDropDownValue?candidateName=${url}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
    };

    axios(config)
      .then((response) => {
        setVotersListLoading(false);
        if (response.data.response.length > 0) {
          setPollingAddressList(response.data.response);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
        setVotersListLoading(false);
      })
      .finally(() => {});
  };

  const onselectGender = (e) => {
    setGenderType(e.target.value);
  };

  const savevotesbyageData = (values) => {
    let data = JSON.stringify({
      CandaidateId: userId,
      VotersByAgeFile: "VotersByAgeFile.pdf",
    });

    savevoters(data).then((res) => {
      if (res.data) {
        if (res.data.statusCode === 401) {
          toast.current?.show({
            severity: "error",
            summary: res.data.message,
            life: 3000,
          });
        } else {
          const docDefinition = generateDocDefinition();

          // Generate the PDF as a blob and give it a filename
          pdfMake.createPdf(docDefinition, null, fonts).getBlob(async (blob) => {
            const fileName = "VotersByAgeFile.pdf"; // Set the desired filename

            // Call the onFileUpload function to upload the file and check its success
            const uploadSuccess = await onFileUpload(blob, fileName);

            // Call getPoolingbooth only if the upload was successful
            if (uploadSuccess) {
              const poolingboothSuccess = await getvotersData();

              // Call handleShare only if getPoolingbooth was successful
              if (poolingboothSuccess) {
                handleShare();
              }
            }
          });
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Something went wrong, please contact your admin",
          life: 3000,
        });
      }
    });
  };

  const savevoters = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/CandidateInfo`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authtoken,
        },
      })
      .then((res) => res)
      .catch((err) => {
        if (err.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
      });
  };

  const getvotersData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/GetCandidateInfo?CandaidateId=${userId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authtoken,
        },
      });

      // Assuming the API returns 200 for a successful response
      if (response.status === 200) {
        handleShare(response.data.votersByAgeFile);
        return true; // Return true on successful call
      } else {
        throw new Error("Failed to fetch polling booth data");
      }
    } catch (err) {
      if (err.response.status === 401) {
        const loginURL = sessionStorage.getItem("candidateLoginURL");
        if (loginURL) {
          // Redirect back to the candidate's login page
          window.location.href = loginURL;
        } else {
          // Fallback to a default login page if the URL is not stored
          window.location.href = process.env.REACT_APP_PROD_API_URL;
        }
      }
      return false; // Return false if the call fails
    }
  };

  const uploadFiles = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/UploadCandidateFile`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + authtoken,
        },
      })
      .then((res) => res)
      .catch((err) => {
        if (err.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
      });
  };

  const onFileUpload = async (fileBlob, fileName) => {
    const formData = new FormData();
    formData.append("votersByAgeFile", fileBlob, fileName); // Append blob with filename
    formData.append("CandaidateId", userId); // Candidate ID

    try {
      const response = await uploadFiles(formData);
      if (response && response.status === 200) {
        toast.current?.show({
          severity: "success",
          summary: "फाइल यशस्वीरित्या शेअर केली",
          life: 3000,
        });
        return true; // Return true on successful upload
      } else {
        throw new Error("फाइल अपलोड अयशस्वी");
      }
    } catch (error) {
      console.error("Upload error:", error);
      toast.current?.show({
        severity: "error",
        summary: "फाइल अपलोड अयशस्वी",
        life: 3000,
      });
      return false; // Return false on failure
    }
  };

  const handleShare = (response) => {
    const uploadedFileURL = response;

    // Generate the WhatsApp link with the file URL
    const message = "Please find the PDF here: ";
    const whatsappURL = `https://wa.me/?text=${encodeURIComponent(message)}%0A${encodeURIComponent(uploadedFileURL)}`;

    // Open WhatsApp Web with the pre-filled message
    window.open(whatsappURL, "_blank");
  };

  return (
    <div className="app main-container-login voter-by-age-page">
      <Toast ref={toast} />
      <div className="header">
        <div className="row mt-2">
          <div className="col-10 d-flex align-items-center">
            <Button
              icon="pi pi-chevron-left"
              severity="secondary"
              onClick={() => {
                onDashboardclick();
              }}
              className="back-button" // Apply the custom class
            />
            <label htmlFor="Constituency" className="form-label Polling text-light labelDashboard marLeftHeader mt-2">
              वयानुसार मतदार{" "}
            </label>
          </div>
          <div className="col-2 text-end mt-2">
            <img
              src={home}
              alt="Whatsapp"
              className="home-img float-end"
              onClick={onDashboardclick}
              style={{ marginBottom: "4px", marginLeft: "10px" }}
            />
          </div>
        </div>

        <div className="row  mt-1">
          <div className="col-6">
            <label className="polling-booth-label whiteFont">पासून</label>
            <input
              autoFocus
              type="number"
              className={`form-control form-select mb-1 text-14 polling-select ${
                fromBoothNumber === "" || fromBoothNumber === null || fromBoothNumber === 0 ? "option-as-placeholder" : ""
              }`}
              placeholder="मतदान केंद्र"
              name="fromBoothNumber"
              value={fromBoothNumber}
              onChange={(e) => onSelectFromAddressOfPolling(e)}
            />
          </div>
          <div className="col-6">
            <label className="polling-booth-label whiteFont">पर्यंत</label>
            <input
              type="number"
              placeholder="मतदान केंद्र"
              className={`form-control form-select mb-1 text-14 polling-select ${
                toBoothNumber === "" || toBoothNumber === null || fromBoothNumber === "" || fromBoothNumber === 0 ? "option-as-placeholder" : ""
              }`}
              name="toBoothNumber"
              value={toBoothNumber}
              onChange={(e) => onSelectToAddressOfPolling(e)}
            />
          </div>
        </div>
        <div className="row  mt-1">
          <div className="col-6">
            <label className="polling-booth-label whiteFont">लिंग</label>
            <select
              name="Confirmation"
              id="confirmation"
              value={genderType}
              onChange={(e) => onselectGender(e)}
              className={`form-control form-select mb-1 text-14 polling-select ${
                genderType === "" || genderType == null ? "option-as-placeholder" : ""
              }`}
              disabled={fromBoothNumber === "" || fromBoothNumber === null || fromBoothNumber === 0}
            >
              <option className="option-as-placeholder" value="">
                महिला / पुरुष / एकत्र
              </option>
              <option value="महिला">महिला</option>
              <option value="पुरुष">पुरुष</option>
              <option value="तृतिय पंथी">तृतिय पंथी</option>
            </select>
          </div>
          <div className="col-3">
            <label className="polling-booth-label whiteFont">वय पासून</label>
            <input
              type="number"
              className="form-control form-select mb-1 text-14 polling-select"
              name="fromAge"
              value={fromAge}
              disabled={fromBoothNumber === "" || fromBoothNumber === null || fromBoothNumber === 0}
              onChange={(e) => onFromNameChange(e)}
            />
          </div>
          <div className="col-3">
            <label className="polling-booth-label whiteFont">वय पर्यंत</label>
            <input
              autoFocus
              type="number"
              disabled={fromBoothNumber === "" || fromBoothNumber === null || fromBoothNumber === 0}
              className="form-control form-select mb-1 text-14 polling-select"
              name="toAge"
              value={toAge}
              onChange={(e) => onToNameChange(e)}
            />
          </div>
        </div>
        <div className="col-6">
          <span className="text-12 text-danger text-center mt-1">{fromAgeError}</span>
        </div>
        <div className="col-6">
          <span className="text-12 text-danger text-center mt-1">{toAgeError}</span>
        </div>
        <div className="row mt-2">
          <div className="col-4 mt-1">
            <button
              className="btn show-btn w-auto text-15 padMiddle"
              disabled={
                fromBoothNumber === "" ||
                fromBoothNumber === null ||
                fromBoothNumber === "" ||
                toBoothNumber === 0 ||
                fromAge === null ||
                toAge === null ||
                fromAge === "" ||
                toAge === ""
              }
              onClick={() => onSearchClick(1, true)}
            >
              शोधा
              <img src={searchimg} alt="Search" style={{ marginLeft: "5px" }} />
            </button>
          </div>
          <div className="col-8 text-end">
            <button className="btn w-auto text-15 text-white" onClick={handlePrint}>
              <img src={print} alt="Print" style={{ marginLeft: "5px" }} /> प्रिंट
            </button>
            <button className="btn w-auto text-15 text-white" onClick={() => {}}>
              <img src={share} alt="Share" style={{ marginLeft: "5px" }} onClick={savevotesbyageData} /> शेअर
            </button>
          </div>
        </div>

        {isDataLoaded ? (
          <div className="card birthdayMarginTop" style={{ backgroundColor: "#383838", borderRadius: "30px" }}>
            <div className="row ">
              <div className="col-4 total-details-SurnameList mt-2" style={{ padding: "2px", borderRadius: "15px 0 0 15px", textAlign: "center" }}>
                <span>स्त्री &nbsp;{femaleCount}</span>
              </div>
              <div className="col-4 total-details-SurnameList mt-2" style={{ padding: "2px", borderRadius: "15px 0 0 15px", textAlign: "center" }}>
                <span>पुरुष &nbsp;{maleCount}</span>
              </div>
              <div className="col-4 total-details-SurnameList mt-2" style={{ padding: "2px", borderRadius: "15px 0 0 15px", textAlign: "center" }}>
                <span>तृतीय लिंग &nbsp;{thirdGenderCount}</span>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {showVoterInfo ? (
          <BottomScrollListener onBottom={onShowmoreClicked}>
            {(scrollRef) => (
              <div
                className="mt-2"
                ref={scrollRef}
                style={{ height: "650px", overflow: "auto", padding: "4px 8px 4px 8px", backgroundColor: "#3e3e3e", borderRadius: "10px" }}
              >
                <div className="fixedPageNew">
                  <div className="card voter-list-card birthdayMarginTop areaWisePadding">
                    <div className="row ">
                      <div className="col-2 pr-0 total-details-Surname" style={{ paddingLeft: "8px" }}>
                        <span>अनु क्र.</span>
                      </div>
                      <div className="col-4 total-details-Surname" style={{ paddingLeft: "8px" }}>
                        <span className="text-left">नाव</span>
                      </div>
                      <div className="col-4 total-details-Surname">
                        <span className="text-left"> व्होटर आयडी</span>
                      </div>
                      <div className="col-2 ps-0 total-details-Surname">
                        <span> लिंग/वय</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {
                  voterDetailsList.length === 0 ?
                   <div  className="d-flex justify-content-center text-white mt-40"> <span style={{marginTop:"50px"}}>कोणताही डेटा उपलब्ध नाही</span></div> :<></>
                } */}
                {Object.entries(
                  voterDetailsList.reduce((acc, item) => {
                    const key = `${item.boothNumber}-${item.addressOfPollingStation}`;
                    if (!acc[key]) {
                      acc[key] = { boothNumber: item.boothNumber, address: item.addressOfPollingStation, voters: [] };
                    }
                    acc[key].voters.push(item);
                    return acc;
                  }, {})
                ).map(([key, group], index) => (
                  <div key={index}>
                    <div className="row d-flex mx-auto header-cell kendraList mt-2">
                      <div className="col-12 ps-0 total-details-SurnameList mt-2" style={{ fontSize: "14px" }}>
                        <span className="voter-list-answer text-justify">
                          {group.boothNumber} - {group.address}
                        </span>
                      </div>
                    </div>

                    {/* Render each voter under the corresponding booth */}
                    {group.voters.map((item, number) => (
                      <div
                        className={
                          group.voters.length === number + 1 && votersListLoading === false
                            ? "card mb-2 voter-list-card last-card-bottom birthdayMarginTop"
                            : "card voter-list-card birthdayMarginTop"
                        }
                        key={number}
                        // style={}
                      >
                        <div className="row d-flex mx-auto">
                          <div className="col-2 ps-0 pr-0 total-details-Surname">
                            <span className="voter-list-answer text-justify">{item.slipNumber}</span>
                          </div>
                          <div className="col-4 ps-0 total-details-Surname  " style={{ paddingLeft: "8px" }}>
                            <span className="voter-list-answer">
                              {item.lastName} {item.firstName} {item.middleName}
                            </span>
                          </div>
                          <div className="col-4 ps-0 total-details-Surname">
                            <span className="voter-list-answer text-justify">{item.voterId}</span>
                          </div>
                          <div className="col-2 ps-0 total-details-Surname   ">
                            <span className="voter-list-answer text-end">
                              {item.gender === "महिला" ? "म." : item.gender === "पुरुष" ? "पु." : item.gender}/{item.age}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </BottomScrollListener>
        ) : null}

        {votersListLoading ? (
          <div className="d-flex justify-content-center">
            <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
