import home from "../Assets/Images/home_icon.svg";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { ThreeDots } from "react-loader-spinner";
import searchimg from "../Assets/Images/search.svg";
import { Button } from "primereact/button";

export default function VoterByCast() {
  const navigate = useNavigate();
  const [totalCount, setTotalCount] = useState("");
  const [, setPollingAddressList] = useState([]);
  const [, setBoothAddress] = useState("");
  const [, setIsChecked] = useState(false);
  const [, setBoothAddressList] = useState([]);
  const [pollingAddress, setPollingAddress] = useState("");
  const [, setCandidateName] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const [scrollMore, setScrollMore] = useState(true);
  const [votersListLoading, setVotersListLoading] = useState(false);
  const [showVoterInfo, setShowVoterInfo] = useState(false);
  const [voterDetailsList, setVoterDetailsList] = useState([]);
  const [caste, setCaste] = useState("");
  const [, setPollingBoothError] = useState("");
  const [fromBoothNumber, setfromBoothNumber] = useState("");
  const [toBoothNumber, settoBoothNumber] = useState("");
  const url = localStorage.getItem("slugUrl");
  const authtoken = localStorage.getItem("token");

  useEffect(() => {
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      let candidateName = decoded.UserName;
      setCandidateName(candidateName);
      getPollingAddresses(decoded.UserName);
      getBoothAddresses(decoded.UserName);
    }
  }, []);

  const onSelectFromAddressOfPolling = (e) => {
    var fromboothNo = e.target.value;
    setfromBoothNumber(fromboothNo);
  };

  const checkValidation = () => {
    let returnValue = true;
    setPollingBoothError("");

    if (pollingAddress == null || pollingAddress == "") {
      setPollingBoothError("कृपया मतदान केंद्र निवडा");
      returnValue = false;
    }

    return returnValue;
  };

  const getBoothAddresses = (candidateName) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getboothaddress?candidateName=${url}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
    };

    axios(config)
      .then((response) => {
        let boothAddressList = response.data.response;
        setBoothAddressList(boothAddressList);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
      })
      .finally(() => {});
  };

  const getPollingAddresses = (candidateName) => {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/GetDropDownValue?candidateName=${url}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
    };

    axios(config)
      .then((response) => {
        let pollingAddressList = response.data.response;
        setPollingAddressList(pollingAddressList);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
      })
      .finally(() => {});
  };
  const onDashboardclick = () => {
    navigate("/candidatedashboard");
  };

  const onSelectOfAddressOfPolling = (item) => {
    setPollingAddress(item);
  };
  const onSelectOfAddressBooth = (item) => {
    setBoothAddress(item);
  };
  const onselectCaste = (item) => {
    setCaste(item);
  };
  const onAllCheckboxSelect = (e) => {
    setIsChecked(e.target.checked);
    setPollingAddress("");
    setBoothAddress("");
  };
  const onSearchClick = (pageno, replace) => {
    if (isApiCallInProgress) return;
    if (replace) {
      setVoterDetailsList([]);
      setTotalCount(0);
    }
    setIsApiCallInProgress(true);
    setVotersListLoading(true);

    const data = {
      fromValue: 0,
      toValue: 0,
      searchPageNumber: pageno,
      searchResultCount: 20,
      caste: caste,
      fromBoothNumber: parseInt(fromBoothNumber) || 0,
      ToBoothNumber: parseInt(toBoothNumber) || 0,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getvoterinfobycaste?candidateName=${url}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setVotersListLoading(false);
        if (response.data.response.length > 0) {
          let searchedList = replace ? response.data.response : [...voterDetailsList, ...response.data.response];
          setVoterDetailsList(searchedList);
          setTotalCount(response.data.response[0].totalCount);
        }
        setShowVoterInfo(true);
        if (response.data.response.length > 0) {
          setScrollMore(true);
        } else {
          setScrollMore(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
        setIsApiCallInProgress(false);
        setVotersListLoading(false);
      })
      .finally(() => {
        setIsApiCallInProgress(false);
      });
  };
  const onShowmoreClicked = () => {
    if (!isApiCallInProgress && scrollMore) {
      const nextPageNumber = pageNumber + 1;
      setPageNumber(nextPageNumber);
      onSearchClick(nextPageNumber, false);
    }
  };

  const onSelectToAddressOfPolling = (e) => {
    var toBoothNo = e.target.value;
    settoBoothNumber(toBoothNo);
  };

  return (
    <div className="app main-container-login">
      <div className="header">
        <div className="row mt-2">
          <div className="col-10 d-flex align-items-center">
            <Button
              icon="pi pi-chevron-left"
              severity="secondary"
              onClick={() => {
                onDashboardclick();
              }}
              className="back-button"
            />
            <label htmlFor="Constituency" className="form-label Polling text-light labelDashboard marLeftHeader mt-2">
              जात निहाय मतदार
            </label>
          </div>
          <div className="col-2 text-end mt-2">
            <img
              src={home}
              alt="Whatsapp"
              className="home-img float-end"
              onClick={onDashboardclick}
              style={{ marginBottom: "4px", marginLeft: "10px" }}
            />
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-6">
            <label className="polling-booth-label whiteFont">पासून</label>
            <input
              autoFocus
              type="number"
              className={`form-control form-select mb-1 text-14 polling-select ${
                fromBoothNumber === "" || fromBoothNumber === null || fromBoothNumber === 0 ? "option-as-placeholder" : ""
              }`}
              placeholder="मतदान केंद्र"
              name="fromBoothNumber"
              value={fromBoothNumber}
              onChange={(e) => onSelectFromAddressOfPolling(e)}
            />
          </div>
          <div className="col-6">
            <label className="polling-booth-label whiteFont">पर्यंत</label>

            <input
              type="number"
              placeholder="मतदान केंद्र"
              className={`form-control form-select mb-1 text-14 polling-select ${
                toBoothNumber === "" || toBoothNumber === null || fromBoothNumber === "" || fromBoothNumber === 0 ? "option-as-placeholder" : ""
              }`}
              name="toBoothNumber"
              value={toBoothNumber}
              onChange={(e) => onSelectToAddressOfPolling(e)}
            />
          </div>
          <div className="col-12 mt-2">
            <select
              name="Caste"
              value={caste || ""}
              className={`form-control form-select mb-1 text-14 polling-select ${caste == "" || caste == null ? "option-as-placeholder" : ""}`}
              id="Caste"
              onChange={(e) => onselectCaste(e.target.value)}
              disabled={fromBoothNumber === "" || fromBoothNumber === null || fromBoothNumber === 0}
            >
              <option className="option-as-placeholder" value="">
                जात / धर्म निवडा
              </option>
              <option value="हिंदू">हिंदू</option>
              <option value="शीख">शीख</option>
              <option value="जैन">जैन</option>
              <option value="बौद्ध">बौद्ध</option>
              <option value="मुस्लिम">मुस्लिम</option>
              <option value="ख्रिश्चन">ख्रिश्चन</option>
            </select>
          </div>
        </div>

        <div className="row  d-flex align-items-center">
          <div className="col-7 mt-2 ">
            <span className="form-label fw-bold voter-list-label whiteFont">एकूण संख्या : {totalCount} </span>
          </div>
          <div className="row mt-2">
            <div className="col-12 d-flex justify-content-between align-items-center">
              <div className="d-flex">
                <button
                  className="btn show-btn w-auto text-15 me-2 padMiddle"
                  onClick={() => onSearchClick(1, true)}
                  disabled={
                    fromBoothNumber === "" ||
                    fromBoothNumber === null ||
                    toBoothNumber === "" ||
                    toBoothNumber === null ||
                    toBoothNumber === 0 ||
                    fromBoothNumber === 0
                  }
                >
                  शोधा
                  <img src={searchimg} alt="Search" style={{ marginLeft: "5px" }} />
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* {votersListLoading ? (
          <div className="d-flex justify-content-center">
          <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
          </div>
        ) : showVoterInfo && voterDetailsList.length === 0 ? (
          <div className="d-flex justify-content-center text-white">कोणताही डेटा उपलब्ध नाही</div>
        ) :  */}
         {votersListLoading && isApiCallInProgress === false? (
          <div className="d-flex justify-content-center">
            <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
          </div>
        ) : (
          <></>
        )}
        {showVoterInfo ? (
                  <BottomScrollListener onBottom={onShowmoreClicked}>
                    {(scrollRef) => (
                      <div className="mt-2" ref={scrollRef} style={{ height: "650px", overflowY: "auto", overflowX: "hidden" }}>
                        {voterDetailsList.map((item, number) => (
                          <div
                            className={
                              voterDetailsList.length == number + 1 && votersListLoading == false
                                ? " card mb-2 voter-list-card last-card-bottom"
                                : "card mb-2 voter-list-card"
                            }
                            key={number}
                          >
                            <div className="row mx-auto">
                              <div className="col-6 ps-0 mb-1 mt-2">
                                <span className="cast-list"> अनु.क्र : &nbsp;{item.slipNumber}</span>
                              </div>
                              <div className="col-6 p-0 mb-1 mt-2">
                                <span className="cast-list">Voter ID :&nbsp;{item.voterId}</span>
                              </div>
                              <div className="col-6 ps-0 mb-1">
                                <span className="cast-list">भाग क्र.</span>
                              </div>
                              <div className="col-6 ps-0 mb-1">
                                <span className="cast-list">{item.boothNumber}</span>
                              </div>
                              <div className="col-6 ps-0 mb-1">
                                <span className="cast-list">नाव</span>
                              </div>
                              <div className="col-6 ps-0 mb-1">
                                <span className="cast-list">
                                  {item.lastName} {item.firstName} {item.middleName}
                                </span>
                              </div>
                              <div className="col-6 ps-0 mb-1">
                                <span className="cast-list">घर क्रमांक</span>
                              </div>
                              <div className="col-6 ps-0 mb-1">
                                <span className="cast-list">{item.houseNumber}</span>
                              </div>
                              <div className="col-3 ps-0 mb-1">
                                <span className="cast-list">वय</span>
                              </div>
                              <div className="col-3 ps-0 mb-1 ">
                                <span className="cast-list">{item.age}</span>
                              </div>
                              <div className="col-3 ps-0 mb-1">
                                <span className="cast-list"> लिंग</span>
                              </div>
                              <div className="col-3 ps-0 mb-1">
                                <span className="cast-list">{item.gender}</span>
                              </div>
                              <div className="col-12 ps-0 text-end">
                                <button
                                  className="cast-list"
                                  style={{
                                    borderRadius: "8px",
                                    borderColor: "white",
                                    backgroundColor:
                                      item.caste === "हिंदू"
                                        ? "#FB832E"
                                        : item.caste === "शीख"
                                        ? "#E84A5A"
                                        : item.caste === "जैन"
                                        ? "#FFE607"
                                        : item.caste === "बौद्ध"
                                        ? "#0010BF"
                                        : item.caste === "मुस्लिम"
                                        ? "#009E07"
                                        : item.caste === "ख्रिश्चन"
                                        ? "#87B5E2"
                                        : "#FFFFFF",
                                  }}
                                >
                                  {item.caste}
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </BottomScrollListener>
                ) : null}
 {  isApiCallInProgress === true? (
          <div className="d-flex justify-content-center">
            <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
          </div>
        ) : (
          <></>
        )}
     </div>
    </div>
  );
}
