import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

const PrivateRoute = ({ element, allowedRoutes }) => {
  const { isAuthenticated, isExistsInCandidate } = useAuth();

  // If user is not authenticated, redirect to login
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // If user is authenticated but isExistsInCandidate is false and the route is not allowed, redirect to /displayvoterlist
  if (!isExistsInCandidate && !allowedRoutes.includes(window.location.pathname)) {
    return <Navigate to="/displayvoterlist" />;
  }

  // Otherwise, allow access to the private route
  return element;
};

export default PrivateRoute;
