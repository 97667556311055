import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import home from "../Assets/Images/home_icon.svg";
import searchimg from "../Assets/Images/search.svg";
import share from "../Assets/Images/sharevoter.svg";
import print from "../Assets/Images/print.svg";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { ThreeDots } from "react-loader-spinner";
import confirmedImg from "../Assets/Images/Vector.svg";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { Toast } from "primereact/toast";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import MarathiFont from "../Utils/MarathiFont";
import MarathiFontBold from "../Utils/MarathiFontBold"; // Bold variant base64
import { Button } from "primereact/button";

export default function PollingAgent() {
  const navigate = useNavigate();
  const [pollingAddressList, setPollingAddressList] = useState([]);
  const [pollingAddress, setPollingAddress] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [fullName, setFullName] = useState("");
  const [serialNumber, setSerialNumber] = useState(0);
  const [pollingListLoading, setPollingListLoading] = useState(false);
  const [candidateName, setCandidateName] = useState("");
  const [pollingAgentList, setPollingAgentList] = useState([]);
  const [pollingBoothError, setPollingBoothError] = useState("");
  const [votingDoneLoading, setVotingDoneLoading] = useState(false);
  const [voterUniqueId, setVoterUniqueId] = useState("");
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false); // Prevent multiple API calls
  const [scrollMore, setScrollMore] = useState(true); // Track if more data is available
  const [showAddressPollingStation, setShowAddressPollingStation] = useState([]);
  const [address, setAddress] = useState("");
  const url = localStorage.getItem("slugUrl");
  const [pollingStats, setPollingStats] = useState({
    totalConfirmedVoter: 0,
    totalDoneConfirmVoter: 0,
    totalDoneVoter: 0,
    totalVoter: 0,
    pollingStation: "",
  });
  const [toBoothNumber, settoBoothNumber] = useState("");
  const userId = localStorage.getItem("userId");
  const toast = useRef("");
  const [voterDetailsList, setVoterDetailsList] = useState([]);
  const authtoken = localStorage.getItem("token");
  const onDashboardclick = () => {
    navigate("/candidatedashboard");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token != "" && token != null) {
      const decoded = jwtDecode(token);
      // getPollingAddresses(decoded.UserName);
      getPollingAgentList(pageNumber, false, decoded.UserName); // Load initial data
      setCandidateName(decoded.UserName);
    } else {
      navigate("/login");
    }
  }, []);

  const getPollingAgentList = (pageno, replace) => {
    if (isApiCallInProgress) return; // Prevent multiple API calls at the same time

    setIsApiCallInProgress(true); // Mark API call as in progress
    // Prepare the data payload
    let VoterName = fullName && fullName.trim() ? fullName.trim().split(" ") : [""];
    setPollingListLoading(true);
    const data = {
      firstName: VoterName[0] || "",
      middleName: VoterName[1] || "",
      lastName: VoterName[2] || "",
      FromBoothNumber: parseInt(toBoothNumber) || 1,
      slipNumber: serialNumber ? parseInt(serialNumber) : null,
      searchPageNumber: pageno, // Current page number for pagination
      searchResultCount: 20,
    };

    // API request configuration
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/pollingagent?candidateName=${url}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        const newResults = response.data.response;
        setPollingListLoading(false);
        // Update scrollMore based on new results
        if (newResults.length === 0) {
          setScrollMore(false); // No more data to load
        } else {
          setScrollMore(true); // More data available
        }

        // Use functional state update to ensure correct appending
        if (newResults.length > 0) {
          setPollingAgentList(
            (prevList) =>
              replace
                ? newResults // Replace list if replace is true
                : [...prevList, ...newResults] // Append if replace is false
          );

          // Update polling stats (assuming the first result contains stats)
          setPollingStats({
            totalDoneConfirmVoter: newResults[0].totalDoneConfirmVoter,
            totalDoneVoter: newResults[0].totalDoneVoter,
            totalConfirmedVoter: newResults[0].totalConfirmedVoter,
            totalVoter: newResults[0].totalVoter,
            pollingStation: newResults[0].addressOfPollingStation,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
      })
      .finally(() => {
        setPollingListLoading(false); // Hide loading spinner
        setIsApiCallInProgress(false); // Mark API call as complete
      });
  };

  // Load more pages on scroll
  const onShowmoreClicked = () => {
    if (scrollMore && !isApiCallInProgress) {
      const nextPageNumber = pageNumber + 1;
      setPageNumber(nextPageNumber);
      getPollingAgentList(nextPageNumber, false, candidateName); // false ensures data is appended
    }
  };

  const ConfirmationTemplate = (rowData) => {
    return (
      <span>
        <img
          src={rowData.votingConfirmation === "होय" ? confirmedImg : ""}
          className=""
          alt={rowData.votingConfirmation === "होय" ? "Confirmed" : ""}
        />
      </span>
    );
  };

  const onSelectOfAddressOfPolling = (e) => {
    var fromboothNo = e.target.value;
    settoBoothNumber(fromboothNo);
  };

  const onSerialNumberChange = (e) => {
    setSerialNumber(e.target.value);
  };
  const onNameChange = (value) => {
    let replaceValue;
    if (value != fullName || fullName == "") {
      replaceValue = true;
    } else {
      replaceValue = false;
    }
    setFullName(value);
    setPageNumber(1);
  };
  const onEnterClick = (e) => {
    if (e.keyCode == 13) {
      getPollingAgentList(pageNumber, true, candidateName);
    }
  };
  const checkValidation = () => {
    let returnValue = true;
    setPollingBoothError("");

    if (pollingAddress == null || pollingAddress == "") {
      setPollingBoothError("कृपया मतदान केंद्र निवडा");
      returnValue = false;
    }

    return returnValue;
  };

  const onSearchClick = () => {
    // if (checkValidation()) {

    getPollingAgentList(1, 1, candidateName);
    // }
  };
  const nameTemplate = (rowData) => {
    return (
      <span>
        {rowData.firstName} {rowData.middleName} {rowData.lastName}{" "}
      </span>
    );
  };
  const onVotingDoneOrNotClick = (id) => {
    setVotingDoneLoading(true);
    setVoterUniqueId(id);

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/updateVoterDone?Id=${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
    };

    axios(config)
      .then((response) => {
        setPollingAgentList((prevResponses) =>
          prevResponses.map((response) => (response.id === id ? { ...response, isVotingDone: !response.isVotingDone } : response))
        );
        setVotingDoneLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
        setVotingDoneLoading(false);
      });
    updateVotingData();
  };

  const updateVotingData = () => {
    let VoterName = fullName && fullName.trim() ? fullName.trim().split(" ") : [""];
    const data = {
      firstName: VoterName[0] == null || VoterName[0] == undefined ? "" : VoterName[0],
      middleName: VoterName[1] == null || VoterName[1] == undefined ? "" : VoterName[1],
      lastName: VoterName[2] == null || VoterName[2] == undefined ? "" : VoterName[2],
      PollingAddress: address,
      slipNumber: serialNumber == "" || serialNumber == null ? 0 : parseInt(serialNumber),
      FromBoothNumber: parseInt(toBoothNumber) || 1,
      searchResultCount: 20,
      searchPageNumber: 1,
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/pollingagent?candidateName=${url}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
      data: data,
    };

    axios(config).then((response) => {
      if (response.data.response.length > 0) {
        setPollingStats((prevStats) => ({
          ...prevStats,
          totalDoneConfirmVoter: response.data.response[0]?.totalDoneConfirmVoter,
          totalDoneVoter: response.data.response[0]?.totalDoneVoter,
          totalConfirmedVoter: response.data.response[0]?.totalConfirmedVoter,
          totalVoter: response.data.response[0]?.totalVoter,
          showAddressPollingStation: response.data[0]?.addressOfPollingStation,
        }));
      }
    });
  };

  const savevotesData = (values) => {
    let data = JSON.stringify({
      CandaidateId: userId,
      PollingAgentFile: "PollingAgentFile.pdf",
    });

    savevoters(data).then((res) => {
      if (res.data) {
        if (res.data.statusCode === 401) {
          toast.current?.show({
            severity: "error",
            summary: res.data.message,
            life: 3000,
          });
        } else {
          const docDefinition = generateDocDefinition();

          // Generate the PDF as a blob and give it a filename
          pdfMake.createPdf(docDefinition, null, fonts).getBlob(async (blob) => {
            const fileName = "PollingAgentFile.pdf"; // Set the desired filename

            // Call the onFileUpload function to upload the file and check its success
            const uploadSuccess = await onFileUpload(blob, fileName);

            // Call getPoolingbooth only if the upload was successful
            if (uploadSuccess) {
              const poolingboothSuccess = await getvotersData();

              // Call handleShare only if getPoolingbooth was successful
              if (poolingboothSuccess) {
                handleShare();
              }
            }
          });
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Something went wrong, please contact your admin",
          life: 3000,
        });
      }
    });
  };

  const savevoters = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/CandidateInfo`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authtoken,
        },
      })
      .then((res) => res)
      .catch((err) => {
        if (err.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
      });
  };

  const getvotersData = async () => {
    try {
      const token = localStorage.getItem("token"); // Assuming you're storing the token in localStorage
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + authtoken,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/GetCandidateInfo?CandaidateId=${userId}`,
        { headers } // Pass the headers here
      );

      // Assuming the API returns 200 for a successful response
      if (response.status === 200) {
        handleShare(response.data?.pollingAgentFile);
        return true; // Return true on successful call
      } else {
        throw new Error("Failed to fetch polling booth data");
      }
    } catch (err) {
      if (err.response.status === 401) {
        const loginURL = sessionStorage.getItem("candidateLoginURL");
        if (loginURL) {
          // Redirect back to the candidate's login page
          window.location.href = loginURL;
        } else {
          // Fallback to a default login page if the URL is not stored
          window.location.href = process.env.REACT_APP_PROD_API_URL;
        }
      }
      return false; // Return false if the call fails
    }
  };

  const uploadFiles = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/UploadCandidateFile`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + authtoken,
        },
      })
      .then((res) => res)
      .catch((err) => err);
  };

  const onFileUpload = async (fileBlob, fileName) => {
    const formData = new FormData();
    formData.append("VotersByAgeFile", fileBlob, fileName); // Append blob with filename
    formData.append("CandaidateId", userId); // Candidate ID

    try {
      const response = await uploadFiles(formData);
      if (response && response.status === 200) {
        toast.current?.show({
          severity: "success",
          summary: "फाइल यशस्वीरित्या शेअर केली",
          life: 3000,
        });
        return true; // Return true on successful upload
      } else {
        throw new Error("फाइल अपलोड अयशस्वी");
      }
    } catch (error) {
      console.error("Upload error:", error);
      toast.current?.show({
        severity: "error",
        summary: "फाइल अपलोड अयशस्वी",
        life: 3000,
      });
      return false; // Return false on failure
    }
  };

  const handleShare = (response) => {
    const uploadedFileURL = response;

    // Generate the WhatsApp link with the file URL
    const message = "Please find the PDF here: ";
    const whatsappURL = `https://wa.me/?text=${encodeURIComponent(message)}%0A${encodeURIComponent(uploadedFileURL)}`;

    // Open WhatsApp Web with the pre-filled message
    window.open(whatsappURL, "_blank");
  };

  pdfMake.vfs = {
    ...pdfFonts.pdfMake.vfs, // Keep default fonts
    "AnekDevanagari-Regular.ttf": MarathiFont, // Base64 encoded normal font
    "AnekDevanagari-Bold.ttf": MarathiFontBold, // Base64 encoded bold font
  };

  // Set up custom fonts
  const fonts = {
    AnekDevanagari: {
      normal: "AnekDevanagari-Regular.ttf",
      bold: "AnekDevanagari-Bold.ttf", // Define the bold style
    },
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const generateDocDefinition = () => {
    const tableRows = pollingAgentList.map((item) => [
      { text: item.slipNumber.toString(), font: "AnekDevanagari", fontSize: 8 },
      { text: `${item.firstName}${item.middleName}  ${item.lastName}`, font: "AnekDevanagari", fontSize: 8 },
      { text: item.voterId.toString(), font: "AnekDevanagari", fontSize: 8 },
      { text: item.boothNumber.toString(), font: "AnekDevanagari", fontSize: 8 },
      //  { text: item.wordName.toString(), font: "AnekDevanagari", fontSize: 8 },
      { text: item.age.toString(), font: "AnekDevanagari", lineHeight: 0.8, fontSize: 7 },
      { text: item.gender.toString(), font: "AnekDevanagari", lineHeight: 0.8, fontSize: 7 },
      { text: item.caste, font: "AnekDevanagari", fontSize: 7 },
      { text: item.familyMemberCount, font: "AnekDevanagari", fontSize: 7 },
      { text: formatDate(item.birthDate), font: "AnekDevanagari", fontSize: 7 },
    ]);

    return {
      content: [
        {
          text: "पोलिंग एजन्ट",
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 10],
          font: "AnekDevanagari",
        },
        {
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: [35, 115, 59, 40, 20, 35, 30, 28, 49],
            body: [
              [
                { text: "अनु क्र.", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "नाव", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "व्होटर आयडी", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "भाग क्र.", bold: true, font: "AnekDevanagari", fontSize: 9 },
                //   { text: "प्रभाग", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "वय", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "लिंग", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "जात", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "सदस्य", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "जन्मदिनांक", bold: true, font: "AnekDevanagari", fontSize: 9 },
              ],
              ...tableRows,
            ],
          },
          layout: "lightHorizontalLines",
        },
      ],
      defaultStyle: {
        font: "AnekDevanagari",
      },
    };
  };

  const handlePrint = () => {
    const docDefinition = generateDocDefinition();
    pdfMake.createPdf(docDefinition, null, fonts).print();
  };

  const votingTemplate = (rowData) => {
    return (
      <div className="col-6 ps-0 pe-0  ">
        {votingDoneLoading && voterUniqueId == rowData.id ? (
          <div className="d-flex justify-content-center ">
            <ThreeDots color="#fff" height={30} width={30} />
          </div>
        ) : (
          <>
            <input
              type="checkbox"
              id={`votingConfirmationSwitch_${rowData.id}`}
              className="checkbox"
              onChange={() => onVotingDoneOrNotClick(rowData.id)}
              checked={rowData.isVotingDone}
            />
            <label
              htmlFor={`votingConfirmationSwitch_${rowData.id}`}
              className={`fw-bold Polling toggle ${
                rowData.isVotingDone ? "justify-content-start checked-toggle-span" : "justify-content-end unchecked-toggle-span"
              }`}
            >
              <span className="text-12" style={{ color: "gray" }}>
                {rowData.isVotingDone == true ? "झाले" : "नाही"}
              </span>
            </label>
          </>
        )}
      </div>
    );
  };
  return (
    <div className="app main-container-login">
      <Toast ref={toast} />
      <div className="header">
        <div className="row mt-2">
          <div className="col-10 d-flex align-items-center">
            <Button
              icon="pi pi-chevron-left"
              severity="secondary"
              onClick={() => {
                onDashboardclick();
              }}
              className="back-button"
            />
            <label htmlFor="Constituency" className="form-label Polling text-light labelDashboard marLeftHeader mt-2">
              पोलिंग एजन्ट
            </label>
          </div>
          <div className="col-2 text-end mt-2">
            <img
              src={home}
              alt="Whatsapp"
              className="home-img float-end"
              onClick={onDashboardclick}
              style={{ marginBottom: "4px", marginLeft: "10px" }}
            />
          </div>
        </div>
        <div className="row text-14">
          <div className="col-12 text-14">
            <input
              autoFocus
              type="number"
              className={`form-control form-select mb-1 text-14 polling-select ${
                toBoothNumber === "" || toBoothNumber === null || toBoothNumber === 0 ? "option-as-placeholder" : ""
              }`}
              placeholder="मतदान केंद्र"
              name="fromBoothNumber"
              value={toBoothNumber}
              onChange={(e) => onSelectOfAddressOfPolling(e)}
            />
            {/* <Dropdown
              name="pollingAddress"
              value={pollingAddress}
              onChange={(e) => {
                const selectedValue = e.target.value;
                const boothNumber = selectedValue.split("-")[0];

                onSelectOfAddressOfPolling(boothNumber, selectedValue);
              }}
              options={pollingAddressList.map((item) => ({
                label: `${item.boothNumber} `,
                value: `${item.boothNumber} `,
              }))}
              placeholder="मतदान केंद्र निवडा"
              className={`form-control form-select mb-1 text-14 polling-select ${
                pollingAddress == "" || pollingAddress == null ? "option-as-placeholder" : ""
              }`}
              filter
              // showClear
              emptyFilterMessage="कोणतेही परिणाम आढळले नाहीत"
              id="pollingAddress"
            /> */}
            <span className="text-danger text-center">{pollingBoothError}</span>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-4">
            <input
              type="text"
              onChange={onSerialNumberChange}
              value={serialNumber == 0 ? "" : serialNumber}
              className="form-control input-color"
              id="text"
              placeholder="अनु क्र. "
              onKeyDown={onEnterClick}
            ></input>
          </div>
          <div className="col-8">
            <input
              type="text"
              onChange={(e) => onNameChange(e.target.value)}
              value={fullName}
              className="form-control input-color"
              id="text"
              placeholder="नाव"
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-4 mt-1">
            <button className="btn show-btn w-auto text-15 padMiddle" onClick={onSearchClick} disabled={toBoothNumber === "" || toBoothNumber === ""}>
              {" "}
              शोधा
              <img src={searchimg} alt="Search" style={{ marginLeft: "5px" }} />
            </button>
          </div>
          <div className="col-8 text-end">
            <button className="btn w-auto text-15 text-white">
              <img src={print} alt="Search" style={{ marginLeft: "5px" }} onClick={handlePrint} /> प्रिंट
            </button>
            <button className="btn w-auto text-15 text-white">
              <img src={share} alt="Search" style={{ marginLeft: "5px" }} onClick={savevotesData} /> शेअर
            </button>
          </div>
        </div>
        <div className="card birthdayMarginTop" style={{ backgroundColor: "#383838", borderRadius: "30px" }}>
          <div className="row ">
            <div className="col-3 total-details-SurnameList mt-2" style={{ padding: "2px", borderRadius: "15px 0 0 15px", textAlign: "center" }}>
              <span style={{ marginTop: "10px", fontSize: "14px" }}>झालेले मतदान</span>
              <span>{pollingStats.totalDoneVoter ? pollingStats.totalDoneVoter : 0}</span>
            </div>
            <div className="col-3 total-details-SurnameList mt-2" style={{ padding: "2px", borderRadius: "15px 0 0 15px", textAlign: "center" }}>
              <span style={{ marginTop: "10px", fontSize: "14px" }}>कन्फर्म</span>
              <span>
                {pollingStats.totalDoneVoter ? pollingStats.totalDoneVoter : 0}/
                {pollingStats.totalConfirmedVoter ? pollingStats.totalConfirmedVoter : 0}
              </span>
            </div>
            <div className="col-3 total-details-SurnameList mt-2" style={{ padding: "2px", borderRadius: "15px 0 0 15px", textAlign: "center" }}>
              <span style={{ marginTop: "10px", fontSize: "14px" }}>न झालेले मतदान</span>
              <span>{pollingStats.totalVoter ? pollingStats.totalVoter - pollingStats.totalDoneVoter : 0}</span>
            </div>
            <div className="col-3 total-details-SurnameList mt-2" style={{ padding: "2px", borderRadius: "15px 0 0 15px", textAlign: "center" }}>
              <span style={{ marginTop: "10px", fontSize: "14px" }}>एकूण मतदान</span>
              <span>{pollingStats.totalVoter ? pollingStats.totalVoter : 0}</span>
            </div>
          </div>
        </div>
        {/* <div className="row mx-auto mt-2 ">
          <div className="col-3 total-details ">
            <span style={{ marginTop: "10px", fontSize: "14px" }}>झालेले मतदान</span>
            <span>{pollingStats.totalDoneVoter ? pollingStats.totalDoneVoter : 0}</span>
          </div>
          <div className="col-3 total-details">
            <span style={{ marginTop: "10px", fontSize: "14px" }}>कन्फर्म</span>
            <span>
              {pollingStats.totalDoneConfirmVoter ? pollingStats.totalDoneConfirmVoter : 0}/
              {pollingStats.totalConfirmedVoter ? pollingStats.totalConfirmedVoter : 0}
            </span>
          </div>
          <div className="col-3  total-details ">
            <span style={{ marginTop: "10px", fontSize: "14px" }}>न झालेले मतदान</span>
            <span>{pollingStats.totalVoter ? pollingStats.totalVoter - pollingStats.totalDoneVoter : 0}</span>
          </div>
          <div className="col-3  total-details ">
            <span style={{ marginTop: "10px", fontSize: "14px" }}>एकूण मतदान</span>
            <span>{pollingStats.totalVoter ? pollingStats.totalVoter : 0}</span>
          </div>
        </div> */}
        <BottomScrollListener onBottom={onShowmoreClicked}>
          {(scrollRef) => (
            <div
              className="mt-2"
              ref={scrollRef}
              style={{ height: "650px", overflow: "auto", padding: "0px 8px 4px 8px", backgroundColor: "#3e3e3e", borderRadius: "10px" }}
            >
              {pollingAgentList.length != 0 ? (
                <>
                  <div className="fixedPageNew">
                    <div className="row mx-auto">
                      <div className="col-12 total-details-pollingList pollingAgentList">
                        <div className="col-12 ps-0 total-details-SurnameList mt-2 mb-2" style={{ fontSize: "14px" }}>
                          <span className="voter-list-answer text-justify">{pollingStats.pollingStation}</span>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="row mx-auto">
                      <div className="col-2 pollingHeader-details  total-details-Surname ">
                        <span style={{ marginTop: "10px", fontSize: "14px" }}>अनु क्र.</span>
                      </div>
                      <div className="col-5 pollingHeader-details  total-details-Surname">
                        <span style={{ marginTop: "10px", fontSize: "14px" }}>नाव</span>
                      </div>
                      <div className="col-3 pollingHeader-details  total-details-Surname ">
                        <span style={{ marginTop: "10px", fontSize: "14px" }}>कन्फर्म</span>
                      </div>
                      <div className="col-2  pollingHeader-details   total-details-Surname"></div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              {pollingAgentList.length === 0 ? (
                <div className="d-flex justify-content-center text-white mt-40">
                  {" "}
                  <span style={{ marginTop: "50px" }}>कोणताही डेटा उपलब्ध नाही</span>
                </div>
              ) : (
                <></>
              )}
              {pollingAgentList.map((item, number) => (
                <div className="card mx-auto Polling-list-card" key={number}>
                  <div className="row  mx-auto">
                    <div className="col-2  pr-0 ">
                      <span className="voter-list-answer text-justify">{item.slipNumber}</span>
                    </div>
                    <div className="col-5  ps-0">
                      <span className="voter-list-answer">
                        {item.lastName} {item.firstName} {item.middleName}
                      </span>
                    </div>
                    <div className="col-3">{ConfirmationTemplate(item)}</div>
                    <div className="col-2">{votingTemplate(item)}</div>
                  </div>
                </div>
              ))}
              {pollingListLoading ? (
                <div className="d-flex justify-content-center">
                  <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
                </div>
              ) : null}
            </div>
          )}
        </BottomScrollListener>
      </div>
    </div>
  );
}
