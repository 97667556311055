import { Toast } from "primereact/toast";
import React, { useEffect, useState, useCallback, useRef } from "react";
import home from "../Assets/Images/home_icon.svg";
import { useNavigate } from "react-router-dom";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { QRCodeCanvas } from "qrcode.react";
import QRCode from "qrcode";
import moment from "moment";
import DatePicker from "react-datepicker";

const CandidateConfig = () => {
  const toast = useRef("");
  const navigate = useNavigate();
  const [photoFile, setPhotoFile] = useState(null);
  const [bannerFile, setBannerFile] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [photoFiles, setPhotoFiles] = useState(null);
  const [bannerFiles, setBannerFiles] = useState(null);
  const [logoFiles, setLogoFiles] = useState(null);
  const [audioFiles, setAudioFiles] = useState(null);
  const [candidatephoto, setCandidatePhoto] = useState(null);
  const [candidatephotos, setCandidatePhotos] = useState(null);
  const [userID, setUserID] = useState(null);
  const [name, setuserName] = useState(null);
  const [loading, setloading] = useState(false);
  const [socialLinks, setSocialLinks] = useState({
    faceBook: "",
    twitter: "",
    youTube: "",
    constituency: "",
    codeofconduct: "",
    fistName: "",
    middleName: "",
    lastName: "",
    partyName: "",
  });
  const [candidateData, setCandidateData] = useState(null);
  const [url, setUrl] = useState(null);
  const qrRef = useRef(null);
  const slughurl = localStorage.getItem("slugUrl");
  const authtoken = localStorage.getItem("token");

  const getCandidate = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/CandidateHistory?SlughUrl=${slughurl}`);
      setCandidateData(response.data); // Update state with the data
    } catch (err) {
      if (err.response?.status === 401) {
        const loginURL = sessionStorage.getItem("candidateLoginURL");
        if (loginURL) {
          window.location.href = loginURL;
        } else {
          window.location.href = process.env.REACT_APP_PROD_API_URL;
        }
      }
    }
  };

  const handleDownload = async () => {
    // Create a temporary canvas to generate the high-quality QR code
    const tempCanvas = document.createElement("canvas");

    try {
      // Use the qrcode library to draw the QR code on the canvas
      await QRCode.toCanvas(tempCanvas, url, { width: 900, margin: 2 });

      // Convert the canvas to a data URL and trigger the download
      const image = tempCanvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = image;
      link.download = "qrcode.png";
      link.click();
    } catch (error) {
      console.error("Error generating QR code", error);
    }
  };

  // Fetch candidate data when component mounts
  useEffect(() => {
    if (name) {
      getCandidate();
    }
  }, [name]);

  useEffect(() => {
    if (candidateData) {
      setSocialLinks({
        faceBook: candidateData.faceBook || "",
        twitter: candidateData.twitter || "",
        youTube: candidateData.youTube || "",
        slughUrl: candidateData.slughUrl || "",
        fistName: candidateData.firstName || "",
        middleName: candidateData.middleName || "",
        lastName: candidateData.lastName || "",
        partyName: candidateData.partyName || "",
      });
      setAudioFiles(candidateData.audio);
      setPhotoFiles(candidateData.photo);
      setBannerFiles(candidateData.banner);
      setLogoFiles(candidateData.logo);
      setCandidatePhoto(candidateData.candidatephoto);

      const baseUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;

      setUrl(`${baseUrl}/candidate?name=${slughurl}&&scanned=true`);
    }
  }, [candidateData]);

  const onDashboardclick = () => {
    navigate("/candidatedashboard");
  };

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    const userName = localStorage.getItem("userName");
    setUserID(userId);
    setuserName(userName);
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSocialLinks((prevSocialLinks) => ({ ...prevSocialLinks, [name]: value }));
  };

  const headerTemplate = (options) => {
    const { chooseButton } = options;
    return <div className="flex justify-content-between align-items-center">{chooseButton}</div>;
  };

  const onFileSelect = (fileType) => (event) => {
    if (!event?.files || event.files.length === 0) {
      toast.current?.show({
        severity: "error",
        summary: "No file selected.",
        life: 3000,
      });
      return;
    }

    const file = event.files[0];

    if (!file?.type.startsWith("image/") && fileType !== "audio") {
      toast.current?.show({
        severity: "error",
        summary: "Please select an image file only.",
        life: 3000,
      });
      return;
    }

    // Update state with the raw file object
    if (fileType === "audio") {
      setAudioFile(file);
    } else if (fileType === "photo") {
      setPhotoFile(file);
    } else if (fileType === "banner") {
      setBannerFile(file);
    } else if (fileType === "logo") {
      setLogoFile(file);
    } else if (fileType === "candidatephoto") {
      setCandidatePhoto(file);
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      if (fileType === "audio") {
        setAudioFiles(reader.result);
      } else if (fileType === "photo") {
        setPhotoFiles(reader.result);
      } else if (fileType === "banner") {
        setBannerFiles(reader.result);
      } else if (fileType === "logo") {
        setLogoFiles(reader.result);
      } else if (fileType === "candidatephoto") {
        setCandidatePhotos(reader.result);
      }
    };

    reader.readAsDataURL(file);
  };

  const uploadFiles = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/UploadFiles`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + authtoken,
        },
      })
      .then((res) => res)
      .catch((err) => err);
  };

  const onFileUpload = async (id) => {
    const formData = new FormData();
    if (logoFile) formData.append("logo", logoFile);
    if (photoFile) formData.append("photo", photoFile);
    if (bannerFile) formData.append("banner", bannerFile);
    if (audioFile) formData.append("audio", audioFile);
    if (candidatephoto) formData.append("candidatephoto", candidatephoto);
    formData.append("Id", userID);

    try {
      const response = await uploadFiles(formData);

      toast.current?.show({
        severity: "success",
        summary: "तपशील यशस्वीरित्या अपलोड केले",
        life: 3000,
      });
    } catch (error) {
      console.error("Upload error:", error);
      toast.current?.show({
        severity: "error",
        summary: "तपशील अपलोड करण्यात अयशस्वी",
        life: 3000,
      });
    }
  };

  const saveCandidate = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/CandidateHistory`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authtoken,
        },
      })
      .then((res) => res)
      .catch((err) => err);
  };

  const saveCandidateData = (values) => {
    setloading(true);
    let data = JSON.stringify({
      id: userID,
      Audio: audioFile?.name ? audioFile?.name : null,
      Photo: photoFile?.name ? photoFile?.name : null,
      Banner: bannerFile?.name ? bannerFile?.name : null,
      Logo: logoFile?.name ? logoFile?.name : null,
      FaceBook: socialLinks.faceBook,
      Twitter: socialLinks.twitter,
      YouTube: socialLinks.youTube,
      Constituency: socialLinks.constituency,
      CandidatePhoto: candidatephoto?.name ? candidatephoto?.name : null,
      codeofconduct: socialLinks.codeofconduct,
      firstName: socialLinks.fistName,
      middleName: socialLinks.middleName,
      lastName: socialLinks.lastName,
      partyName: socialLinks.partyName,
    });

    saveCandidate(data).then((res) => {
      if (res.data) {
        if (res.data.statusCode === 401) {
          toast.current?.show({
            severity: "error",
            summary: res.data.message,
            life: 3000,
          });
        } else {
          onFileUpload(res.data.data);
          setloading(false);
          navigate("/candidatedashboard");
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Something went wrong, please contact your admin",
          life: 3000,
        });
      }
    });
  };
  const shareOnWhatsApp = () => {
    const message = `उमेदवारासाठी URL: ${url}`;
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <>
      <div className="app main-container-login">
        <Toast ref={toast} />
        <div className="header">
          <div className="row mt-2">
            <div className="col-10">
              <label htmlFor="Constituency" className="form-label Polling text-light labelDashboard">
                उमेदवार कॉन्फिगरेशन स्क्रीन
              </label>
            </div>
            <div className="col-2 text-end">
              <img
                src={home}
                alt="Whatsapp"
                className="home-img float-end"
                onClick={onDashboardclick}
                style={{ marginBottom: "4px", marginLeft: "10px" }}
              />
            </div>
          </div>
        </div>
        <div className="container black-theme">
          <div class="row">
            <div className="col-6">
              <div>
                <label htmlFor="fistName" className="text-light mt-4 ">
                  पहिले नाव
                </label>
              </div>
              <InputText
                id="fistName"
                name="fistName"
                value={socialLinks.fistName}
                onChange={handleInputChange}
                placeholder="उदाहरण- श्रीमान अभिषेक"
                className="p-inputtext-dark"
              />
            </div>
            <div className="col-6">
              <div>
                <label htmlFor="middleName" className="text-light mt-4 ">
                  मधले नाव
                </label>
              </div>
              <InputText
                id="middleName"
                name="middleName"
                value={socialLinks.middleName}
                onChange={handleInputChange}
                placeholder="उदाहरण- रमेश"
                className="p-inputtext-dark"
              />
            </div>
            <div className="col-6">
              <div>
                <label htmlFor="lastName" className="text-light mt-4 ">
                  शेवटचे नाव
                </label>
              </div>
              <InputText
                id="lastName"
                name="lastName"
                value={socialLinks.lastName}
                onChange={handleInputChange}
                placeholder="उदाहरण- कुलकर्णी"
                className="p-inputtext-dark"
              />
            </div>
            <div className="col-6">
              <div>
                <label htmlFor="codeofconduct" className="text-light mt-4">
                  आचारसंहिता दिनांक
                </label>
              </div>
              <DatePicker
                selected={socialLinks.codeofconduct ? moment(socialLinks.codeofconduct, "DD/MM/YYYY").toDate() : null} // Ensure this is a valid Date object or null
                onChange={(date) =>
                  handleInputChange({
                    target: {
                      name: "codeofconduct",
                      value: moment(date).format("DD/MM/YYYY"), // Pass formatted date
                    },
                  })
                }
                dateFormat="dd/MM/yyyy" // Matching the placeholder format
                className="p-inputtext-dark form-control" // Add additional form control class if needed
                placeholderText="आचारसंहिता दिनांक" // Placeholder text
                style={{
                  padding: "12px",
                  backgroundColor: "#121212",
                  color: "#ffffff",
                  border: "1px solid #121212",
                }}
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={200}
              />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              {" "}
              <h5 className="text-light mt-4">आवाजाची क्लिप अपलोड करा</h5>
              <FileUpload
                name="audioClip"
                accept="audio/*"
                maxFileSize={1000000}
                mode="basic"
                onSelect={onFileSelect("audio")}
                headerTemplate={headerTemplate}
                chooseLabel="ऑडिओ निवडा"
                uploadLabel="अपलोड करा"
                cancelLabel="रद्द करा"
                className="p-mb-3 file-upload-dark"
              />
              {audioFiles && (
                <div className="mt-3">
                  <h6 className="text-light">Uploaded Audio:</h6>
                  <audio controls>
                    <source src={audioFiles} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              )}
            </div>
            <div class="col-6">
              {" "}
              <h5 className="text-light mt-4">फोटो अपलोड करा</h5>
              <FileUpload
                name="photo"
                accept="image/*"
                maxFileSize={2000000}
                mode="basic"
                onSelect={onFileSelect("photo")}
                headerTemplate={headerTemplate}
                chooseLabel="फोटो निवडा"
                uploadLabel="अपलोड करा"
                cancelLabel="रद्द करा"
                className="p-mb-3 file-upload-dark"
              />
              {photoFiles && (
                <div className="mt-3">
                  <h6 className="text-light">Uploaded Photo:</h6>
                  <img src={photoFiles} alt="Uploaded" style={{ maxWidth: "10%" }} />
                </div>
              )}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              {" "}
              <h5 className="text-light mt-4">बॅनर अपलोड करा</h5>
              <FileUpload
                name="banner"
                accept="image/*"
                maxFileSize={2000000}
                mode="basic"
                onSelect={onFileSelect("banner")}
                headerTemplate={headerTemplate}
                chooseLabel="बॅनर निवडा"
                uploadLabel="अपलोड करा"
                cancelLabel="रद्द करा"
                className="p-mb-3 file-upload-dark"
              />
              {bannerFiles && (
                <div className="mt-3">
                  <h6 className="text-light">Uploaded Banner:</h6>
                  <img src={bannerFiles} alt="Uploaded Banner" style={{ maxWidth: "10%" }} />
                </div>
              )}
            </div>
            <div class="col-6">
              {" "}
              <h5 className="text-light mt-4">लोगो अपलोड करा</h5>
              <FileUpload
                name="logo"
                accept="image/*"
                maxFileSize={500000}
                mode="basic"
                onSelect={onFileSelect("logo")}
                headerTemplate={headerTemplate}
                chooseLabel="लोगो निवडा"
                uploadLabel="अपलोड करा"
                cancelLabel="रद्द करा"
                className="p-mb-3 file-upload-dark"
              />
              {logoFiles && (
                <div className="mt-3">
                  <h6 className="text-light">Uploaded Logo:</h6>
                  <img src={logoFiles} alt="Uploaded Logo" style={{ maxWidth: "10%" }} />
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div class="col-6">
              {" "}
              <h5 className="text-light mt-4">उमेदवार फोटो अपलोड करा</h5>
              <FileUpload
                name="candidatephoto"
                accept="image/*"
                maxFileSize={2000000}
                mode="basic"
                onSelect={onFileSelect("candidatephoto")}
                headerTemplate={headerTemplate}
                chooseLabel="उमेदवार फोटो निवडा"
                uploadLabel="अपलोड करा"
                cancelLabel="रद्द करा"
                className="p-mb-3 file-upload-dark"
              />
              {candidatephotos && (
                <div className="mt-3">
                  <h6 className="text-light">Uploaded Photo:</h6>
                  <img src={candidatephotos} alt="Uploaded" style={{ maxWidth: "10%" }} />
                </div>
              )}
            </div>
            <div className="col-6">
              <div>
                <label htmlFor="constituency" className="text-light mt-4 ">
                  मतदारसंघ
                </label>
              </div>
              <InputText
                id="constituency"
                name="constituency"
                value={socialLinks.constituency}
                onChange={handleInputChange}
                placeholder="उदाहरण- २८२ सांगली"
                className="p-inputtext-dark"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div>
                <label htmlFor="partyName" className="text-light mt-4 ">
                पक्षाचे नाव निवडा
                </label>
              </div>
              <InputText
                id="partyName"
                name="partyName"
                value={socialLinks.partyName}
                onChange={handleInputChange}
                placeholder="उदाहरण- काँग्रेस"
                className="p-inputtext-dark"
              />
            </div>
          </div>

          <div className="mt-4">
            <h5 className="text-light">सोशल मीडिया लिंक्स</h5>
            <div className="p-fluid">
              <div className="p-field">
                <label htmlFor="faceBook" className="text-light">
                  Facebook
                </label>
                <InputText
                  id="faceBook"
                  name="faceBook"
                  value={socialLinks.faceBook}
                  onChange={handleInputChange}
                  placeholder="Enter Facebook URL"
                  className="p-inputtext-dark"
                />
              </div>
              <div className="p-field">
                <label htmlFor="twitter" className="text-light mt-3">
                  Twitter
                </label>
                <InputText
                  id="twitter"
                  name="twitter"
                  value={socialLinks.twitter}
                  onChange={handleInputChange}
                  placeholder="Enter Twitter URL"
                  className="p-inputtext-dark"
                />
              </div>
              <div className="p-field">
                <label htmlFor="linkedin" className="text-light mt-3">
                  YouTube
                </label>
                <InputText
                  id="youTube"
                  name="youTube"
                  value={socialLinks.youTube}
                  onChange={handleInputChange}
                  placeholder="Enter YouTube URL"
                  className="p-inputtext-dark"
                />
              </div>
            </div>

            <h5 className="text-light mt-4">उमेदवारासाठी URL तयार करा</h5>
            <div className="p-fluid">
              <div className="p-field">
                <label htmlFor="candidateUrl" className="text-light mt-3">
                  उमेदवारासाठी URL
                </label>
                <InputText
                  id="candidateUrl"
                  name="candidateUrl"
                  disabled={true}
                  value={url}
                  onChange={handleInputChange}
                  placeholder="Enter उमेदवारासाठी URL"
                  className="p-inputtext-dark"
                />
                <button onClick={shareOnWhatsApp} className="whatsappbtn btn-success mt-3">
                  शेअर करा
                </button>
                <div ref={qrRef}>
                  <QRCodeCanvas value={url} className="mt-3" />
                </div>
                <button onClick={handleDownload} className="whatsappbtn btn-success mt-3">
                  क्यूआर कोड डाउनलोड करा
                </button>
              </div>
            </div>
            <Button disabled={loading} label="सबमिट करा" icon="pi pi-check" className="p-button-success mt-3" onClick={saveCandidateData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateConfig;
