import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Header from "./Header";
import Back from "../Assets/Images/backbtn.svg";
import WhiteWPImage from "../Assets/Images/white-wp.svg";

export default function SearchResult(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [showNotResultFound, setShowNotResultFound] = useState(false);
  const searchResultList = location.state.searchList;
  const CandidateName = location.state.CandidateName;
  const { searchList = [], selectedLanguage = "Marathi" } = location.state || {};
  const [editFormData, setEditFormData] = useState({});
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (searchResultList.length > 0) {
      setShowNotResultFound(true);
    } else {
      setShowNotResultFound(false);
    }
  }, [selectedLanguage]);

  const getText = (key) => {
    const texts = {
      resultHeader: {
        English: "Search Results",
        Marathi: "शोध परिणाम",
      },
      slipNumber: {
        English: "Yadi Sr. No.",
        Marathi: "अनु. क्रमांक",
      },
      boothNumber: {
        English: "Booth No",
        Marathi: "बूथ क्रमांक",
      },
      fullName: {
        English: "Full Name",
        Marathi: "संपूर्ण नाव",
      },
      voterIdNo: {
        English: "Voter ID No.",
        Marathi: "मतदार ओळख क्रमांक",
      },
      age: {
        English: "Age",
        Marathi: "वय",
      },
      gender: {
        English: "Gender",
        Marathi: "लिंग",
      },
      sectionNo: {
        English: "Section No",
        Marathi: "विभाग क्रमांक",
      },
      addressOfPollingStation: {
        English: "Address of Polling Station",
        Marathi: "मतदान केंद्राचा पत्ता",
      },
      confirm: {
        English: "Confirm",
        Marathi: "पुष्टी",
      },
      share: {
        English: "Share",
        Marathi: "शेअर करा",
      },
      noResultFound: {
        English: "No Result Found",
        Marathi: "परिणाम आढळले नाहीत",
      },
      searchMore: {
        English: "Search More",
        Marathi: "अधिक शोधा",
      },
      edit: {
        English: "Edit",
        Marathi: "संपादन करा",
      },
      save: {
        English: "Save",
        Marathi: "जतन करा",
      },
      mobileNo: {
        English: "Mobile No.",
        Marathi: "मोबाइल क्रमांक",
      },
      caste: {
        English: "Caste",
        Marathi: "जात",
      },
      birthdate: {
        English: "Birthdate",
        Marathi: "जन्मतारीख",
      },
    };
    return texts[key][selectedLanguage];
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setEditFormData({
      ...editFormData,
      [index]: {
        ...editFormData[index],
        [name]: value,
      },
    });
  };

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };

  const handleSubmit = async (e, index) => {
    e.preventDefault();
    handleEditToggle();
    const data = {
      id: searchResultList[index].id,
      WorkerId: searchResultList[index].WorkerId == undefined ? 0 : searchResultList[index].WorkerId,
      wordName: editFormData[index]?.wordName || searchResultList[index].wordName,
      fatherName: editFormData[index]?.fatherName || searchResultList[index].fatherName,
      husbandName: editFormData[index]?.husbandName || searchResultList[index].husbandName,
      district: editFormData[index]?.district || searchResultList[index].district,
      taluka: editFormData[index]?.taluka || searchResultList[index].taluka,
      town: editFormData[index]?.town || searchResultList[index].town,
      caste: editFormData[index]?.caste || searchResultList[index].caste,
      mobileNo: editFormData[index]?.mobileNo || searchResultList[index].mobileNo,
      firstName: editFormData[index]?.firstName || searchResultList[index].firstName,
      middleName: editFormData[index]?.middleName || searchResultList[index].middleName,
      lastName: editFormData[index]?.lastName || searchResultList[index].lastName,
      boothNumber: editFormData[index]?.boothNumber || searchResultList[index].boothNumber,
      slipNumber: editFormData[index]?.slipNumber || searchResultList[index].slipNumber,
      fullName:
        editFormData[index]?.fullName ||
        `${searchResultList[index].firstName} ${searchResultList[index].middleName} ${searchResultList[index].lastName}`,
      voterId: editFormData[index]?.voterId || searchResultList[index].voterId,
      age: editFormData[index]?.age || searchResultList[index].age,
      gender: editFormData[index]?.gender || searchResultList[index].gender,
      sectionNoAndName: editFormData[index]?.sectionNoAndName || searchResultList[index].sectionNoAndName,
      addressOfPollingStation: editFormData[index]?.addressOfPollingStation || searchResultList[index].addressOfPollingStation,
      selectedLanguage,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/UpdateVoter?selectedLanguage=${selectedLanguage}`,
      data: data,
    };

    try {
      const response = await axios(config);
      if (response.data && response.data.response === "Success") {
        const updatedSearchResultList = [...searchResultList];
        updatedSearchResultList[index] = {
          ...updatedSearchResultList[index],
          ...editFormData[index],
        };
        searchResultList(updatedSearchResultList);
        setEditMode(false);
      } else {
        console.log("Error updating data:", response.data.responseMessage);
      }
    } catch (error) {
      if (error.response.status === 401) {
        const loginURL = sessionStorage.getItem("candidateLoginURL");
        if (loginURL) {
          // Redirect back to the candidate's login page
          window.location.href = loginURL;
        } else {
          // Fallback to a default login page if the URL is not stored
          window.location.href = process.env.REACT_APP_PROD_API_URL;
        }
      }
    }
  };

  const handlesearchmore = () => {
    navigate(`/candidate?name=${CandidateName}`);
  };

  const onBackClick = () => {
    navigate(`/candidate?name=${CandidateName}`);
  };

  const handleDashboardClick = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <div className="app">
        <Header />
        <div className="row">
          <div className="col-6 d-flex">
            <img src={Back} alt="Back button" className="back-btn-img" onClick={onBackClick} />
            <div className="searchresult-text text-center">{getText("resultHeader")}</div>
          </div>
        </div>
        {showNotResultFound ? (
          <div className="container main-container" style={{ paddingTop: "0px" }}>
            {searchResultList.map((item, index) => (
              <div className="card voter-info-card" key={index}>
                <div className="row">
                  <div className="col-6">
                    <label>{getText("boothNumber")}</label>
                    <div>
                      <h6>{item.boothNumber}</h6>
                    </div>
                  </div>
                  <div className="col-6">
                    <label>{getText("slipNumber")}</label>
                    <div>
                      <h6>{item.slipNumber}</h6>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-8">
                    <label>{getText("fullName")}</label>
                  </div>
                </div>
                <div>
                  <h6>{item.firstName + " " + item.middleName + " " + item.lastName}</h6>
                </div>
                <div className="row">
                  <div className="col-6">
                    <label>{getText("voterIdNo")}</label>
                    <div>
                      <h6>{item.voterId}</h6>
                    </div>
                  </div>
                  <div className="col-3">
                    <label>{getText("age")}</label>
                    {editMode ? (
                      <input
                        type="text"
                        name="age"
                        value={editFormData[index]?.age || item.age}
                        onChange={(e) => handleInputChange(e, index)}
                        style={{ border: "1px solid #D9D9D9", borderRadius: "4px", width: "100%" }}
                      />
                    ) : (
                      <input
                        type="text"
                        name="age"
                        value={editFormData[index]?.age || item.age}
                        onChange={(e) => handleInputChange(e, index)}
                        style={{ border: "none", borderRadius: "4px", width: "100%" }}
                      />
                    )}
                  </div>
                  <div className="col-3">
                    <label>{getText("gender")}</label>
                    {editMode ? (
                      <input
                        type="text"
                        name="gender"
                        value={editFormData[index]?.gender || item.gender}
                        onChange={(e) => handleInputChange(e, index)}
                        style={{ border: "1px solid #D9D9D9", borderRadius: "4px", width: "100%" }}
                      />
                    ) : (
                      <input
                        type="text"
                        name="gender"
                        value={editFormData[index]?.gender || item.gender}
                        onChange={(e) => handleInputChange(e, index)}
                        style={{ border: "none", borderRadius: "4px", width: "100%" }}
                      />
                    )}
                  </div>
                </div>
                {editMode && (
                  <>
                    <div className="row">
                      <div className="col-6">
                        <label>{getText("mobileNo")}</label>
                        {editMode ? (
                          <input
                            type="text"
                            name="mobileNo"
                            value={editFormData[index]?.mobileNo || item.mobileNo}
                            onChange={(e) => handleInputChange(e, index)}
                            style={{ border: "1px solid #D9D9D9", borderRadius: "4px", width: "100%" }}
                          />
                        ) : (
                          <input
                            type="text"
                            name="mobileNo"
                            value={editFormData[index]?.mobileNo || item.mobileNo}
                            onChange={(e) => handleInputChange(e, index)}
                            style={{ border: "1px solid #D9D9D9", borderRadius: "4px", width: "100%" }}
                          />
                        )}
                      </div>
                      <div className="col-6">
                        <label>{getText("caste")}</label>
                        <input
                          type="text"
                          name="caste"
                          value={editFormData[index]?.caste || item.caste}
                          onChange={(e) => handleInputChange(e, index)}
                          style={{ border: "1px solid #D9D9D9", borderRadius: "4px", width: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <label>{getText("birthdate")}</label>
                        <input
                          type="text"
                          name="birthdate"
                          value={editFormData[index]?.birthdate || item.birthdate}
                          onChange={(e) => handleInputChange(e, index)}
                          style={{ border: "1px solid #D9D9D9", borderRadius: "4px", width: "100%" }}
                        />
                      </div>
                    </div>
                  </>
                )}
                <label>{getText("sectionNo")}</label>
                {editMode ? (
                  <input
                    type="text"
                    name="sectionNoAndName"
                    value={editFormData[index]?.sectionNoAndName || item.sectionNoAndName}
                    onChange={(e) => handleInputChange(e, index)}
                    style={{ border: "1px solid #D9D9D9", borderRadius: "4px", width: "100%" }}
                  />
                ) : (
                  <div>
                    <h6>{item.sectionNoAndName}</h6>
                  </div>
                )}
                <label>{getText("addressOfPollingStation")}</label>
                {editMode ? (
                  <input
                    type="text"
                    name="addressOfPollingStation"
                    value={editFormData[index]?.addressOfPollingStation || item.addressOfPollingStation}
                    onChange={(e) => handleInputChange(e, index)}
                    style={{ border: "1px solid #D9D9D9", borderRadius: "4px", width: "100%" }}
                  />
                ) : (
                  <div>
                    <h6>{item.addressOfPollingStation}</h6>
                  </div>
                )}
                <div className="d-inline-flex mt-3" style={{ marginTop: "10px" }}>
                  <div className="">
                    <button type="button" className="btn btn-primary confirm-btn d-flex">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                      <div style={{ marginLeft: "10px" }}>{getText("confirm")}</div>
                    </button>
                  </div>
                  <div className="">
                    <button type="button" className="btn btn-primary share-btn">
                      <img src={WhiteWPImage} alt="Share" className="white-wp-icon" />
                      {getText("share")}
                    </button>
                  </div>
                </div>
                <div className="d-inline-flex mt-3" style={{ marginTop: "10px" }}>
                  <button type="button" className="btn btn-secondary edit-btn" onClick={(e) => handleSubmit(e, index)}>
                    {editMode ? getText("save") : getText("edit")}
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="container  thanku-form justify-content-center">
            <div className="thanku-form">
              <div className="Thank-you-div-text">{getText("noResultFound")}</div>
              <div className="mt-5"></div>
            </div>
          </div>
        )}
        <div className="footer">
          <div className="mb-2 text-end mt-2" style={{ paddingRight: "25px" }}>
            <button type="button" className="btn btn-primary btn-back" onClick={handlesearchmore}>
              {getText("searchMore")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
