import React, { useEffect, useState } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { ThreeDots } from "react-loader-spinner";
import home from "../Assets/Images/home_icon.svg";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
export default function PollingAreaPLacesList() {
  const navigate = useNavigate();
  const [candidateName, setCandidateName] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const [scrollMore, setScrollMore] = useState(true);
  const [votersListLoading, setVotersListLoading] = useState(false);
  const [, setShowVoterInfo] = useState(false);
  const [voterDetailsList, setVoterDetailsList] = useState([]);
  const [townNameList, setTownNameList] = useState([]);
  const [, setTotalCount] = useState("");
  const [boothAddress, setBoothAddress] = useState("");
  const [pollingAddress, setPollingAddress] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [townName, setTownName] = useState("");
  const url = localStorage.getItem("slugUrl");
  const authtoken = localStorage.getItem("token");

  useEffect(() => {
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      let candidateName = decoded.UserName;
      setCandidateName(candidateName);
      getTownList(decoded.UserName);
    }
  }, []);

  const getTownList = (candidateName) => {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/GetPollinfAreaWiseTown?candidateName=${url}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
    };

    axios(config)
      .then((response) => {
        if (response?.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
        setTownNameList(response.data.response);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
      })
      .finally(() => {});
  };

  const onShowmoreClicked = () => {
    if (!isApiCallInProgress && scrollMore) {
      const nextPageNumber = pageNumber + 1;
      setPageNumber(nextPageNumber);
      onSearchClick(nextPageNumber, false);
    }
  };
  const onDashboardclick = () => {
    navigate("/candidatedashboard");
  };
  const onEnterClick = (e) => {
    if (e.keyCode == 13) {
      onSearchClick(1, true);
    }
  };
  const onSearchClick = (pageno, replace) => {
    if (isApiCallInProgress) return;
    if (replace) {
      setVoterDetailsList([]);
      setTotalCount(0);
    }
    setIsApiCallInProgress(true);
    setVotersListLoading(true);

    const data = {
      boothAddress: boothAddress,
      pollingAddress: pollingAddress,
      fromValue: 0,
      toValue: 0,
      isGetAllValue: isChecked,
      searchPageNumber: pageno,
      searchResultCount: 20,
      townName: townName,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getpollingareawisetown?candidateName=${url}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setVotersListLoading(false);
        if (response.data.response.length > 0) {
          let searchedList = replace ? response.data.response : [...voterDetailsList, ...response.data.response];
          setVoterDetailsList(searchedList);
          setTotalCount(response.data.response[0].totalCount);
        }
        setShowVoterInfo(true);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
        setIsApiCallInProgress(false);
        setVotersListLoading(false);
      })
      .finally(() => {
        setIsApiCallInProgress(false);
      });
  };
  return (
    <div className="app main-container-login">
      <div className="header">
        <div className="fixedPage">
          <div className="row mt-2">
            <div className="col-10 d-flex align-items-center">
              <Button
                icon="pi pi-chevron-left"
                severity="secondary"
                onClick={() => {
                  onDashboardclick();
                }}
                className="back-button"
              />
              <label htmlFor="Constituency" className="form-label Polling text-light labelDashboard marLeftHeader mt-2">
                मतदार संघातील गावे
              </label>
            </div>
            <div className="col-2 text-end mt-2">
              <img
                src={home}
                alt="Whatsapp"
                className="home-img float-end"
                onClick={onDashboardclick}
                style={{ marginBottom: "4px", marginLeft: "10px" }}
              />
            </div>
          </div>
        </div>

        {votersListLoading ? (
          <div className="d-flex justify-content-center">
            <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
          </div>
        ) : null}
        <div className="container-fluid">
          {townNameList.length > 0 ? (
            <div className="">
              {townNameList.map((item, index) => (
                <div className="col-md-6 mb-3" key={index}>
                  <div
                    className="card show-cursor"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      backgroundColor: "#3E3E3E",
                      color: "#fff",
                      padding: "10px",
                    }}
                  >
                    {/* First row: Town and Total Count */}
                    <div className="row count-text w-100 px-3 text-14">
                      <div className="col-6 text-14">
                        <span>{item.town}</span>
                      </div>
                      <div className="col-6 d-flex justify-content-end text-14">
                        <span style={{ color: "#929292" }}>एकूण&nbsp;&nbsp;</span>
                        <span>{item.totalCount}</span>
                      </div>
                    </div>

                    {/* Second row: Gender Counts */}
                    <div className="row count-text w-100 mt-2 px-3 text-14">
                      <div className="col-12 d-flex justify-content-between text-14">
                        <span style={{ color: "#929292" }}>पुरुष&nbsp;&nbsp;</span>
                        <span>{item.maleCount}</span>
                        <span style={{ color: "#929292" }}>स्त्री&nbsp;&nbsp;</span>
                        <span>{item.femaleCount}</span>
                        <span style={{ color: "#929292" }}>तृतिय&nbsp;&nbsp;</span>
                        <span>{item.thirdGenderCount}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              {" "}
              <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
