import React, { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import home from "../Assets/Images/home_icon.svg";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { ThreeDots } from "react-loader-spinner";
import _ from "lodash";
import { Toast } from "primereact/toast";
import moment from "moment";
import { useAuth } from "../Auth/AuthContext";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import DatePicker from "react-datepicker";
import share from "../Assets/Images/sharevoter.svg";

export default function DisplayVoterList() {
  const [searchSuggestionList, setSearchSuggestionList] = useState([]);
  const [fullName, setFullName] = useState("");
  const [candidateName, setCandidateName] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [scrollMore, setScrollMore] = useState(true);
  const [suggestionListLoading, setSuggestionListLoading] = useState(false);
  const [showEditMode, setShowEditMode] = useState(false);
  const [editVoterId, setEditVoterId] = useState("");
  const [showFamilyCards, setShowFamilyCards] = useState(false);
  const navigate = useNavigate();
  const toast = useRef("");
  const [, setWorkerId] = useState(0);
  const [candidateId, setCandidateId] = useState(0);
  const [familyList, setFamilyList] = useState([]);
  const [showFamilyListLoading, setShowFamilyListLoading] = useState(false);
  const [showSeperateFamilyDetailsCard, setShowSeperateFamilyDetailsCard] = useState(false);
  const [showFamilyDetailsId, setShowFamilyDetailsId] = useState("");
  const [addingFamilyLoading, setAddingFamilyLoading] = useState(false);
  const [primaryFamilyMemberId, setPrimaryFamilyMemberId] = useState(0);
  const [isLoading] = useState(false); // Flag to prevent multiple API calls
  const { isExistsInCandidate } = useAuth();
  const [activeIndex, setActiveIndex] = useState(0);
  const [, setFirstName] = useState("");
  const [, setLastName] = useState("");
  const [, setMiddleName] = useState("");
  const [voterId, setVoterId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [errorMessageForMobileNo, setErrorMessageForMobileNo] = useState("");
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const url = localStorage.getItem("slugUrl");
  const authtoken = localStorage.getItem("token");
  const [searchValue, setSearchValue] = useState("");
  const [originalFamilyList, setOriginalFamilyList] = useState(familyList); // Store original familyList data
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [candidateData, setCandidateData] = useState(null);

  const getCandidate = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/CandidateHistory?SlughUrl=${url}`);
      setCandidateData(response.data); // Update state with the data
    } catch (err) {
      if (err.response.status === 401) {
        const loginURL = sessionStorage.getItem("candidateLoginURL");
        if (loginURL) {
          // Redirect back to the candidate's login page
          window.location.href = loginURL;
        } else {
          // Fallback to a default login page if the URL is not stored
          window.location.href = process.env.REACT_APP_PROD_API_URL;
        }
      }
    }
  };

  const handleWhatsappClick = (item, number) => {
    const updateFamily = [];
    updateFamily.push({
      "अनु क्र": item.slipNumber,
      "ओळखपत्र क्र": item.voterId,
      "बूथ क्र": item.boothNumber,
      नाव: item.lastName + " " + item.firstName + " " + item.middleName,
      वय: item.age,
      लिंग: item.gender,
      पत्ता: item.wordName + " " + item.district,
      "मतदार केंद्र": item.addressOfPollingStation,
    });
    familyList.forEach((item) => {
      if (item.isFamilyMemberCheck == true) {
        updateFamily.push({
          "अनु क्र": item.familyInfo.slipNumber,
          "ओळखपत्र क्र": item.familyInfo.voterId,
          "बूथ क्र": item.familyInfo.boothNumber,
          नाव: item.familyInfo.lastName + " " + item.familyInfo.firstName + " " + item.familyInfo.middleName,
          वय: item.familyInfo.age,
          लिंग: item.familyInfo.gender,
          पत्ता: item.familyInfo.wordName + " " + item.familyInfo.district,
          "मतदार केंद्र": item.familyInfo.addressOfPollingStation,
        });
      }
    });

    const messageArray = updateFamily
      .map((item) => {
        const srNo = `अनु क्र: ${item["अनु क्र"]}`;
        const voterIdNumber = `ओळखपत्र क्र: ${item["ओळखपत्र क्र"]}`;
        const boothNumber = `बूथ क्र: ${item["बूथ क्र"]}`;
        const fullName = `नाव: ${item.नाव}`;
        const age = `वय: ${item.वय}`;
        const gender = `लिंग: ${item.लिंग}`;
        const address = `पत्ता: ${item.पत्ता}`;
        const boothName = `मतदार केंद्र: ${item["मतदार केंद्र"]}`;

        return `
${srNo},
${voterIdNumber},
${boothNumber},
${fullName},
${age},
${gender},
${address},
${boothName}
------------------------------------`;
      })
      .filter((item) => item.length > 0);

    const votingDateTime = `\nमतदानाची तारीख आणि वेळ : २०/११/२०२४ स. ७.३० ते सायं. ५.३०`;
    const faceBook = candidateData?.faceBook;
    const twitter = candidateData?.twitter;
    const webSite = candidateData?.webSite;
    const combinedMessage = `${messageArray} ${votingDateTime}`;
    const encodedMessage = encodeURIComponent(`${combinedMessage}\n\nFacebook: ${faceBook}\n\nTwitter: ${twitter}\n\nWebsite: ${webSite}`);

    window.open(`https://wa.me/?text=${encodedMessage}`, "_self");
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchValue(searchTerm);
    if (searchTerm === "") {
      setFamilyList(originalFamilyList);
    } else {
      const filteredList = familyList.filter((member) => {
        const searchTermLower = searchTerm.toLowerCase();

        // Concatenate full name fields
        const fullName = [member.familyInfo.lastName, member.familyInfo.firstName, member.familyInfo.middleName]
          .filter(Boolean)
          .join(" ")
          .toLowerCase(); // filter(Boolean) removes any undefined or null values

        const shortName = [member.familyInfo.lastName, member.familyInfo.firstName].filter(Boolean).join(" ").toLowerCase();

        const fullEnglishName = [member.familyInfo.englishLastName, member.familyInfo.englishFirstName, member.familyInfo.englishMiddleNAme]
          .filter(Boolean)
          .join(" ")
          .toLowerCase();

        const shortEnglishName = [member.familyInfo.englishLastName, member.familyInfo.englishFirstName].filter(Boolean).join(" ").toLowerCase();

        return (
          member.familyInfo.firstName?.toLowerCase().includes(searchTermLower) ||
          member.familyInfo.middleName?.toLowerCase().includes(searchTermLower) ||
          member.familyInfo.lastName?.toLowerCase().includes(searchTermLower) ||
          member.familyInfo.englishFirstName?.toLowerCase().includes(searchTermLower) ||
          member.familyInfo.englishMiddleNAme?.toLowerCase().includes(searchTermLower) ||
          member.familyInfo.englishLastName?.toLowerCase().includes(searchTermLower) ||
          // Check for full name match
          fullName.includes(searchTermLower.toLowerCase()) ||
          fullEnglishName.includes(searchTermLower.toLowerCase()) ||
          shortEnglishName.includes(searchTermLower.toLowerCase()) ||
          shortName.includes(searchTermLower.toLowerCase())
        );
      });

      setFamilyList(filteredList);
      setSearchValue("");
      setSearchValue(null);
    }
  };

  const checkVoterIdNumberValidation = (value) => {
    let isValid = true;
    let regex = new RegExp(/^[A-Za-z]{3}[0-9]{7}$/);
    if (regex.test(value) === true) {
      return (isValid = true);
    } else {
      return (isValid = false);
    }
  };

  const onChangeMobileNo = (value) => {
    const trimmedValue = value.trim();

    // Prevent entering more than 10 digits
    if (trimmedValue.length > 10) return;

    // Reset related states
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setFullName("");
    setSearchSuggestionList([]);
    setVoterId("");
    setErrorMessage("");

    // Call API when mobile number is null or empty
    if (!trimmedValue) {
      setMobileNo(""); // Update the state for an empty input
      onNameSearchSuggestion("", 1, true, "", 0, ""); // Trigger the search API when mobile number is empty
      setErrorMessageForMobileNo("");
      return; // Exit the function since we've handled the empty input case
    }

    // Validate the mobile number (only 10 digits)
    if (checkMobileNumberValidation(trimmedValue)) {
      setMobileNo(trimmedValue);

      // Call API only if the mobile number is exactly 10 digits
      if (trimmedValue.length === 10) {
        setSuggestionListLoading(true);
        onNameSearchSuggestion("", 1, true, "", 0, trimmedValue); // Trigger the search API
      }

      setErrorMessageForMobileNo("");
    } else {
      // Handle invalid input cases
      setMobileNo(trimmedValue);
      setErrorMessageForMobileNo("कृपया वैध मोबाइल नंबर टाका");
    }
  };

  const checkMobileNumberValidation = (value) => {
    let isValid = true;
    let regex = new RegExp(/^\d{10}$/);
    if (regex.test(value) === true) {
      return (isValid = true);
    } else {
      return (isValid = false);
    }
  };

  const onDashboardclick = () => {
    if (showEditMode) {
      setShowEditMode(false);
    } else {
      navigate("/candidatedashboard");
    }
  };

  useEffect(() => {
    getCandidate();
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      if (decoded.isExistsInCandidate === "True") {
        const candidateName = decoded.UserName;
        setCandidateName(candidateName);
        setCandidateId(decoded.Id);
        // Call the API on component mount for candidate
        onNameSearchSuggestion("", pageNumber, false, "", candidateName, "");
      } else {
        const workerId = decoded.Id;
        setWorkerId(workerId);
        onNameSearchSuggestion("", pageNumber, false, "", "", "");
      }
    }
  }, []); // Empty dependency array ensures this runs only once on mount

  const onChangeFirstName = (value) => {
    let replaceValue;
    if (value != fullName || fullName == "") {
      replaceValue = true;
    } else {
      replaceValue = false;
    }
    setSearchSuggestionList([]);
    setShowFamilyCards(false);
    setShowEditMode(false);
    setFullName(value);
    setPageNumber(1);
    if (value) {
      setSuggestionListLoading(true);
      debouncedFetchResults(value, 1, replaceValue);
    } else {
      setSuggestionListLoading(false);
      debouncedFetchResults(value, 1, replaceValue);
    }
    return () => {
      debouncedFetchResults.cancel();
    };
  };

  const onChangeVoterId = (value) => {
    if (value.length > 10) {
      return;
    }

    const value1 = value.trim();
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setFullName("");

    setSearchSuggestionList([]);
    if (checkVoterIdNumberValidation(value1)) {
      setVoterId(value1);
      onNameSearchSuggestion("", 1, true, value1, 0, "");
      setErrorMessage("");
    } else {
      if (value1 === "") {
        setVoterId(value1);
        onNameSearchSuggestion("", 1, true, "", 0, "");
        setErrorMessage("");
      } else {
        setVoterId(value1);
        setErrorMessage("कृपया वैध मतदार क्रमांक टाका");
      }
    }
  };

  const onShowmoreClicked = () => {
    if (scrollMore && !isLoading) {
      // Prevent triggering API if already loading
      const nextPageNumber = pageNumber + 1;
      setPageNumber(nextPageNumber);
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);

      if (decoded.isExistsInCandidate === "True") {
        let candidateName = decoded.UserName;
        setCandidateName(candidateName);
        onNameSearchSuggestion(fullName, nextPageNumber, false, "", candidateName, "");
      } else {
        let workerId = decoded.Id;
        setWorkerId(workerId);
        onNameSearchSuggestion(fullName, nextPageNumber, false, "", "", "");
      }
    }
  };

  const debouncedFetchResults = useCallback(
    _.debounce((searchValue, page, replaceValue) => {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      const candidateName = decoded.UserName;

      if (decoded.isExistsInCandidate === "True") {
        onNameSearchSuggestion(searchValue, page, replaceValue, "", candidateName, "");
      } else {
        onNameSearchSuggestion(searchValue, page, replaceValue, "", "", "");
      }
    }, 500),
    []
  );

  const onNameSearchSuggestion = (searchValue, page, replace, voterIdNumber, workerId, mobileNumber) => {
    if (isApiCallInProgress) return;

    setIsApiCallInProgress(true);

    let VoterName = searchValue && searchValue.trim() ? searchValue.trim().split(" ") : [""];
    setSuggestionListLoading(true);
    const token = localStorage.getItem("token");
    const decoded = jwtDecode(token);

    var workerValue = decoded.isExistsInWorker == "True" ? decoded.Id : 0;
    // var workerValue = worker === null || isNaN(parseInt(worker)) ? 0 : parseInt(worker);
    var candidateName1 = "";
    const slugUrl = localStorage.getItem("slugUrl");
    if (workerValue === null || workerValue === 0) {
      candidateName1 = slugUrl;
    } else {
      candidateName1 = "test";
    }

    const data = {
      firstName: VoterName[0] === null || VoterName[0] === undefined ? "" : VoterName[0],
      middleName: VoterName[1] === null || VoterName[1] === undefined ? "" : VoterName[1],
      lastName: VoterName[2] === null || VoterName[2] === undefined ? "" : VoterName[2],
      workerId: workerValue,
      searchResultCount: 20,
      searchPageNumber: page,
      voterId: voterIdNumber === null || undefined ? "" : voterIdNumber,
      mobileNo: mobileNumber === null || undefined ? "" : mobileNumber,
    };
    const selectLanguage = localStorage.getItem("selectedLanguage");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/searchsuggestions?candidateName=${candidateName1}`,
      data: data,
    };

    axios(config)
      .then((response) => {
        if (response.data.response.length > 0) {
          let searchedList = replace ? response.data.response : [...searchSuggestionList, ...response.data.response];
          setSearchSuggestionList(searchedList);
        }
        if (response.data.response.length > 0) {
          setScrollMore(true);
        } else {
          setScrollMore(false);
        }
        setSuggestionListLoading(false);
      })
      .catch((error) => {
        setSuggestionListLoading(false);
        setIsApiCallInProgress(false);
        if (error.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
      })
      .finally(() => {
        setIsApiCallInProgress(false);
      });
  };

  const onEditVoterDetails = (voterId) => {
    setEditVoterId(voterId);
    setShowEditMode(true);
    setShowFamilyCards(false);
  };
  const onCancelEditVoterDetails = (voterId) => {
    setEditVoterId(voterId);
    setShowEditMode(false);
  };
  const onAgeNumberChange = (e, number) => {
    const updatedList = [...searchSuggestionList];
    updatedList[number].age = e.target.value;
    setSearchSuggestionList(updatedList);
  };
  const onPhoneNumberChange = (e, number) => {
    const value = e.target.value;
    const re = /^[+-]?\d*(?:[.,]\d*)?$/;
    const numericValue = value.replace(/\D/g, "");

    if (re.test(numericValue)) {
      if (numericValue.length > 10) {
        return;
      }
      const updatedList = [...searchSuggestionList];
      updatedList[number].mobileNo = numericValue;
      setSearchSuggestionList(updatedList);
    }
  };

  const onselectCaste = (e, number) => {
    const updatedList = [...searchSuggestionList];
    updatedList[number].caste = e.target.value;
    setSearchSuggestionList(updatedList);
  };
  const onselectConfirmation = (e, number, val) => {
    const updatedList = [...searchSuggestionList];
    updatedList[number].votingConfirmation = e.target.value;
    setSearchSuggestionList(updatedList);
  };
  const onNewAddressChange = (e, number) => {
    const updatedList = [...searchSuggestionList];
    updatedList[number].changedAddress = e.target.value;
    setSearchSuggestionList(updatedList);
  };
  const onBirthDateChange = (e, number) => {
    const updatedList = [...searchSuggestionList];
    const birthDateInIST = moment(e.target.value).utcOffset("+05:30").format("YYYY-MM-DD"); 
    updatedList[number].birthDate = birthDateInIST;
    setSearchSuggestionList(updatedList);
  };

  const onFamilyMemberChange = (e, number) => {
    if (e.target.value > 25) {
      toast.current.show({ severity: "error", detail: "कुटुंबातील सदस्य 25 पेक्षा कमी असावेत", life: 3000 });
      // return;
    }

    const updatedList = [...searchSuggestionList];
    updatedList[number].familyMemberCount = e.target.value;
    setSearchSuggestionList(updatedList);
  };

  const onMaritalStatusClick = (e, number) => {
    let setValueOfMarriedStatus;
    if (e.target.checked == true) {
      setValueOfMarriedStatus = "विवाहित";
    } else {
      setValueOfMarriedStatus = "अविवाहित";
    }
    const updatedList = [...searchSuggestionList];
    updatedList[number].maritalStatus = setValueOfMarriedStatus;
    setSearchSuggestionList(updatedList);
  };

  const onVisitedStatusClick = (e, number) => {
    const updatedList = [...searchSuggestionList];
    updatedList[number].visited = e.target.checked;
    setSearchSuggestionList(updatedList);
  };
  const onFamilyMemberCheckboxClick = (item, number) => {
    let newFamilyMemberList = { ...familyList };
    newFamilyMemberList = familyList.map((familyMemberInfo, index) => {
      if (familyMemberInfo.familyInfo.id == item.familyInfo.id) {
        if (item.isFamilyMemberCheck == false) {
          return {
            familyInfo: familyMemberInfo.familyInfo,
            isFamilyMemberCheck: true,
          };
        } else {
          return {
            familyInfo: familyMemberInfo.familyInfo,
            isFamilyMemberCheck: false,
          };
        }
      } else {
        return {
          familyInfo: familyMemberInfo.familyInfo,
          isFamilyMemberCheck: familyMemberInfo.isFamilyMemberCheck,
        };
      }
    });
    setFamilyList(newFamilyMemberList);
    // setOriginalFamilyList(newFamilyMemberList);
  };
  const onShowFamilyMemberButtonClick = async (e, item) => {
    setEditVoterId(item.voterId);
    setShowFamilyCards(true);
    setShowFamilyListLoading(true);
    const familyMemberIds = await getFamilyMember(item.id);
    onFamilyClick(item, familyMemberIds);
  };
  const onShowAllDetailsOfFamily = (id) => {
    setShowFamilyDetailsId(id);
    setShowSeperateFamilyDetailsCard(true);
  };
  const onHideAllDetailsOfFamily = () => {
    setShowSeperateFamilyDetailsCard(false);
    setShowFamilyDetailsId("");
  };
  const onHideFamilyMemberButtonClick = (e, voterid) => {
    setEditVoterId(voterid);
    setShowFamilyCards(false);
  };
  const getFamilyMember = async (familyMemberId) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/getfamilymember?primaryFamilyMemberId=${familyMemberId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
    };

    try {
      const response = await axios(config);
      return response.data.response;
    } catch (error) {
      if (error.response.status === 401) {
        const loginURL = sessionStorage.getItem("candidateLoginURL");
        if (loginURL) {
          // Redirect back to the candidate's login page
          window.location.href = loginURL;
        } else {
          // Fallback to a default login page if the URL is not stored
          window.location.href = process.env.REACT_APP_PROD_API_URL;
        }
      }
    }
  };
  const onFamilyClick = (selectedPrimaryFamilyMember, familyMemberIds) => {
    setShowFamilyCards(true);
    setFamilyList([]);
    setPrimaryFamilyMemberId(selectedPrimaryFamilyMember.id);
    const data = {
      firstName: selectedPrimaryFamilyMember.firstName,
      middleName: selectedPrimaryFamilyMember.middleName,
      lastName: selectedPrimaryFamilyMember.lastName,
      workerId: 0,
      voterId: selectedPrimaryFamilyMember.voterId,
    };
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/searchfamilymember?candidateName=${candidateName == "null" ? "" : url}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        let familyList = [];
        let updatedFamilyMemberList = [];
        response.data.response.forEach((item) => {
          familyList.push({
            familyInfo: item,
            isFamilyMemberCheck: false,
          });
        });
        const idSet = new Set(familyMemberIds);
        updatedFamilyMemberList = familyList.map((item) => {
          if (idSet.has(item.familyInfo.id)) {
            return { ...item, isFamilyMemberCheck: true };
          }
          return item;
        });

        setFamilyList(updatedFamilyMemberList);
        setOriginalFamilyList(updatedFamilyMemberList);
        setShowFamilyListLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
        setShowFamilyListLoading(false);
      })
      .finally(() => {});
  };
  const checkValidation = (item) => {
    let returnValue = true;
    const value = item.mobileNo;
    const re = /^[+-]?\d*(?:[.,]\d*)?$/;
    if (value != "" && value != null) {
      const numericValue = value.replace(/\D/g, "");

      if (numericValue !== null && numericValue !== "" && re.test(numericValue)) {
        if (numericValue.length < 10 || numericValue.length > 10) {
          toast.current.show({ severity: "error", detail: "Please enter a valid phone number number ", life: 3000 });
          returnValue = false;
        }
      }
    }
    if (item.familyMemberCount > 25) {
      toast.current.show({ severity: "error", detail: "कुटुंबातील सदस्य 25 पेक्षा कमी असावेत", life: 3000 });

      returnValue = false;
    }
    return returnValue;
  };
  const onUpdateVoterDetails = async (item, index) => {
    const token = localStorage.getItem("token");
    const decoded = jwtDecode(token);
    let worker = decoded.Id;
    var workerValue = worker === null || isNaN(parseInt(worker)) ? item.id : parseInt(worker);
    if (checkValidation(item)) {
      let isExistsInCandidate = false;
      if (localStorage.getItem("token") !== null) {
        const token = localStorage.getItem("token");
        const decoded = jwtDecode(token);
        isExistsInCandidate = decoded.isExistsInCandidate;
      }

      const data = {
        id: item.id,
        workerId: workerValue,
        candidateId: candidateId,
        caste: item.caste,
        mobileNo: item.mobileNo,
        familyMemberCount: item.familyMemberCount,
        houseNumber: item.houseNumber,
        birthDate: item.birthDate != "" && item.birthDate != null ? moment(item.birthDate, "YYYY-MM-DD").toDate() : null,
        changedAddress: item.changedAddress,
        votingConfirmation: item.votingConfirmation,
        maritalStatus: item.maritalStatus,
        visited: item.visited,
      };
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/updatevoter?isExistsInCandidate=${isExistsInCandidate}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authtoken,
        },
        data: data,
      };

      try {
        const response = await axios(config);
        if (response.data.response == true) {
           toast.current.show({ severity: "success", detail: "तपशील यशस्वीरित्या अपडेट केले", life: 3000 });
          setEditVoterId(null);
        } else {
          toast.current.show({ severity: "error", detail: " While Details updating", life: 3000 });
        }
      } catch (error) {
        if (error.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
      }
    }
  };
  const onAddOrUpdateFamilyMemberClick = () => {
    setAddingFamilyLoading(true);
    let selectedFamilyListIds = [];
    familyList.forEach((element) => {
      if (element.isFamilyMemberCheck == true) {
        selectedFamilyListIds.push(element.familyInfo.id);
      }
    });
    const data = {
      primaryFamilyMemberId: primaryFamilyMemberId,
      fmailyMemberId: selectedFamilyListIds,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/addfamilymember`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        if (response.data.response == true) {
          toast.current.show({ severity: "success", detail: "कुटुंब यशस्वीरित्या अद्ययावत", life: 3000 });
          // setEditVoterId(null);
        } else {
          toast.current.show({ severity: "error", detail: "Unknown error  while adding family member", life: 3000 });
        }
        setAddingFamilyLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
        setAddingFamilyLoading(false);
      });
  };

  const onlogoutClick = () => {
    const loginURL = sessionStorage.getItem("candidateLoginURL");
    if (loginURL) {
      // Redirect back to the candidate's login page
      window.location.href = loginURL;
    } else {
      // Fallback to a default login page if the URL is not stored
      window.location.href = `${process.env.REACT_APP_PROD_API_URL}`;
    }
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
  };

  return (
    <div className="app main-container-login">
      <Toast ref={toast} />
      <div className="header">
        <div className="fixedPage">
          <div className="row mt-2">
            <div className="col-10 d-flex align-items-center">
              <Button
                icon="pi pi-chevron-left"
                severity="secondary"
                onClick={() => {
                  onDashboardclick();
                }}
                className="back-button"
              />
              <label htmlFor="Constituency" className="text-bold form-label Polling text-light labelDashboard marLeftHeader mt-2">
                संपूर्ण मतदार यादी
              </label>
            </div>
            <div className="col-2 text-end mt-2">
              <img
                src={home}
                alt="Whatsapp"
                className="home-img float-end"
                onClick={onDashboardclick}
                style={{ marginBottom: "4px", marginLeft: "10px" }}
              />
              {!isExistsInCandidate && <i className="pi pi-sign-out d-flex" style={{ fontSize: "1rem", color: "white" }} onClick={onlogoutClick}></i>}
            </div>
          </div>

          <div className="row card voter-info-cardEndUser">
            <div className="tabview-descriptio pl-0">
              <span className="text-white" style={{ Lineheight: "20px", fontSize: "14px" }}>
                मतदार यादीतील नाव शोधण्यासाठी खालील पैकी एक पर्याय निवडा.
              </span>
              <TabView
                className="justify-content-center"
                activeIndex={activeIndex}
                // onTabChange={(e) => setActiveIndex(e.index)}
                onTabChange={(e) => {
                  // Clear the input field of the tab that's being left
                  if (activeIndex === 0 && e.index !== 0) {
                    setVoterId(""); // Clear voter ID
                    setErrorMessage("");
                  } else if (activeIndex === 1 && e.index !== 1) {
                    setFirstName(null);
                    setMiddleName(null);
                    setLastName(null);
                    setShowEditMode(false);
                  } else if (activeIndex === 2 && e.index !== 2) {
                    setMobileNo(null); // Clear mobile number
                    setErrorMessageForMobileNo("");
                    setShowEditMode(false);
                  }
                  setActiveIndex(e.index);
                  setShowEditMode(false);
                }}
              >
                <TabPanel header="Voter ID नंबर" className={activeIndex === 0 ? "active-tab mt-4 mb-4" : "mt-4 mb-4"}>
                  <div className="col-12 p-0 mt-2">
                    <input
                      type="text"
                      className="form-control input-VoterFormCSSChange text-center text-white inputBoxCapsule"
                      id="voterIdInput"
                      placeholder="Voter ID नंबर टाका"
                      onChange={(e) => onChangeVoterId(e.target.value)}
                      style={{ padding: "16px 0px 6px 0px", width: "100%" }}
                      value={voterId}
                    />
                    <span className="text-white text-12 d-flex justify-content-center mt-2">{errorMessage}</span>
                  </div>
                </TabPanel>

                <TabPanel header="संपूर्ण नाव" className={activeIndex === 1 ? "active-tab mt-4 mb-2" : "mt-4 mb-4"}>
                  <div className="col-12 p-0 mt-2">
                    <input
                      type="text"
                      className="form-control input-VoterFormCSSChange text-center text-white inputBoxCapsule"
                      id="nameInput"
                      placeholder="आडनाव नाव मधले नाव"
                      style={{ padding: "16px 0px 6px 0px", width: "100%" }}
                      onChange={(e) => onChangeFirstName(e.target.value)}
                      value={fullName}
                    />
                  </div>
                </TabPanel>

                <TabPanel header="मोबाइल" className={activeIndex === 2 ? "active-tab mt-4 mb-4" : "mt-4 mb-4"}>
                  <div className="col-12 p-0 mt-2">
                    <input
                      type="number"
                      className="form-control input-VoterFormCSSChange text-center inputBoxCapsule padTopInput"
                      id="mobileNoInput"
                      style={{ padding: "16px 0px 6px 0px", width: "100%", color: "white" }}
                      placeholder="मोबाईल नंबर"
                      onChange={(e) => onChangeMobileNo(e.target.value)}
                      value={mobileNo}
                    />
                    <span className="text-white text-12 d-flex justify-content-center mt-2">{errorMessageForMobileNo}</span>
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>

        <div className="app main-container-login voter-by-age-page">
          <BottomScrollListener onBottom={onShowmoreClicked}>
            {(scrollRef) => (
              <div
                className="mt-2"
                ref={scrollRef}
                style={{
                  height: "800px",
                  overflowY: "auto",
                  overflowX: "hidden",
                  padding: "4px 8px 4px 8px",
                  backgroundColor: "#3e3e3e",
                  borderRadius: "10px",
                }}
              >
                {searchSuggestionList.length === 0 && !suggestionListLoading ? (
                  <div className="d-flex justify-content-center text-white mt-40">
                    {" "}
                    <span style={{ marginTop: "50px" }}>कोणताही डेटा उपलब्ध नाही</span>
                  </div>
                ) : (
                  <></>
                )}

                {searchSuggestionList.map((item, number) => (
                  <div className="card voter-list-card birthdayMarginTop Polling-list-card mb-2" key={number}>
                    <div className="row d-flex  mx-auto ">
                      <div className="col-4 ps-0">
                        <span className="voter-list-answer"> मतदान केंद्र क्र</span>
                      </div>
                      <div className="col-4 ps-0">
                        <span className="voter-list-answer">{item.boothNumber}</span>
                      </div>

                      <div className="col-4 text-end">
                        {showEditMode && editVoterId == item.voterId ? null : (
                          <i
                            className="pi pi-pencil show-cursor  editAlign"
                            title="Edit"
                            onClick={() => onEditVoterDetails(item.voterId)}
                            style={{
                              backgroundColor: "white",
                              color: "black",
                              borderRadius: "7px",
                              padding: "5px", // Optionally add some padding for a better appearance
                            }}
                          ></i>
                        )}
                      </div>
                      <div className="col-4 ps-0 mb-1">
                        <span className="voter-list-answer">अनु.क्र</span>
                      </div>
                      <div className="col-8 ps-0 mb-1">
                        <span className="voter-list-answer">{item.slipNumber}</span>
                      </div>
                      <div className="col-4 ps-0 mb-1">
                        <span className="voter-list-answer">Voter ID</span>
                      </div>
                      <div className="col-8 ps-0 mb-1">
                        <span className="voter-list-answer">{item.voterId}</span>
                      </div>
                      <div className="col-4 ps-0 mb-1">
                        <span className="voter-list-answer">नाव</span>
                      </div>
                      <div className="col-8 ps-0 mb-1">
                        <span className="voter-list-answer">
                          {item.lastName} {item.firstName} {item.middleName}
                        </span>
                      </div>
                      {showEditMode && editVoterId == item.voterId && (
                        <>
                          <div className="col-4 ps-0 mb-1 mt-3">
                            <span className=" voter-list-answer">मोबाइल नंबर</span>
                          </div>
                          <div className="col-5 ps-0 mb-1">
                            {showEditMode && editVoterId == item.voterId ? (
                              <input
                                type="number"
                                className="w-100 form-control input-box mb-1 mt-1 border-1 boxColor"
                                value={item.mobileNo}
                                name="mobileNo"
                                onChange={(e) => onPhoneNumberChange(e, number)}
                                onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                              />
                            ) : (
                              <span className="voter-list-answer">{item.mobileNo}</span>
                            )}
                          </div>
                          <div className="col-3 ps-0 mb-1 mt-2" hidden={item.mobileNo === "" || item.mobileNo === 0}>
                            {showEditMode && editVoterId === item.voterId ? (
                              <i
                                // className="pi pi-save show-cursor  editAlign"
                                title="Update"
                                className="voter-list-answer"
                                onClick={() => onUpdateVoterDetails(item, number)}
                                style={{
                                  backgroundColor: "#0057ff",
                                  color: "white",
                                  borderRadius: "7px",
                                  padding: "3px 5px 0px 4px",
                                  marginTop: "10px",
                                  fontStyle: "normal",
                                }}
                              >
                                सेव्ह करा
                              </i>
                            ) : null}
                          </div>
                        </>
                      )}
                      <div className="col-4 ps-0 mb-1">
                        <span className="voter-list-answer">पत्ता</span>
                      </div>
                      <div className="col-8 ps-0 mb-1">
                        <span className="voter-list-answer">
                          {item.wordName}, {item.district}
                        </span>
                      </div>
                      {showEditMode && editVoterId == item.voterId && (
                        <>
                          <div className="col-4 ps-0 mb-1 mt-2">
                            <span className="voter-list-answer">बदललेला पत्ता </span>
                          </div>
                          <div className="col-8 ps-0 mb-1">
                            {showEditMode && editVoterId === item.voterId ? (
                              <input
                                type="text"
                                className="w-100 form-control input-box  mb-1 boxColor"
                                value={item.changedAddress}
                                name="changedAddress"
                                onChange={(e) => onNewAddressChange(e, number)}
                              />
                            ) : (
                              <span className="voter-list-answer">{item.changedAddress}</span>
                            )}
                          </div>
                        </>
                      )}
                      <div className="col-4 ps-0 mb-1 mt-1">
                        <span className=" voter-list-answer">वय</span>
                      </div>
                      <div className="col-3 ps-0 mb-1 mt-1 ">
                        <span className="voter-list-answer">{item.age}</span>
                      </div>
                      <div className="col-2 mb-1 mt-1">
                        <span className=" voter-list-label whiteFont"> लिंग</span>
                      </div>
                      <div className="col-3 ps-0 mb-1 mt-1">
                        <span className="voter-list-answer">{item.gender}</span>
                      </div>

                      {showEditMode && editVoterId == item.voterId && (
                        <>
                          <div className="col-4 ps-0 mb-1 mt-2">
                            <span className="voter-list-answer">जन्मतारीख</span>
                          </div>
                          <div className="col-8 ps-0 mb-1">
                          {showEditMode && editVoterId === item.voterId ? (
                            <DatePicker
                            selected={item.birthDate ? moment(item.birthDate).utcOffset("+05:30").toDate() : null} // Convert to IST time zone
                            onChange={(date) => {
                                if (date) {
                                  onBirthDateChange(
                                    {
                                      target: {
                                        name: "birthDate",
                                        value: moment(date).utcOffset("+05:30").format("YYYY-MM-DD"), // Convert to IST and format the date
                                      },
                                    },
                                    number
                                  );
                                } else {
                                  onBirthDateChange(
                                    {
                                      target: {
                                        name: "birthDate",
                                        value: null, // Handle clearing the date
                                      },
                                    },
                                    number
                                  );
                                }
                              }}
                              dateFormat="yyyy-MM-dd"
                              maxDate={moment().subtract(18, "years").utcOffset("+05:30").toDate()} // Set maxDate properly in IST
                              className="form-control input-box mb-1 boxColor"
                              placeholderText="YYYY-MM-DD"
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={200}
                            />
                          ) : (
                            <span className="voter-list-answer">
                            {item.birthDate !== "" && item.birthDate !== null
                              ? moment(item.birthDate).utcOffset("+05:30").format("YYYY-MM-DD") // Convert display date to IST
                              : ""}
                          </span>
                          )}
                          </div>
                        </>
                      )}

                      {showEditMode && editVoterId == item.voterId && (
                        <>
                          <div className="col-4 ps-0 mb-1 mt-2">
                            <span className="voter-list-answer">कुटुंबातील सदस्य</span>
                          </div>
                          <div className="col-3 ps-0 pe-0 mb-1 ">
                            {showEditMode && editVoterId === item.voterId ? (
                              <input
                                type="number"
                                className="w-100 form-control input-box mb-1 boxColor"
                                value={item.familyMemberCount}
                                name="familyMember"
                                onChange={(e) => onFamilyMemberChange(e, number)}
                                onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                              />
                            ) : (
                              <span className="voter-list-answer">{item.familyMemberCount}</span>
                            )}
                          </div>
                        </>
                      )}
                      {showEditMode && editVoterId == item.voterId && (
                        <>
                          <div className="col-2 mb-1 mt-2">
                            <span className="whiteFont voter-list-label">धर्म</span>
                          </div>
                          <div className="col-3 ps-0 mb-1">
                            {showEditMode && editVoterId == item.voterId ? (
                              <select
                                name="Caste"
                                value={item.caste || ""}
                                className="form-control form-control input-box mb-1 boxColor"
                                id="Caste"
                                onChange={(e) => onselectCaste(e, number)}
                              >
                                <option></option>
                                <option value="हिंदू">हिंदू</option>
                                <option value="शीख">शीख</option>
                                <option value="जैन">जैन</option>
                                <option value="बौद्ध">बौद्ध</option>
                                <option value="मुस्लिम">मुस्लिम</option>
                                <option value="ख्रिश्चन">ख्रिश्चन</option>
                              </select>
                            ) : (
                              <span className="voter-list-answer">{item.caste}</span>
                            )}
                          </div>
                        </>
                      )}
                      {showEditMode && editVoterId == item.voterId && (
                        <>
                          <div className="col-4 ps-0 mb-1 pe-1 mt-2">
                            <span className="voter-list-answer">विश्वसनीयता</span>
                          </div>
                          <div className="col-8 ps-0 mb-1">
                            {showEditMode && editVoterId == item.voterId ? (
                              <select
                                name="Confirmation"
                                value={item.votingConfirmation || ""}
                                className="form-control form-select input-box mb-1 boxColor boxWidth"
                                id="confirmation"
                                onChange={(e) => onselectConfirmation(e, number, item.votingConfirmation)}
                              >
                                <option></option>
                                <option value="असू शकते">असू शकते</option>
                                <option value="होय">होय</option>
                                <option value="नाही">नाही</option>
                              </select>
                            ) : (
                              <span className="voter-list-answer">{item.votingConfirmation}</span>
                            )}
                          </div>
                        </>
                      )}
                      {showEditMode && editVoterId == item.voterId && (
                        <>
                          {item.gender != "पुरुष" ? (
                            <>
                              <div className="col-4 ps-0 mb-2">
                                <span className="voter-list-answer">वैवाहिक स्थिती</span>
                              </div>
                              <div className="col-8 ps-0 mb-2">
                                {showEditMode && editVoterId === item.voterId ? (
                                  <>
                                    <input
                                      type="checkbox"
                                      id="maritalStatusSwitch"
                                      className="checkbox"
                                      onChange={(e) => onMaritalStatusClick(e, number)}
                                      checked={item.maritalStatus === "विवाहित" ? true : false}
                                    />
                                    <label
                                      htmlFor="maritalStatusSwitch"
                                      className={`mb-0 toggle toggleWidth ${
                                        item.maritalStatus === "विवाहित"
                                          ? "justify-content-start checked-toggle-span toggleON toggleWidth"
                                          : "justify-content-end unchecked-toggle-span "
                                      }`}
                                    >
                                      <span className="text-12 textAlign">
                                        {item.maritalStatus == null ? "अविवाहित" : item.maritalStatus === "विवाहित" ? "विवाहित" : item.maritalStatus}
                                      </span>
                                    </label>
                                  </>
                                ) : (
                                  <span className="text-12 textAlign">
                                    {item.maritalStatus == null ? "अविवाहित" : item.maritalStatus === "विवाहित" ? "विवाहित" : item.maritalStatus}
                                  </span>
                                )}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}{" "}
                        </>
                      )}
                      {showEditMode && editVoterId == item.voterId && (
                        <>
                          <div className="col-4  ps-0 d-flex align-items-center mb-1 ">
                            {showEditMode && editVoterId == item.voterId ? (
                              <>
                                <input
                                  type="checkbox"
                                  id="visitedswitch"
                                  className="checkbox"
                                  onChange={(e) => onVisitedStatusClick(e, number)}
                                  checked={item.visited == true ? true : false}
                                />
                                <label
                                  htmlFor="visitedswitch"
                                  className={`mb-0 toggle toggleWidth ${
                                    item.visited
                                      ? "justify-content-start checked-toggle-span toggleON toggleWidth"
                                      : "justify-content-end unchecked-toggle-span"
                                  }`}
                                >
                                  <span className="text-12 textAlign">{item.visited == true ? "भेट दिली" : "भेट नाही"}</span>
                                </label>
                              </>
                            ) : (
                              <>
                                <input type="checkbox" id="visitedswitch2" className="checkbox" checked={item.visited == true ? true : false} />
                                <label
                                  htmlFor="visitedswitch2"
                                  className={`mb-0 toggle toggleWidth ${
                                    item.visited
                                      ? "justify-content-start checked-toggle-span toggleON toggleWidth"
                                      : "justify-content-end unchecked-toggle-span"
                                  }`}
                                >
                                  <span className="text-12 textAlign">{item.visited == true ? "भेट दिली" : "भेट नाही"}</span>
                                </label>
                              </>
                            )}
                          </div>
                        </>
                      )}
                      {showEditMode && editVoterId == item.voterId && (
                        <>
                          <div className="col-8 ps-0 mb-1">
                            {showFamilyListLoading && editVoterId == item.voterId ? (
                              <div className="d-flex justify-content-center">
                                <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
                              </div>
                            ) : (
                              <>
                                {showFamilyCards && editVoterId == item.voterId ? (
                                  <button
                                    className="btn show-btndisplayList w-auto text-12 famWidthButton"
                                    onClick={(e) => onHideFamilyMemberButtonClick(e, item.voterId)}
                                  >
                                    कुटुंबातील सदस्य लपवा
                                  </button>
                                ) : (
                                  <div className="d-flex align-items-center">
                                    <button
                                      className="btn show-btndisplayList w-auto text-12 famWidthButton"
                                      onClick={(e) => onShowFamilyMemberButtonClick(e, item)}
                                    >
                                      कुटुंबातील सदस्य दाखवा
                                    </button>
                                    <button
                                      className="btn w-auto text-15 text-white ms-2"
                                      onClick={() => {
                                        handleWhatsappClick(item, number);
                                      }}
                                    >
                                      <img src={share} alt="Share" /> शेअर
                                    </button>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </>
                      )}
                      {showEditMode && showFamilyCards && editVoterId == item.voterId ? (
                        <div className="input-group mb-2 mt-2 d-flex justify-content-center" style={{ zIndex: "0" }}>
                          <span className="flex p-input-icon-left h-2rem w-100">
                            <input
                              className="form-control input-box input-VoterFormCSSChangetest"
                              value={searchValue}
                              onChange={handleSearch}
                              placeholder="आपले नाव शोधा"
                              style={{ borderRadius: "22px", backgroundColor: "#4d4c4c" }}
                            />
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}

                      {showEditMode && editVoterId == item.voterId && (
                        <>
                          {showFamilyCards && editVoterId == item.voterId && showFamilyListLoading == false ? (
                            <div className="row family-card-bg mx-auto py-1 d-flex align-items-center mt-2 ">
                              {familyList.length == 0 ? (
                                <span className=" text-info oops-msg-text text-danger justify-content-center">
                                  <b>कुटुंबातील सदस्य सापडत नाही.</b>
                                </span>
                              ) : (
                                familyList.map((member, number) => (
                                  <div className="col-12  my-1 text-light" style={{ background: "#5F6368" }}>
                                    <div className={`row p-1 ${member.isFamilyMemberCheck ? "blue-card" : ""}`}>
                                      <div className="col-3 pe-0">
                                        {showEditMode && editVoterId == item.voterId ? (
                                          <input
                                            className="form-check-input me-1 mt-0 text-14"
                                            type="checkbox"
                                            onChange={() => onFamilyMemberCheckboxClick(member, number)}
                                            value=""
                                            checked={member.isFamilyMemberCheck}
                                            id=""
                                          />
                                        ) : (
                                          <input
                                            className="form-check-input me-1 mt-0 text-14"
                                            type="checkbox"
                                            value=""
                                            checked={member.isFamilyMemberCheck}
                                            id=""
                                          />
                                        )}
                                      </div>
                                      <div className="col-7 pe-0">
                                        <span className="fw-bold ">Voter Id </span>
                                        <span>{member.familyInfo.voterId}</span>
                                      </div>

                                      <div className="col-2 text-end">
                                        {showSeperateFamilyDetailsCard && showFamilyDetailsId == member.familyInfo.voterId ? (
                                          <i class="pi pi-chevron-up show-cursor text-light text-12" onClick={onHideAllDetailsOfFamily} />
                                        ) : (
                                          <i
                                            class="pi pi-chevron-down show-cursor text-light text-12"
                                            onClick={() => onShowAllDetailsOfFamily(member.familyInfo.voterId)}
                                          />
                                        )}
                                      </div>

                                      <div className="col-3 mb-2">
                                        <span className="fw-bold ">नाव</span>
                                      </div>

                                      <div className="col-9">
                                        <span>
                                          {" "}
                                          {member.familyInfo.lastName} {member.familyInfo.firstName} {member.familyInfo.middleName}{" "}
                                        </span>
                                      </div>
                                      {showSeperateFamilyDetailsCard && showFamilyDetailsId === member.familyInfo.voterId ? (
                                        <>
                                          <div className="row d-flex align-items-center w-100 mb-1 ">
                                            <div className="col-3 pe-0">
                                              <span className="fw-bold ">वय &nbsp; </span>
                                              <span> {member.familyInfo.age} </span>
                                            </div>
                                            <div className="col-4 pe-0">
                                              <span className="fw-bold">लिंग &nbsp; </span>
                                              <span> {member.familyInfo.gender} </span>
                                            </div>
                                            {member.familyInfo.gender !== "पुरुष" ? (
                                              <div className="col-5 px-0 text-end">
                                                <input
                                                  type="checkbox"
                                                  id="familyMaritalStatusSwitch"
                                                  className="checkbox"
                                                  checked={member.familyInfo.maritalStatus}
                                                  // onChange={(e) => onMaritalStatusClick(e, number)}
                                                />
                                                <span
                                                  htmlFor="familyMaritalStatusSwitch"
                                                  className={`fw-bold family-marital-toggle toggle ${
                                                    member.familyInfo.maritalStatus
                                                      ? "justify-content-start checked-toggle-span toggleON"
                                                      : "justify-content-end unchecked-toggle-span"
                                                  }`}
                                                >
                                                  <span className="text-10 px-1 textColorFamMember">
                                                    {member.familyInfo.maritalStatus ? "विवाहित" : "अविवाहित"}
                                                  </span>
                                                </span>
                                              </div>
                                            ) : null}{" "}
                                          </div>

                                          <div className="col-3 mb-2">
                                            <span className="fw-bold  ">पत्ता </span>
                                          </div>
                                          <div className="col-9 mb-2">
                                            <span>
                                              {" "}
                                              {member.familyInfo.wordName}, {member.familyInfo.district}{" "}
                                            </span>
                                          </div>
                                          <div className="col-3 mb-2">
                                            <span className="fw-bold me-2">मतदार केंद्र </span>
                                          </div>
                                          <div className="col-9 mb-2">
                                            <span> {member.familyInfo.addressOfPollingStation} </span>
                                          </div>
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                ))
                              )}
                            </div>
                          ) : null}
                        </>
                      )}
                      {showEditMode && editVoterId === item.voterId ? (
                        <div className="col-12 d-flex align-items-center justify-content-end mt-2">
                          {addingFamilyLoading ? (
                            <div className="d-inline-block me-2  ">
                              <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
                            </div>
                          ) : showFamilyCards && editVoterId === item.voterId && familyList.length > 0 ? (
                            <button className="btn  show-btn w-auto text-12 me-2" onClick={() => onAddOrUpdateFamilyMemberClick(item, number)}>
                              {" "}
                              कुटुंब जोडा
                            </button>
                          ) : null}
                          <button className="btn  show-btndisplayList w-auto text-12 me-2" onClick={() => onUpdateVoterDetails(item, number)}>
                            {" "}
                            सबमिट करा{" "}
                          </button>
                          <button className="btn show-btn buttonRadius w-auto text-12 " onClick={() => onCancelEditVoterDetails(number)}>
                            {" "}
                            रद्द करा{" "}
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
                {suggestionListLoading ? (
                  <div className="d-flex justify-content-center">
                    <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
                  </div>
                ) : null}
              </div>
            )}
          </BottomScrollListener>
        </div>
      </div>
    </div>
  );
}
