import React from "react";
import { useNavigate } from "react-router-dom";
import website from "../Assets/Images/websiteimg.png";
import facebook from "../Assets/Images/facebook_icon.svg";
import Instagram from "../Assets/Images/Instagram.jpg";
import youtube from "../Assets/Images/youtube.png";
import Twitter from "../Assets/Images/Twitter.png";
import Header from "./Header";
import Back from "../Assets/Images/backbtn.svg";
import Home from "../Assets/Images/home_icon.svg";

export default function CandidateInfo() {
  const navigate = useNavigate();

  const handleSubmitClick = () => {
    navigate("/");
  };

  const onBackClick = () => {
    navigate("/candidateinfo");
  };
  const handleDashboardClick = () => {
    navigate("/dashboard");
  };
  return (
    <>
      <div className="app">
        <Header />
        <div className="row">
          <div className="col-6">
            <img src={Back} alt="Scanner image" className="back-btn-img" onClick={onBackClick} />
          </div>

          <div className="col-6 text-end">
            <img src={Home} alt="Scanner image" className="home-btn-img" onClick={handleDashboardClick} />
          </div>
        </div>
        <div className="container main-container">
          <div className="card candidate-review-card">
            <div className="row">
              <div className="col-6 text-center">
                <div className="thanku-form">
                  <div className="card candidatename"></div>
                  <div className="sign-text" style={{ fontSize: "12px" }}>
                    Candidate Name
                  </div>
                </div>
              </div>
              <div className="col-6 text-center">
                <div className="thanku-form">
                  <div className="card candidatename"></div>
                </div>
                <div className="sign-text" style={{ fontSize: "12px" }}>
                  Sign
                </div>
              </div>
            </div>
            <div className="sign-text media">Media Connect</div>
            <div className="row mt-2" style={{ justifyContent: "center" }}>
              <div className="col-2 media-connect-text">
                <img src={facebook} alt="Scanner image" className="wp-img" />
                facebook
              </div>
              <div className="col-2 media-connect-text">
                <img src={Instagram} alt="Scanner image" className="wp-img" />
                Instagram
              </div>
              <div className="col-2 media-connect-text">
                <img src={Twitter} alt="Scanner image" className="wp-img" style={{ height: "30px" }} />
                Twitter
              </div>
              <div className="col-2 media-connect-text">
                <img src={youtube} alt="Scanner image" className="wp-img" />
                Youtube
              </div>
              <div className="col-2 media-connect-text">
                <img src={website} alt="Scanner image" className="wp-img" />
                Website
              </div>
            </div>
            <div className="sign-text media">Quick Contact No</div>
            <div className="sign-text media no">9922993399</div>
            <div className="sign-text media">Event Calender</div>
            <div className="row" style={{ paddingTop: "60px", paddingBottom: "10px" }}>
              <div className="col-6">
                {" "}
                <a className="sign-text media">Reviews</a>
              </div>
              <div className="col-6"></div>
            </div>
          </div>
          <div className="sign-text mt-3">Your Comment if any</div>
          <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
        </div>
        <div className="footer">
          <div className="mb-2 text-end mt-2" style={{ paddingRight: "25px" }}>
            <button type="button" className="btn btn-primary btn-back" onClick={handleSubmitClick}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
