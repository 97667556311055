import React, { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import home from "../Assets/Images/home_icon.svg";
import { useNavigate } from "react-router-dom";
import share from "../Assets/Images/sharevoter.svg";
import print from "../Assets/Images/print.svg";
import { ThreeDots } from "react-loader-spinner";
import "jspdf-autotable";
import MarathiFont from "../Utils/MarathiFont";
import pdfMake, { log } from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import MarathiFontBold from "../Utils/MarathiFontBold"; // Bold variant base64
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";

export default function PollingBooth() {
  const navigate = useNavigate();
  const [candidateName, setCandidateName] = useState("");
  const [votersListLoading, setVotersListLoading] = useState(false);
  const [toPollingAddress, setToPollingAddress] = useState("");
  const [fromPollingAddress, setFromPollingAddress] = useState("");
  const [boothList, setBoothListList] = useState([]);
  const [fromBoothNumber, setfromBoothNumber] = useState(0);
  const [toBoothNumber, settoBoothNumber] = useState(0);
  const [pooingbooth, setPooligBoothFile] = useState(0);
  const toast = useRef("");
  const userId = localStorage.getItem("userId");
  const authtoken = localStorage.getItem("token");
  const [PoolingBoothDetails, setPoolingBoothDetails] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      let candidateName = decoded.UserName;
      setCandidateName(candidateName);
      // getPollingBoothList(candidateName);
      getPollingBoothListCount(candidateName);
    }
  }, []);

  const getPollingBoothListCount = (candidateNm) => {
    const Slughurl = localStorage.getItem("slugUrl");
    setVotersListLoading(true);
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getBoothDetailscount?candidateName=${Slughurl}&FromBoothNumber=${fromBoothNumber}&ToBoothNumber=${toBoothNumber}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
    };

    axios(config)
      .then((response) => {
        setVotersListLoading(false);
        if (response.data.response.length > 0) {
          setBoothListList(response.data.response);
        }
        setfromBoothNumber(0);
        settoBoothNumber(0);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
        setVotersListLoading(false);
      })
      .finally(() => {});
  };

  const onDashboardclick = () => {
    navigate("/candidatedashboard");
  };

  const onSelectFromAddressOfPolling = (boothNo, address) => {
    setfromBoothNumber(boothNo);
    setFromPollingAddress(address);
  };
  const onSelectToAddressOfPolling = (boothNo, address) => {
    settoBoothNumber(boothNo);
    setToPollingAddress(address);
  };
  const onSearchClick = () => {
    getPollingBoothListCount(candidateName);
  };

  const onClearClick = () => {
    setFromPollingAddress("");
    setToPollingAddress("");
    setfromBoothNumber(0);
    settoBoothNumber(0);
    getPollingBoothListCount(candidateName, 0, 0);
  };

  // Load the fonts into vfs
  pdfMake.vfs = {
    ...pdfFonts.pdfMake.vfs, // Keep default fonts
    "AnekDevanagari-Regular.ttf": MarathiFont, // Base64 encoded normal font
    "AnekDevanagari-Bold.ttf": MarathiFontBold, // Base64 encoded bold font
  };

  // Set up custom fonts
  const fonts = {
    AnekDevanagari: {
      normal: "AnekDevanagari-Regular.ttf",
      bold: "AnekDevanagari-Bold.ttf", // Define the bold style
    },
  };

  const generateDocDefinition = () => {
    const tableRows = boothList.map((item) => [
      { text: item.boothNumber.toString(), font: "AnekDevanagari", fontSize: 8 },
      { text: item.pollingAddress, font: "AnekDevanagari", lineHeight: 0.9, fontSize: 8 },
      { text: item.maleCount.toString(), font: "AnekDevanagari", fontSize: 8 },
      { text: item.feMaleCount.toString(), font: "AnekDevanagari", fontSize: 8 },
      { text: item.thirdGenderCount.toString(), font: "AnekDevanagari", fontSize: 8 },
      { text: item.totalCountGender.toString(), font: "AnekDevanagari", fontSize: 8 },
    ]);

    return {
      content: [
        {
          text: "मतदान केंद्र",
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 10],
          font: "AnekDevanagari",
        },
        {
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: [35, "*", 30, 30, 30, 40],
            body: [
              [
                { text: "क्रमांक", bold: true, font: "AnekDevanagari", fontSize: 10 },
                { text: "मतदानाचा पत्ता.", bold: true, font: "AnekDevanagari", fontSize: 10 },
                { text: "पुरुष", bold: true, font: "AnekDevanagari", fontSize: 10 },
                { text: "स्त्री", bold: true, font: "AnekDevanagari", fontSize: 10 },
                { text: "तृतिय", bold: true, font: "AnekDevanagari", fontSize: 10 },
                { text: "एकूण", bold: true, font: "AnekDevanagari", fontSize: 10 },
              ],
              ...tableRows,
            ],
          },
          layout: "lightHorizontalLines",
        },
      ],
      defaultStyle: {
        font: "AnekDevanagari",
      },
    };
  };

  const uploadFiles = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/UploadCandidateFile`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + authtoken,
        },
      })
      .then((res) => res)
      .catch((err) => {
        if (err.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
      });
  };

  const onFileUpload = async (fileBlob, fileName) => {
    const formData = new FormData();
    formData.append("PollingBoothFile", fileBlob, fileName); // Append blob with filename
    formData.append("CandaidateId", userId); // Candidate ID

    try {
      const response = await uploadFiles(formData);
      if (response && response.status === 200) {
        toast.current?.show({
          severity: "success",
          summary: "फाइल यशस्वीरित्या शेअर केली",
          life: 3000,
        });
        return true; // Return true on successful upload
      } else {
        throw new Error("फाइल अपलोड अयशस्वी");
      }
    } catch (error) {
      toast.current?.show({
        severity: "error",
        summary: "फाइल अपलोड अयशस्वी.",
        life: 3000,
      });
      return false; // Return false on failure
    }
  };

  const handlePrint = () => {
    const docDefinition = generateDocDefinition();
    pdfMake.createPdf(docDefinition, null, fonts).print();
  };

  const handleShare = (response) => {
    const uploadedFileURL = response;

    // Generate the WhatsApp link with the file URL
    const message = "Please find the PDF here: ";
    const whatsappURL = `https://wa.me/?text=${encodeURIComponent(message)}%0A${encodeURIComponent(uploadedFileURL)}`;

    // Open WhatsApp Web with the pre-filled message
    window.open(whatsappURL, "_blank");
  };

  const savePoolingData = (values) => {
    let data = JSON.stringify({
      CandaidateId: userId,
      PollingBoothFile: "PollingBoothDetails.pdf",
    });

    savePoolingBooth(data).then((res) => {
      if (res.data) {
        if (res.data.statusCode === 401) {
          toast.current?.show({
            severity: "error",
            summary: res.data.message,
            life: 3000,
          });
        } else {
          const docDefinition = generateDocDefinition();

          // Generate the PDF as a blob and give it a filename
          pdfMake.createPdf(docDefinition, null, fonts).getBlob(async (blob) => {
            const fileName = "PollingBoothDetails.pdf"; // Set the desired filename

            // Call the onFileUpload function to upload the file and check its success
            const uploadSuccess = await onFileUpload(blob, fileName);

            // Call getPoolingbooth only if the upload was successful
            if (uploadSuccess) {
              const poolingboothSuccess = await getPoolingbooth();

              // Call handleShare only if getPoolingbooth was successful
              if (poolingboothSuccess) {
                handleShare();
              }
            }
          });
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Something went wrong, please contact your admin",
          life: 3000,
        });
      }
    });
  };

  const savePoolingBooth = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/CandidateInfo`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authtoken,
        },
      })
      .then((res) => res)
      .catch((err) => {
        if (err.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
      });
  };

  const getPoolingbooth = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/GetCandidateInfo?CandaidateId=${userId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authtoken,
        },
      });

      // Assuming the API returns 200 for a successful response
      if (response.status === 200) {
        setPooligBoothFile(response.data.pollingBoothFile);
        handleShare(response.data.pollingBoothFile);
        return true; // Return true on successful call
      } else {
        throw new Error("Failed to fetch polling booth data");
      }
    } catch (err) {
      if (err.response.status === 401) {
        const loginURL = sessionStorage.getItem("candidateLoginURL");
        if (loginURL) {
          // Redirect back to the candidate's login page
          window.location.href = loginURL;
        } else {
          // Fallback to a default login page if the URL is not stored
          window.location.href = process.env.REACT_APP_PROD_API_URL;
        }
      }
      return false; // Return false if the call fails
    }
  };

  const handleCardClick = (item, index) => {
    navigate("/pollingBoothDetails", { state: { boothDetails: item } });
  };

  return (
    <div className="app main-container-login">
      <Toast ref={toast} />
      <div className="header">
        <div className="sticky-header">
          <div className="row mt-2">
            <div className="col-10 d-flex align-items-center">
              <Button
                icon="pi pi-chevron-left"
                severity="secondary"
                onClick={() => {
                  onDashboardclick();
                }}
                className="back-button" // Apply the custom class
              />
              <label htmlFor="Constituency" className="form-label Polling text-light labelDashboard marLeftHeader mt-2">
                मतदान केंद्राची यादी
              </label>
            </div>
            <div className="col-2 text-end mt-2">
              <img
                src={home}
                alt="Whatsapp"
                className="home-img float-end"
                onClick={onDashboardclick}
                style={{ marginBottom: "4px", marginLeft: "10px" }}
              />
            </div>
          </div>

          <div className="d-flex justify-content-end mb-2">
            <button className="btn w-auto text-15 text-white me-2" onClick={handlePrint}>
              <img src={print} alt="Print" style={{ marginLeft: "5px" }} /> प्रिंट
            </button>
            <button className="btn w-auto text-15 text-white" onClick={savePoolingData}>
              <img src={share} alt="Share" style={{ marginLeft: "5px" }} /> शेअर
            </button>
          </div>
        </div>

        {!votersListLoading ? (
          boothList.map((item, index) => (
            <div
              key={index}
              onClick={() => handleCardClick(item, index)}
              style={{ cursor: "pointer" }}
              className="card voter-info-card text-light text-14  mb-2 cu"
            >
              <div className="row mx-auto border-bottom pb-1">
                <div className="col-3">
                  <span>{item.boothNumber}</span>
                  <br />
                  <span>{item.town}</span>
                </div>
                <div className="col-9">
                  <span>{item.pollingAddress}</span>
                </div>
              </div>
              <div className="row pollingrow mx-20 mt-1">
                <div className="col-3 pollingrow">
                  <span>पुरुष&nbsp; {item.maleCount}</span>
                </div>
                <div className="col-3 pollingrow">
                  <span>स्त्री&nbsp; {item.feMaleCount}</span>
                </div>
                <div className="col-3 pollingrow">
                  <span>तृतिय&nbsp; {item.thirdGenderCount}</span>
                </div>
                <div className="col-3 pollingrow">
                  <span>
                    एकूण&nbsp; <span style={{ fontWeight: "bold" }}>{item.totalCountGender}</span>
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <>
            {" "}
            <div className="d-flex justify-content-center">
              <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
