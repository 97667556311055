import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import photo from "../Assets/Images/politicallogo.png";
import banner from "../Assets/Images/banner.png";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "../Auth/AuthContext";
export default function Login(props) {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();

  const validate = () => {
    let errors = {};
    if (!userName) errors.userName = "यूजरनेम आवश्यक आहे.";
    if (!password) errors.password = "पासवर्ड आवश्यक आहे.";
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const clearValues = () => {
    localStorage.removeItem("token");
  };
  const onLogin = async () => {
    clearValues();
    if (!validate()) {
      toast.error("कृपया सर्व फील्ड योग्यरित्या भरा.");
      return;
    }
    setLoading(true);
    let data = JSON.stringify({ userName, password });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Login/Login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      const token = response.data.response.token;
      const userID = response.data.response.email;
      const userName = response.data.response.userName;

      localStorage.setItem("token", token);
      localStorage.setItem("userId", userID);
      localStorage.setItem("userName", userName);
      const decoded = jwtDecode(token);
      login(token);
      navigate("/candidatedashboard");
      if (decoded.isExistsInCandidate == "True") {
        navigate("/candidatedashboard");
      } else {
        navigate("/displayvoterlist");
      }
    } catch (error) {
      toast.error("अवैध यूजरनेम आणि पासवर्ड");
    } finally {
      setLoading(false);
    }
  };
  const onEnterClick = (e) => {
    if (e.keyCode == 13) {
      onLogin();
    }
  };
  return (
    <div className="login-page">
      <div className="header header-dashboard">
        <div className="row desktop-img">
          <div className="col-3">
            <img src={photo} alt="Scanner image" />
          </div>
          <div className="col-6 header-middle-title">
            <div className="smaller-text">२८२ सांगली विधानसभा मतदार संघातील -- पक्षाचे अधिकृत उमेदवार </div>
            <div className="large-text">
              श्रीमान अभिषेक कुलकर्णी यांना <br />
              प्रचंड बहुमताने निवडून द्या !
            </div>
          </div>
          <div className="col-3 text-end" style={{ padding: "0px" }}>
            {" "}
            <img src={photo} alt="Scanner image" style={{ marginRight: "13px" }} />{" "}
          </div>
        </div>
      </div>
      <div>
        <img src={banner} alt="Scanner image" className="mobile-img responsive-img" />
      </div>
      <div className="container">
        <div className=" mt-4 text-center">
          <div className="containerStyle">
            <img src={photo} alt="Scanner image" />
          </div>
          <label htmlFor="Constituency" className="form-label Constituency text-login mt-4">
            282 सांगली विधानसभा मतदारसंघ
          </label>

          <form>
            <div className="mb-3 text-center">
              <div>
                <input
                  type="text"
                  className={`LoginPage input-LoginPage capsule-input ${errors.userName ? "is-invalid" : ""}`}
                  id="voterIdInput"
                  placeholder="युजर नेम"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
                <span className="text-white d-flex justify-content-center mt-2">आपले नाव</span>
              </div>
              {errors.userName && (
                <div className="invalid-feedback d-block text-center mt-1" style={{ color: "red" }}>
                  {errors.userName}
                </div>
              )}
            </div>

            <div className="mb-4 text-center">
              <div>
                <input
                  type="password"
                  className={`LoginPage input-LoginPage capsule-input ${errors.password ? "is-invalid" : ""}`}
                  id="passwordInput"
                  placeholder="पासवर्ड"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={onEnterClick}
                />
              </div>
              <span className="text-white d-flex justify-content-center mt-2">आपली जन्मतारीख</span>
              {errors.password && (
                <div className="invalid-feedback d-block text-center mt-1" style={{ color: "red" }}>
                  {errors.password}
                </div>
              )}
            </div>
          </form>

          <div className="row mt-4  ">
            <div className="col-7 d-flex justify-content-center">
              <div className="form-check" style={{ marginLeft: "54px" }}>
                <input className="form-check-input  mt-4" type="checkbox" id="flexCheckDefault" />
                <label className="form-check-label mt-4  text-light " htmlFor="flexCheckDefault">
                  लक्षात ठेवा
                </label>
              </div>
            </div>
            {loading ? (
              <button className="btn btn-primary mt-4" type="button" disabled>
                <span className="spinner-border spinner-border-sm mt-2" role="status" aria-hidden="true"></span> लॉगिन
              </button>
            ) : (
              <button type="button" className="btn btn-primary confirm-btnLogin mt-2" onClick={onLogin}>
                लॉगिन
              </button>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
