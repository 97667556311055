import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import home from "../Assets/Images/home_icon.svg";
import { useNavigate } from "react-router-dom";
import "jspdf-autotable";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { ThreeDots } from "react-loader-spinner";
import { useLocation } from "react-router-dom";

export default function PollingBoothDetails() {
  const navigate = useNavigate();
  const [votersListLoading, setVotersListLoading] = useState(true); // Default to true to show the loader initially
  const [boothList, setBoothListList] = useState([]);
  const [fromBoothNumber, setfromBoothNumber] = useState(0);
  const [toBoothNumber, settoBoothNumber] = useState(0);
  const toast = useRef(null);
  const userId = localStorage.getItem("userId");
  const authtoken = localStorage.getItem("token");
  const [PoolingBoothDetails, setPoolingBoothDetails] = useState([]);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const [scrollMore, setScrollMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const location = useLocation();
  const { boothDetails } = location.state || {};

  useEffect(() => {
    if (authtoken) {
      const decoded = jwtDecode(authtoken);
      let candidateName = decoded.UserName;
      // getPollingBoothListCount(candidateName);
    }
    getPoolingboothDetails();
  }, []);

  const getPollingBoothListCount = (candidateNm) => {
    const Slughurl = localStorage.getItem("slugUrl");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getBoothDetailscount?candidateName=${Slughurl}&FromBoothNumber=${fromBoothNumber}&ToBoothNumber=${toBoothNumber}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.response.length > 0) {
          setBoothListList(response.data.response);
        }
        setfromBoothNumber(0);
        settoBoothNumber(0);
      })
      .catch((error) => {
        handleApiError(error);
      })
      .finally(() => {
        setVotersListLoading(false); // Set loading to false after the request completes
      });
  };

  const onDashboardclick = () => {
    navigate("/pollingbooth");
  };


  const handleApiError = (error) => {
    if (error.response.status === 401) {
      const loginURL = sessionStorage.getItem("candidateLoginURL");
      window.location.href = loginURL || process.env.REACT_APP_PROD_API_URL;
    }
    setVotersListLoading(false);
  };

  const getPoolingboothDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/GetPollingBooth?CandaidateId=${userId}&BoothNumber=${boothDetails.boothNumber}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authtoken,
          },
        }
      );
      if (response.status === 200) {
        setPoolingBoothDetails(response.data.response);
      }
    } catch (err) {
      handleApiError(err);
    } finally {
      setVotersListLoading(false); // Ensure this is set only after the API call completes
    }
  };

  const onShowmoreClicked = () => {
    if (!isApiCallInProgress && scrollMore) {
      setPageNumber(pageNumber + 1);
    }
  };

  return (
    <div className="app main-container-login">
      <Toast ref={toast} />
      <div className="header">
        <div className="row mt-2">
          <div className="col-10 d-flex align-items-center">
            <Button
              icon="pi pi-chevron-left"
              severity="secondary"
              onClick={() => {
                onDashboardclick();
              }}
              className="back-button" // Apply the custom class
            />
            <label htmlFor="Constituency" className="form-label Polling text-light labelDashboard marLeftHeader mt-2">
              मतदान केंद्राची यादी
            </label>
          </div>
          <div className="col-2 text-end mt-2">
            <img
              src={home}
              alt="Whatsapp"
              className="home-img float-end"
              onClick={onDashboardclick}
              style={{ marginBottom: "4px", marginLeft: "10px" }}
            />
          </div>
        </div>
{!votersListLoading ? (
        <BottomScrollListener onBottom={onShowmoreClicked}>
          {(scrollRef) => (
            <div
              className="mt-2"
              ref={scrollRef}
              style={{ height: "650px",
                 overflow: "auto",
                  padding: "4px 8px 4px 8px",
                   backgroundColor: "#3e3e3e", 
                   borderRadius: "10px" }}
            >
 
              <div className="card voter-list-card birthdayMarginTop">
                <div className="row">
                  <div className="col-2 pr-0 total-details-Surname">
                    <span>अनु क्र.</span>
                  </div>
                  <div className="col-4 total-details-Surname">
                    <span>नाव</span>
                  </div>
                  <div className="col-4 total-details-Surname">
                    <span>व्होटर आयडी</span>
                  </div>
                  <div className="col-2 total-details-Surname">
                    <span>लिंग/वय</span>
                  </div>
                </div>
              </div>
              {
                Object.entries(
                  PoolingBoothDetails.reduce((acc, item) => {
                    const key = `${item.boothNumber}-${item.addressOfPollingStation}`;
                    if (!acc[key]) {
                      acc[key] = { boothNumber: item.boothNumber, address: item.addressOfPollingStation, voters: [] };
                    }
                    acc[key].voters.push(item);
                    return acc;
                  }, {})
                ).map(([key, group], index) => (
                  <div key={index}>
                    <div className="row d-flex mx-auto header-cell kendraList">
                      <div className="col-12 ps-0 total-details-SurnameList mt-2">
                        <span>{group.boothNumber} - {group.address}</span>
                      </div>
                    </div>
                    {group.voters.map((item, number) => (
                      <div key={number} className="card voter-list-card birthdayMarginTop">
                        <div className="row d-flex mx-auto">
                          <div className="col-2 total-details-Surname">
                            <span>{number + 1}</span>
                          </div>
                          <div className="col-4 total-details-Surname">
                            <span>{item.fullName}</span>
                          </div>
                          <div className="col-4 total-details-Surname">
                            <span>{item.voterId}</span>
                          </div>
                          <div className="col-2 total-details-Surname">
                            <span>{item.gender === "महिला" ? "म." : "पु."}/{item.age}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
                </div>
              )}
            </BottomScrollListener>
          ) :  <div className="d-flex justify-content-center">
            <ThreeDots height="30" width="30" radius="9" color="white" 
            ariaLabel="three-dots-loading" visible={true} />
            </div>
          }
      </div>
    </div>
  );
}
