import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

export default function CandidateDashbord() {
  const [counts, setCounts] = useState([]);
  const navigate = useNavigate();
  const [candidateData, setCandidateData] = useState(null);
  const [name, setuserName] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const authtoken = localStorage.getItem("token");
  // Consolidate the useEffect hooks
  useEffect(() => {
    const userName = localStorage.getItem("slugUrl");
    if (userName) {
      setuserName(userName); // Set the name
      getCandidate(userName); // Fetch candidate data
      fetchCounts(userName); // Fetch counts data
    }
  }, []);

  // Fetch counts data
  const fetchCounts = (userName) => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/GetAllCounts?CandidateName=${userName}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        setCounts(response.data.response);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
        setLoading(false);
      });
  };

  // Fetch candidate data
  const getCandidate = async (userName) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/CandidateHistory?SlughUrl=${userName}`);
      setCandidateData(response.data); // Update state with the data
    } catch (err) {
      if (err.response.status === 401) {
        const loginURL = sessionStorage.getItem("candidateLoginURL");
        if (loginURL) {
          // Redirect back to the candidate's login page
          window.location.href = loginURL;
        } else {
          // Fallback to a default login page if the URL is not stored
          window.location.href = process.env.REACT_APP_PROD_API_URL;
        }
      }
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const getRecordCount = (tableName) => {
    const record = counts.find((item) => item.tableName === tableName);
    return record ? record.recordCount : "0";
  };

  const onPoolingBoothClick = () => {
    navigate("/pollingbooth");
  };

  const onVoterlistClick = () => {
    navigate("/displayvoterlist");
  };

  const onVoterbyage = () => {
    navigate("/votersbyage");
  };

  const onAreawiseListClick = () => {
    navigate("/areawiselist");
  };

  const onVoterByeCastClick = () => {
    navigate("/voterbycast");
  };
  const onVisitedVotersClick = () => {
    navigate("/visitedvoters");
  };
  const onChangedAddressVotersClick = () => {
    navigate("/changedaddressvoters");
  };
  const onPollingAreaPlacesClick = () => {
    navigate("/pollingarea");
  };
  const onFieldWorkerlistClick = () => {
    navigate("/fieldworkerlist");
  };
  const onMarriedFemalelistClick = () => {
    navigate("/marriedfemalelist");
  };
  const onSurnamelistClick = () => {
    navigate("/surnamelist");
  };
  const onFamilyAddedVotersClick = () => {
    navigate("/votersfamilylist");
  };
  const onPhoneNumberListClick = () => {
    navigate("/phonenumberlist");
  };
  const onTodaysBirthdayListClick = () => {
    navigate("/birthdaylist");
  };
  const onlogoutClick = () => {
    const loginURL = sessionStorage.getItem("candidateLoginURL");
    if (loginURL) {
      // Redirect back to the candidate's login page
      window.location.href = loginURL;
    } else {
      // Fallback to a default login page if the URL is not stored
      window.location.href = process.env.REACT_APP_PROD_API_URL;
    }
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
  };
  const onPollingAgentClick = () => {
    navigate("/pollingAgent");
  };
  const onQRLinkShareClick = () => {
    navigate("/graphForCount");
  };
  const onProfilePageClick = () => {
    navigate("/candidateconfig");
  };

  return (
    <div className="app main-container-login dashboard-main-page">
      <div className="header header-dashboard header-candidate">
        <div className="row d-flex align-items-center justify-content-between">
          <div className="col-1">
            <img src={candidateData?.logo} style={{ maxWidth: "33px" }} alt="congress" />
          </div>
          <div className="col-10 text-center mt-1" style={{ fontSize: "17px" }}>
            {candidateData?.constituency} विधानसभा मतदारांची माहिती
          </div>
          <div className="col-1 d-flex justify-content-end mt-2">
            <i className="pi pi-bars" style={{ fontSize: "1rem", cursor: "pointer" }} onClick={toggleMenu}></i>
            {isMenuOpen && (
              <div
                className="menu-dropdown"
                style={{
                  position: "absolute",
                  top: "50px",
                  right: "2px",
                  backgroundColor: "#3e3e3e",
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)",
                  padding: "10px",
                  borderRadius: "5px",
                  zIndex: "999",
                }}
              >
                <i
                  className="pi pi-cog"
                  style={{ fontSize: "1rem", marginRight: "9px", cursor: "pointer", display: "block" }}
                  onClick={onProfilePageClick}
                ></i>
                <i
                  className="pi pi-sign-out"
                  style={{ fontSize: "1rem", cursor: "pointer", display: "block", marginTop: "34px" }}
                  onClick={onlogoutClick}
                ></i>
              </div>
            )}
          </div>
        </div>
      </div>

      {loading ? (
        <div className="d-flex justify-content-center">
          <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
        </div>
      ) : (
        <div className="container-fluid ">
          <div className="row mx-auto card-row">
            <div className="col-6 p-1 mt-2">
              <div
                className="card show-cursor"
                onClick={onPoolingBoothClick}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
              >
                <div className="count-container" style={{ textAlign: "center" }}>
                  <div className="count-text">
                    <span className="count">{getRecordCount("TotalBooths")}</span>
                    <br />
                    मतदान केंद्राची यादी
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 p-1 mt-2">
              <div
                className="card show-cursor"
                onClick={onVoterlistClick}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
              >
                <div className="count-container" style={{ textAlign: "center" }}>
                  <div className="count-text">
                    <span className="count">{getRecordCount("VoterList")}</span>
                    <br />
                    संपूर्ण मतदार यादी
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 p-1">
              <div
                className="card show-cursor"
                onClick={onSurnamelistClick}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
              >
                <div className="count-container" style={{ textAlign: "center" }}>
                  <div className="count-text">
                    <span className="count">{getRecordCount("VoterBySurname")}</span>
                    <br />
                    आडनावानुसार मतदार
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 p-1">
              <div
                className="card show-cursor"
                onClick={onVoterbyage}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
              >
                <div className="count-container" style={{ textAlign: "center" }}>
                  <div className="count-text">
                    <span className="count">{getRecordCount("YoungAge")}</span>
                    <br />
                    वयानुसार मतदार
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 p-1">
              <div
                className="card show-cursor"
                onClick={onFamilyAddedVotersClick}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
              >
                <div className="count-container" style={{ textAlign: "center" }}>
                  <div className="count-text">
                    <span className="count">{getRecordCount("TotalFamily")}</span>
                    <br />
                    कुटुंबानुसार मतदार
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 p-1">
              <div
                className="card show-cursor"
                onClick={onAreawiseListClick}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
              >
                <div className="count-container" style={{ textAlign: "center" }}>
                  <div className="count-text">
                    <span className="count">{getRecordCount("AreaWiseVoter")}</span>
                    <br />
                    भागानुसार यादी
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 p-1">
              <div
                className="card show-cursor"
                onClick={onPhoneNumberListClick}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
              >
                <div className="count-container" style={{ textAlign: "center" }}>
                  <div className="count-text">
                    <span className="count">{getRecordCount("PhoneNumber")}</span>
                    <br />
                    मतदार मोबाईल नंबर
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 p-1">
              <div
                className="card show-cursor"
                onClick={onVoterByeCastClick}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
              >
                <div className="count-container" style={{ textAlign: "center" }}>
                  <div className="count-text">
                    <span className="count">{getRecordCount("Caste")}</span>
                    <br />
                    जात निहाय मतदार
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 p-1">
              <div
                className="card show-cursor"
                onClick={onFieldWorkerlistClick}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
              >
                <div className="count-container" style={{ textAlign: "center" }}>
                  <div className="count-text">
                    <span className="count">{getRecordCount("TotalWorkers")}</span>
                    <br />
                    बुथ प्रमुखाची यादी
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6 p-1">
              <div
                className="card show-cursor"
                onClick={onPollingAreaPlacesClick}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
              >
                <div className="count-container" style={{ textAlign: "center" }}>
                  <div className="count-text">
                    <span className="count">{getRecordCount("PollingAreaPlaces")}</span>
                    <br />
                    मतदार संघातील गावे
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 p-1">
              <div
                className="card show-cursor"
                onClick={onMarriedFemalelistClick}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
              >
                <div className="count-container" style={{ textAlign: "center" }}>
                  <div className="count-text">
                    <span className="count">{getRecordCount("FemaleMarried")}</span>
                    <br />
                    लग्न झालेल्या महिला
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 p-1">
              <div
                className="card show-cursor"
                onClick={onVisitedVotersClick}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
              >
                <div className="count-container" style={{ textAlign: "center" }}>
                  <div className="count-text">
                    <span className="count">{getRecordCount("Visited")}</span>
                    <br />
                    भेट झालेले मतदार
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 p-1">
              <div
                className="card show-cursor"
                onClick={onChangedAddressVotersClick}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
              >
                <div className="count-container" style={{ textAlign: "center" }}>
                  <div className="count-text">
                    <span className="count">{getRecordCount("ChangedAddress")}</span>
                    <br />
                    पत्ता बदल
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 p-1">
              <div
                className="card show-cursor"
                onClick={onTodaysBirthdayListClick}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
              >
                <div className="count-container" style={{ textAlign: "center" }}>
                  <div className="count-text">
                    <span className="count">{getRecordCount("TodaysBirthday")}</span>
                    <br />
                    आजचे वाढदिवस
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6 p-1">
              <div
                className="card show-cursor"
                onClick={onPollingAgentClick}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
              >
                <div className="count-container" style={{ textAlign: "center" }}>
                  <div className="count-text">
                    <span className="count">{getRecordCount("")}</span>
                    <br />
                    पोलिंग एजन्ट
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 p-1">
              <div
                className="card show-cursor"
                onClick={onQRLinkShareClick}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
              >
                <div className="count-container" style={{ textAlign: "center" }}>
                  <div className="count-text">
                    <span className="count">{getRecordCount("GraphCount")}</span>
                    <br />
                    क्यू आर / लिंक
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
