import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { ThreeDots } from "react-loader-spinner";
import home from "../Assets/Images/home_icon.svg";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Toast } from "primereact/toast";
import WordName from "./WordNameList";
import User from "../Assets/Images/User.png";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import share from "../Assets/Images/sharevoter.svg";
import print from "../Assets/Images/print.svg";
import "jspdf-autotable";
import MarathiFont from "../Utils/MarathiFont";
import pdfMake, { log } from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import MarathiFontBold from "../Utils/MarathiFontBold"; // Bold variant base64
import moment from "moment";
import DatePicker from "react-datepicker";

export default function FieldWorkerList() {
  const navigate = useNavigate();
  const toast = useRef("");
  const [candidateName, setCandidateName] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const [scrollMore, setScrollMore] = useState(true);
  const [votersListLoading, setVotersListLoading] = useState(false);
  const [showVoterInfo, setShowVoterInfo] = useState(false);
  const [voterDetailsList, setVoterDetailsList] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showWordName, setShowWordName] = useState(false);
  const [wordNameList, setWordNameList] = useState([]);
  const [workerList, setWorkerList] = useState([]);
  const [boothList, setBoothNoList] = useState("");
  const [wardNameList, setWardNameList] = useState([]);
  const [wardNameListwithoutBooth, setWardNameListwithoutBooth] = useState([]);
  const [areaBoothList, setAreaBoothList] = useState([]);
  const [errors, setErrors] = useState({});
  const [editingWorkerId, setEditingWorkerId] = useState(null); // New state for editing
  const [isFetchingWorker, setIsFetchingWorker] = useState(false); // New state for loader
  const [isEditing, setIsEditing] = useState(false);
  const url = localStorage.getItem("slugUrl");
  const userId = localStorage.getItem("userId");
  const [showPassword, setShowPassword] = useState(false);
  const authtoken = localStorage.getItem("token");
  const handleClose = () => {
    setShowModal(false);
    setShowVoterInfo(true);
    setEditingWorkerId(null);
    setWorkerList([]);
  };

  const reject = () => {};

  const onDeleteWorkerDetails = (workerId) => {
    confirmDialog({
      message: `तुमची खात्री आहे की तुम्ही हा कामगार हटवू इच्छिता?`,
      header: "Confirmation",
      icon: "",
      draggable: false,
      accept: () => {
        deleteWorker(workerId);
      },
      reject,
    });
  };

  const getPollingBoothList = (workerId) => {
    setVotersListLoading(true);

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Worker/GetWordNameWithPollingStation?WorkerId=${workerId}&CandidateId=${userId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
    };

    axios(config)
      .then((response) => {
        setVotersListLoading(false);

        if (response.data.response.length > 0) {
          const formattedWardNameList = response.data.response.map((item, index) => {
            const pollingBooth = item.addressOfPollingStation || ""; // Extract addressOfPollingStation from the response object
            const wardName = item.wordName || ""; // Extract wordName from the response object

            return `${wardName}`;
          });

          setWardNameList(formattedWardNameList);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            window.location.href = loginURL;
          } else {
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
        setVotersListLoading(false);
      });
  };

  const onEditVoterDetails = (workerId) => {
    setIsEditing(true);
    getByWorkerDetailsList(workerId);
    getPollingBoothList(workerId);
  };

  const deleteWorker = (workerId) => {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Worker/DeleteRecord?ID=${workerId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
    };

    axios(config).then((response) => {
      if (response.data.responseCode === "OK") {
        toast.current?.show({
          severity: "success",
          summary: "कार्यकर्ता यशस्वीरित्या हटवला",
          life: 3000,
        });
        getFieldWorkerList(1, true, candidateName);
      }
    });
  };

  const handleShowWordName = () => {
    setShowWordName(null);
    setShowWordName(true);
    setShowVoterInfo(false);
    setShowModal(false);
    // GetPollingDetailsByWordName(candidateName);
  };
  const handleCloseWordName = () => {
    setShowWordName(false);
    getFieldWorkerList(1, true, candidateName);
  };

  const handleShow = () => {
    setIsEditing(false);
    setShowModal(true);
    setShowVoterInfo(false);
  };

  useEffect(() => {
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      let candidateName = decoded.UserName;
      setCandidateName(candidateName);
      getFieldWorkerList(1, false, candidateName);
    }
  }, []);

  const getByWorkerDetailsList = (workerId) => {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Worker/GetById?candidateName=${url}&WorkerID=${workerId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.response) {
          setIsFetchingWorker(false);
          const workerDetails = response.data.response[0];
          setWorkerList(workerDetails);
          setEditingWorkerId(workerId); // Set the editing worker ID
          setShowModal(true); // Open the modal
          setShowVoterInfo(false); // Hide voter info if necessary
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error fetching worker details",
            life: 3000,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
        toast.current?.show({
          severity: "error",
          summary: "Error fetching worker details",
          life: 3000,
        });
      });
  };

  const getFieldWorkerList = (pageno, replace, candidateNm) => {
    if (isApiCallInProgress) return;
    if (replace) {
      setVoterDetailsList([]);
      setTotalCount(0);
    }
    setIsApiCallInProgress(true);
    setVotersListLoading(true);

    const data = {
      boothAddress: "",
      pollingAddress: "",
      fromValue: 0,
      toValue: 0,
      isGetAllValue: true,
      searchPageNumber: pageno,
      searchResultCount: 20,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getworkerdetails?candidateName=${url}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setVotersListLoading(false);
        if (response?.data?.response?.length > 0) {
          let searchedList = replace ? response.data.response : [...voterDetailsList, ...response.data.response];
          setVoterDetailsList(searchedList);
          setTotalCount(response.data.response[0].totalCount);
          updateVoterDetailsWithAdditionalData(url, searchedList);
          setScrollMore(true); // Allow scrolling for more data
        } else {
          setScrollMore(false); // No more data, stop scrolling
          setPageNumber(1); // Reset to the first page
        }
        setShowVoterInfo(true);
      })
      .catch((error) => {
        setIsApiCallInProgress(false);
        setVotersListLoading(false);
        if (error.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
      })
      .finally(() => {
        setIsApiCallInProgress(false);
        setVotersListLoading(false);
      });
  };

  const updateVoterDetailsWithAdditionalData = (candidateName, searchedList) => {
    const url = `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/GetWorkerWorkDetails?candidateName=${candidateName}`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authtoken,
    };

    axios
      .get(url, { headers })
      .then((response) => {
        const workerDetails = response.data.response;
        if (!Array.isArray(workerDetails)) {
          return;
        }
        const updatedList = searchedList.map((voter) => {
          const workerDetail = workerDetails.find((detail) => detail.workerId === voter.workerId);
          if (workerDetail) {
            return {
              ...voter,
              totalVoters: workerDetail.totalVoters,
              workerVisitCount: workerDetail.workerVisitCount,
            };
          }
          return voter;
        });

        setVoterDetailsList(updatedList);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
      });
  };

  const onShowmoreClicked = () => {
    if (!isApiCallInProgress && scrollMore) {
      const nextPageNumber = pageNumber + 1;
      setPageNumber(nextPageNumber);
      let replace = false; // Append to existing list
      getFieldWorkerList(nextPageNumber, replace, candidateName);
    }
  };

  const onDashboardclick = () => {
    navigate("/candidatedashboard");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setWorkerList((prevList) => {
      if (name === "birthdate") {
        const formattedPassword = value.split("-").reverse().join(""); // "DDMMYYYY"
        return {
          ...prevList,
          [name]: value,
          password: formattedPassword,
        };
      }
      // For other fields, update as usual
      return {
        ...prevList,
        [name]: value,
      };
    });
  };

  const handleCheckboxChange = (e) => {
    const value = e.target.checked;
    setWorkerList((prevDetails) => ({
      ...prevDetails,
      isDisable: value,
    }));
  };

  const getAssignAreaDetails = (boothNumber, wardName) => {
    setBoothNoList(boothNumber);
    setWardNameList(wardName);
    setShowWordName(false);
    setShowModal(true);

    let AddressOfPollingStation = [];
    let WordName = [];

    wardName.forEach((item) => {
      const parts = item.split("-");
      AddressOfPollingStation.push(parts[0]);
      WordName.push(parts.slice(1).join("-"));
    });
    setWardNameListwithoutBooth(WordName);
    setAreaBoothList(AddressOfPollingStation);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!workerList.mobile) newErrors.mobile = "मोबाईल नंबर आवश्यक आहे";
    if (!workerList.mobile || workerList.mobile.length !== 10) newErrors.mobile = "मोबाईल नंबर १० अंकी असावा";
    if (workerList.email && !/\S+@\S+\.\S+/.test(workerList.email)) newErrors.email = "वैध ईमेल आवश्यक आहे";
    if (!workerList.userName) newErrors.userName = "यूजरनेम आवश्यक आहे";
    if (!workerList.password) newErrors.password = "पासवर्ड आवश्यक आहे";
    if (!workerList.birthdate) newErrors.birthdate = "जन्मदिनांक आवश्यक आहे";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onAddOrUpdateWorkerList();
    }

    if (isEditing === false && workerList.userName) {
      const message = `Hello, your username is: ${workerList.userName}, and your password is: Your BirthDate e.g - 16121998`;
      if (workerList.mobile) {
        const whatsappUrl = `https://api.whatsapp.com/send?phone=91${workerList.mobile}&text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, "_blank");
      } else {
        alert("Mobile number is required to send WhatsApp message.");
      }
    }
  };

  const onAddOrUpdateWorkerList = () => {
    const token = localStorage.getItem("token");
    const decoded = jwtDecode(token);
    let formattedWardNames = "";
    let formattedAddressOfPollingStation = "";

    if (Array.isArray(wardNameList) && Array.isArray(boothList)) {
      formattedWardNames = wardNameList.join("#");
      formattedAddressOfPollingStation = areaBoothList.join("#");
    }

    const data = {
      firstName: workerList.firstName,
      middleName: workerList.middleName,
      lastName: workerList.lastName,
      mobile: workerList.mobile,
      mobile1: workerList.mobile1,
      AddressOfPollingStation: formattedAddressOfPollingStation,
      WordName: formattedWardNames,
      email: workerList.email,
      aadhaarCard: workerList.aadhaarCard,
      voterId: workerList.voterID,
      userName: workerList.userName,
      password: workerList.password,
      IsDisable: workerList.isDisable,
      BirthDate: workerList.birthdate,
    };

    let url = "";
    let method = "";
    const Slughurl = localStorage.getItem("slugUrl");

    if (editingWorkerId) {
      // Update existing worker
      url = `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Worker/UpdateRecord?candidateName=${Slughurl}&WorkerID=${editingWorkerId}`;
      method = "post";
    } else {
      // Add new worker
      url = `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Worker/addnewworker?candidateName=${Slughurl}`;
      method = "post";
    }

    const config = {
      method: method,
      url: url,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.statusCode === 200) {
          toast.current.show({
            severity: "success",
            detail: editingWorkerId ? "कार्यकर्ता यशस्वीरित्या अपडेट झाला" : "कामगार यशस्वीरित्या जोडला गेला",
            life: 3000,
          });
          handleClose();
          setEditingWorkerId(null);
          getFieldWorkerList(1, true, candidateName);
        } else {
          toast.current.show({
            severity: "error",
            detail: editingWorkerId ? "Error updating worker" : "Error adding worker",
            life: 3000,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
        if (error.response.status === 500) {
          toast.current.show({
            severity: "error",
            detail: "यूजरनेम आधीपासून अस्तित्वात आहे",
            life: 3000,
          });
        }
      });
  };

  // Load the fonts into vfs
  pdfMake.vfs = {
    ...pdfFonts.pdfMake.vfs, // Keep default fonts
    "AnekDevanagari-Regular.ttf": MarathiFont, // Base64 encoded normal font
    "AnekDevanagari-Bold.ttf": MarathiFontBold, // Base64 encoded bold font
  };

  // Set up custom fonts
  const fonts = {
    AnekDevanagari: {
      normal: "AnekDevanagari-Regular.ttf",
      bold: "AnekDevanagari-Bold.ttf", // Define the bold style
    },
  };

  const generateDocDefinition = () => {
    const tableRows = voterDetailsList.map((item) => [
      {
        text: `${item.firstName} ${item.middleName ? item.middleName : ""} ${item.lastName}`,
        font: "AnekDevanagari",
        fontSize: 8,
      },
      { text: item?.aadhaarCard, font: "AnekDevanagari", lineHeight: 0.9, fontSize: 8 },
      { text: item?.mobile, font: "AnekDevanagari", fontSize: 8 },
      { text: item?.voterID, font: "AnekDevanagari", fontSize: 8 },
      // { text: item.workerVisitCount.toString(), font: "AnekDevanagari", fontSize: 8 },
    ]);

    return {
      content: [
        {
          text: "कार्यकर्ता यादी",
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 10],
          font: "AnekDevanagari",
        },
        {
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: ["*", "*", "*", "*"],
            body: [
              [
                { text: "नाव", bold: true, font: "AnekDevanagari", fontSize: 10 },
                { text: "मतदार ओळखपत्र", bold: true, font: "AnekDevanagari", fontSize: 10 },
                { text: "मोबाईल", bold: true, font: "AnekDevanagari", fontSize: 10 },
                { text: "व्होटर आयडी", bold: true, font: "AnekDevanagari", fontSize: 10 },
              ],
              ...tableRows,
            ],
          },
          layout: "lightHorizontalLines",
        },
      ],
      defaultStyle: {
        font: "AnekDevanagari",
      },
    };
  };

  const uploadFiles = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/UploadCandidateFile`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + authtoken,
        },
      })
      .then((res) => res)
      .catch((err) => {
        if (err.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
      });
  };

  const onFileUpload = async (fileBlob, fileName) => {
    const formData = new FormData();
    formData.append("FieldworkerFile", fileBlob, fileName); // Append blob with filename
    formData.append("CandaidateId", userId); // Candidate ID

    try {
      const response = await uploadFiles(formData);
      if (response && response.status === 200) {
        toast.current?.show({
          severity: "success",
          summary: "फाइल यशस्वीरित्या शेअर केली",
          life: 3000,
        });
        return true; // Return true on successful upload
      } else {
        throw new Error("फाइल अपलोड अयशस्वी");
      }
    } catch (error) {
      toast.current?.show({
        severity: "error",
        summary: "फाइल अपलोड अयशस्वी.",
        life: 3000,
      });
      return false; // Return false on failure
    }
  };

  const handlePrint = () => {
    const docDefinition = generateDocDefinition();
    pdfMake.createPdf(docDefinition, null, fonts).print();
  };

  const handleShare = (response) => {
    const uploadedFileURL = response;

    // Generate the WhatsApp link with the file URL
    const message = "Please find the PDF here: ";
    const whatsappURL = `https://wa.me/?text=${encodeURIComponent(message)}%0A${encodeURIComponent(uploadedFileURL)}`;

    // Open WhatsApp Web with the pre-filled message
    window.open(whatsappURL, "_blank");
  };

  const savePoolingData = (values) => {
    let data = JSON.stringify({
      CandaidateId: userId,
      FieldworkerFile: "FieldworkerFile.pdf",
    });

    savePoolingBooth(data).then((res) => {
      if (res.data) {
        if (res.data.statusCode === 401) {
          toast.current?.show({
            severity: "error",
            summary: res.data.message,
            life: 3000,
          });
        } else {
          const docDefinition = generateDocDefinition();

          // Generate the PDF as a blob and give it a filename
          pdfMake.createPdf(docDefinition, null, fonts).getBlob(async (blob) => {
            const fileName = "FieldworkerFile.pdf"; // Set the desired filename

            // Call the onFileUpload function to upload the file and check its success
            const uploadSuccess = await onFileUpload(blob, fileName);

            // Call getPoolingbooth only if the upload was successful
            if (uploadSuccess) {
              const poolingboothSuccess = await getPoolingbooth();

              // Call handleShare only if getPoolingbooth was successful
              if (poolingboothSuccess) {
                handleShare();
              }
            }
          });
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Something went wrong, please contact your admin",
          life: 3000,
        });
      }
    });
  };

  const savePoolingBooth = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/CandidateInfo`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authtoken,
        },
      })
      .then((res) => res)
      .catch((err) => {
        if (err.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
      });
  };

  const getPoolingbooth = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      };

      const response = await axios.get(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/GetCandidateInfo?CandaidateId=${userId}`, { headers });

      // Assuming the API returns 200 for a successful response
      if (response.status === 200) {
        handleShare(response.data.fieldworkerFile);
        return true; // Return true on successful call
      } else {
        throw new Error("Failed to fetch polling booth data");
      }
    } catch (err) {
      if (err.response.status === 401) {
        const loginURL = sessionStorage.getItem("candidateLoginURL");
        if (loginURL) {
          // Redirect back to the candidate's login page
          window.location.href = loginURL;
        } else {
          // Fallback to a default login page if the URL is not stored
          window.location.href = process.env.REACT_APP_PROD_API_URL;
        }
      }
      return false; // Return false if the call fails
    }
  };

  return (
    <>
      <ConfirmDialog />
      <Modal show={showModal && !showVoterInfo} onHide={handleClose} backdrop="static" className="modal-dark-theme fullscreen" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{editingWorkerId ? "कार्यकर्ता अपडेट करा" : "नवीन कार्यकर्ता जोडा"}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form>
            <div className="row">
              {/* Last Name */}
              <div className="form-group col-6">
                <label>आडनाव</label>
                <input
                  type="text"
                  name="lastName"
                  className={`form-control ${errors.lastName ? "is-invalid" : ""}`}
                  value={workerList.lastName}
                  onChange={handleInputChange}
                />
                {errors.lastName && <div className="invalid-feedback text-center">{errors.lastName}</div>}
              </div>

              <div className="form-group col-6">
                <label>प्रथम नाव</label>
                <input
                  type="text"
                  name="firstName"
                  className={`form-control ${errors.firstName ? "is-invalid" : ""}`}
                  value={workerList.firstName}
                  onChange={handleInputChange}
                />
                {errors.firstName && <div className="invalid-feedback text-center">{errors.firstName}</div>}
              </div>

              <div className="form-group col-6 mt-2">
                <label>मधले नाव</label>
                <input type="text" name="middleName" className="form-control" value={workerList.middleName} onChange={handleInputChange} />
              </div>

              <div className="form-group col-6 mt-2">
                <label>मतदार ओळखपत्र</label>
                <input
                  type="text"
                  name="voterID"
                  className={`form-control ${errors.voterID ? "is-invalid" : ""}`}
                  maxLength={10}
                  value={workerList.voterID}
                  onChange={handleInputChange}
                />
                {errors.voterID && <div className="invalid-feedback text-center">{errors.voterID}</div>}
              </div>
              <div className="form-group col-6 mt-2">
                <label>मोबाईल</label>
                <input
                  type="text"
                  name="mobile"
                  maxLength={10}
                  className={`form-control ${errors.mobile ? "is-invalid" : ""}`}
                  value={workerList.mobile}
                  onChange={handleInputChange}
                />
                {errors.mobile && <div className="invalid-feedback text-center">{errors.mobile}</div>}
              </div>
              <div className="form-group col-6 mt-2">
                <label>मोबाईल1</label>
                <input type="text" name="mobile1" className="form-control" maxLength={10} value={workerList.mobile1} onChange={handleInputChange} />
              </div>
              <div className="form-group col-6 mt-2">
                <label>आधार कार्ड</label>
                <input
                  type="text"
                  maxLength={12}
                  name="aadhaarCard"
                  className={`form-control ${errors.aadhaarCard ? "is-invalid" : ""}`}
                  value={workerList.aadhaarCard}
                  onChange={handleInputChange}
                />
                {errors.aadhaarCard && <div className="invalid-feedback text-center">{errors.aadhaarCard}</div>}
              </div>

              <div className="form-group col-6 mt-2">
                <label>जन्मदिनांक</label>
                {/* <input
                  type="date"
                  name="birthdate"
                  placeholder="DDMMYYYY"
                  className={`form-control ${errors.birthdate ? "is-invalid" : ""}`}
                  value={workerList.birthdate}
                  onChange={handleInputChange}
                /> */}
                <DatePicker
                  selected={
                    workerList.birthdate
                      ? moment(workerList.birthdate, "YYYY-MM-DD").toDate() // Properly convert string to Date object
                      : null
                  } // Ensure this is a valid Date object or null
                  onChange={(date) =>
                    handleInputChange({
                      target: {
                        name: "birthdate",
                        value: moment(date).format("YYYY-MM-DD"), // Format the date as needed for your logic
                      },
                    })
                  }
                  dateFormat="yyyy-MM-dd"
                  className={`form-control ${errors.birthdate ? "is-invalid" : ""}`} // Conditional class based on errors
                  placeholderText="YYYY-MM-DD"
                  maxDate={moment().subtract(18, "years").toDate()} // Optional: restricts date selection to 18+ years
                  showYearDropdown
                  scrollableYearDropdown
                  disabled={editingWorkerId !== null}
                  yearDropdownItemNumber={200}
                />
                {errors.birthdate && <div className="invalid-feedback text-center">{errors.birthdate}</div>}
                {workerList.birthdate && (
                  <div className="mt-2">
                    <span className="text-12">तुमचा वाढदिवस हा तुमचा पासवर्ड आहे (DDMMYYYY).</span>
                  </div>
                )}
              </div>

              <div className="form-group col-12 mt-2">
                <label>ईमेल</label>
                <input
                  type="email"
                  name="email"
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                  value={workerList.email}
                  onChange={handleInputChange}
                />
                {errors.email && <div className="invalid-feedback text-center">{errors.email}</div>}
              </div>
              <div className="form-group col-12 mt-2">
                <label>यूजरनेम</label>
                <input
                  type="text"
                  name="userName"
                  className={`form-control ${errors.userName ? "is-invalid" : ""}`}
                  value={workerList.userName}
                  onChange={handleInputChange}
                />
                {errors.userName && <div className="invalid-feedback text-center">{errors.userName}</div>}
              </div>

              <div className="form-group col-6 mt-2" hidden={editingWorkerId !== null}>
                <label>पासवर्ड</label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className={`form-control ${errors.password ? "is-invalid" : ""}`}
                    value={workerList.password}
                    onChange={handleInputChange}
                    disabled={editingWorkerId !== null || editingWorkerId === null}
                  />
                  <button type="button" className="btn btn-outline-secondary" onClick={togglePasswordVisibility}>
                    {showPassword ? "लपवा" : "दाखवा"}
                  </button>
                  {errors.password && <div className="invalid-feedback text-center">{errors.password}</div>}
                </div>
              </div>
              <div className="form-group col-6 mt-4">
                <label> Disable</label>
                <input
                  type="checkbox"
                  style={{ marginLeft: "10px" }}
                  checked={workerList.isDisable}
                  value={workerList.isDisable}
                  onChange={handleCheckboxChange}
                />
              </div>
              {workerList.userName && (
                <div className="mt-2">
                  <span>तुम्ही लॉग इन करता तेव्हा यूजरनेम आणि पासवर्ड वापरता येतो</span>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-12 text-end">
                <button
                  className="btn btn-primary w-auto text-15 mt-3 widthCandidate"
                  onClick={(e) => {
                    e.preventDefault();
                    handleShowWordName();
                  }}
                >
                  क्षेत्र जोडा
                </button>
              </div>
            </div>

            {isEditing && (
              <div className="row d-flex">
                {wardNameList.map((ward, index) => (
                  <div key={index} className="col-12 ps-0 mt-2">
                    <div className="card voter-list-card text-light text-14">
                      <div className="row align-items-center">
                        <div className="col-12">
                          <span>{ward}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className="text-end mt-3">
              <button className="btn btn-secondary w-auto text-15 ml-right widthCandidate" onClick={handleClose}>
                रद्द करा
              </button>
              <button type="submit" className="btn btn-primary w-auto text-15" onClick={handleSubmit}>
                {editingWorkerId ? "अपडेट करा" : "सबमिट करा"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={showWordName} onHide={handleCloseWordName} backdrop="static" className="modal-dark-theme fullscreen" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>नवीन कार्यकर्ता जोडा</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WordName handleCallBack={getAssignAreaDetails} />
        </Modal.Body>
      </Modal>

      <div className="app main-container-login">
        <Toast ref={toast} />
        <div className="header">
          <div className="row mt-2">
            <div className="col-10 d-flex align-items-center">
              <Button
                icon="pi pi-chevron-left"
                severity="secondary"
                onClick={() => {
                  onDashboardclick();
                }}
                className="back-button"
              />
              <label htmlFor="Constituency" className="form-label Polling text-light labelDashboard marLeftHeader mt-2">
                कार्यकर्ता यादी
              </label>
            </div>
            <div className="col-2 text-end mt-2">
              <img
                src={home}
                alt="Whatsapp"
                className="home-img float-end"
                onClick={onDashboardclick}
                style={{ marginBottom: "4px", marginLeft: "10px" }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-end">
              <button className="btn show-btn w-auto text-15 mt-3 padMiddle" onClick={handleShow}>
                {" "}
                नवीन बुथप्रमुख / केंद्रप्रमुख / कार्यकर्ता जोडा
              </button>
            </div>
            <div className="d-flex justify-content-end mb-2">
              <button className="btn w-auto text-15 text-white me-2" onClick={handlePrint}>
                <img src={print} alt="Print" style={{ marginLeft: "5px" }} /> प्रिंट
              </button>
              <button className="btn w-auto text-15 text-white" onClick={savePoolingData}>
                <img src={share} alt="Share" style={{ marginLeft: "5px" }} /> शेअर
              </button>
            </div>
          </div>

          {showVoterInfo ? (
            <BottomScrollListener onBottom={onShowmoreClicked}>
              {(scrollRef) => (
                <div className="mt-2" ref={scrollRef} style={{ height: "650px", overflowY: "auto", overflowX: "hidden" }}>
                  {voterDetailsList.map((item, number) => (
                    <div
                      className={
                        voterDetailsList.length == number + 1 && votersListLoading == false ? " card voter-list-card" : "card voter-list-card"
                      }
                      key={number}
                    >
                      <div className="row d-flex mx-auto">
                        <div className="profile-card mt-2 mr-2">
                          <div className="avatar mt-2">
                            <img src={User} alt="Profile Image" />
                          </div>
                          <div className="profile-content">
                            <div className="profile-info mt-2">
                              {/* Edit and Delete icons aligned above the name */}
                              <div className="d-flex justify-content-end mb-1">
                                {/* Edit Icon */}
                                <i
                                  className="pi pi-pencil show-cursor me-2"
                                  title="Edit"
                                  onClick={() => onEditVoterDetails(item.workerId)}
                                  style={{
                                    backgroundColor: "white",
                                    color: "black",
                                    borderRadius: "7px",
                                    padding: "5px",
                                  }}
                                ></i>

                                {/* Delete Icon */}
                                <i
                                  className={`pi pi-trash show-cursor ${item.isDisable ? "disabled-icon" : ""}`}
                                  title="Delete"
                                  onClick={() => !item.isDisable && onDeleteWorkerDetails(item.workerId)} // Prevent click if disabled
                                  style={{
                                    backgroundColor: item.isDisable ? "lightgray" : "white", // Change background if disabled
                                    color: item.isDisable ? "gray" : "black", // Change color if disabled
                                    borderRadius: "7px",
                                    padding: "5px",
                                    cursor: item.isDisable ? "not-allowed" : "pointer", // Change cursor if disabled
                                  }}
                                ></i>
                              </div>

                              {/* Voter name */}
                              <h2 className="name">
                                {item.lastName} {item.firstName} {item.middleName}
                              </h2>

                              {/* Voter details section */}
                              <div className="row voter-detailsFieldWorker">
                                <div className="col">
                                  <span className="label">मतदार ओळखपत्र</span>
                                  <span className="value">{item.voterID}</span>
                                </div>
                                <div className="col">
                                  <span className="label">मोबाईल</span>
                                  <span className="value">{item.mobile}</span>
                                </div>
                                <div className="col">
                                  <span className="label">कामगार उपलब्ध</span>
                                  <span className="value">{item.isDisable == true ? "उपलब्ध नाही" : "उपलब्ध"}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="profile-stats">
                          <div className="ps-0 text-center">
                            <div className="value">लक्ष्य</div>
                            <div className="value">{item.totalVoters ? item.totalVoters : 0}</div>
                          </div>
                          <div className="col-6 ps-0 text-center">
                            <div className="value">गाठलेले लक्ष्य</div>
                            <div className="value">{item.workerVisitCount ? item.workerVisitCount : 0}</div>
                          </div>
                          <div className="col-6 ps-0 text-center">
                            <div className="value">लक्ष्य बाकी</div>
                            <div className="value">
                              {(item.totalVoters ? item.totalVoters : 0) - (item.workerVisitCount ? item.workerVisitCount : 0)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="profile-stats ">
                        <div className=" text-center">{/* <div className="value mr-9"> नियुक्त केलेले क्षेत्र</div> */}</div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </BottomScrollListener>
          ) : null}

          {isFetchingWorker && (
            <div className="d-flex justify-content-center">
              <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
            </div>
          )}

          {votersListLoading ? (
            <div className="d-flex justify-content-center">
              <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
