import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import home from "../Assets/Images/home_icon.svg";
import { ThreeDots } from "react-loader-spinner";
import { Button } from "primereact/button";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const GraphForCount = () => {
  const navigate = useNavigate();

  // Separate loading states for each dataset
  const [loadingStates, setLoadingStates] = useState({
    hourlyHitLoading: true,
    dailyHitLoading: true,
    yearlyHitLoading: true,
    hourlyLinkShareLoading: true,
    dailyLinkShareLoading: true,
    yearlyLinkShareLoading: true,
  });

  const [data, setData] = useState({
    hourlyHitCounts: [],
    dailyHitCounts: [],
    yearlyHitCounts: [],
    hourlyLinkShareCounts: [],
    dailyLinkShareCounts: [],
    yearlyLinkShareCounts: [],
  });

  const [labels, setLabels] = useState({
    hourLabels: [],
    dayLabels: [],
    yearLabels: [],
  });

  const url = localStorage.getItem("slugUrl");
  const authtoken = localStorage.getItem("token");
  const today = new Date();
  const todayDate = today.toISOString().split("T")[0];
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();

  const apiHeaders = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + authtoken,
  };

  const fetchData = async (endpoint, timePeriod, body = {}) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/${endpoint}?candidateName=${url}`, body, {
        headers: apiHeaders,
      });
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        const loginURL = sessionStorage.getItem("candidateLoginURL");
        window.location.href = loginURL || process.env.REACT_APP_PROD_API_URL;
      }
      throw error;
    }
  };

  const fetchAllData = async (candidateName) => {
    try {
      const hourlyHitData = await fetchData("GetUrlHitCountsForDate", "hourly", { date: todayDate });
      setData((prevData) => ({
        ...prevData,
        hourlyHitCounts: hourlyHitData.map((d) => d.hitCount),
      }));
      setLabels((prevLabels) => ({
        ...prevLabels,
        hourLabels: hourlyHitData.map((d) => d.timeUnit),
      }));
      setLoadingStates((prevStates) => ({ ...prevStates, hourlyHitLoading: false }));

      const dailyHitData = await fetchData("GetUrlHitCountsForMonth", "daily", { month: currentMonth });
      setData((prevData) => ({
        ...prevData,
        dailyHitCounts: dailyHitData.map((d) => d.hitCount),
      }));
      setLabels((prevLabels) => ({
        ...prevLabels,
        dayLabels: dailyHitData.map((d) => d.timeUnit),
      }));
      setLoadingStates((prevStates) => ({ ...prevStates, dailyHitLoading: false }));

      const yearlyHitData = await fetchData("GetUrlHitCountsForYear", "yearly", { year: currentYear });
      setData((prevData) => ({
        ...prevData,
        yearlyHitCounts: yearlyHitData.map((d) => d.hitCount),
      }));
      setLabels((prevLabels) => ({
        ...prevLabels,
        yearLabels: yearlyHitData.map((d) => d.timeUnit),
      }));
      setLoadingStates((prevStates) => ({ ...prevStates, yearlyHitLoading: false }));

      const hourlyLinkShareData = await fetchData("GetLinkShareCountsForDate", "hourly", { date: todayDate });
      setData((prevData) => ({
        ...prevData,
        hourlyLinkShareCounts: hourlyLinkShareData.map((d) => d.hitCount),
      }));
      setLoadingStates((prevStates) => ({ ...prevStates, hourlyLinkShareLoading: false }));

      fetchData("GetLinkShareCountsForMonth", "daily", { month: currentMonth })
        .then((dailyLinkShareData) => {
          setData((prevData) => ({
            ...prevData,
            dailyLinkShareCounts: dailyLinkShareData.map((d) => d.hitCount),
          }));
          setLoadingStates((prevStates) => ({ ...prevStates, dailyLinkShareLoading: false }));
        })
        .catch((error) => {
          console.error("Error fetching daily link share data:", error);
        });

      const yearlyLinkShareData = await fetchData("GetLinkShareCountsForYear", "yearly", { year: currentYear });
      setData((prevData) => ({
        ...prevData,
        yearlyLinkShareCounts: yearlyLinkShareData.map((d) => d.hitCount),
      }));
      setLoadingStates((prevStates) => ({ ...prevStates, yearlyLinkShareLoading: false }));
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const decoded = jwtDecode(token);
    fetchAllData(decoded.UserName);
  }, []);

  const chartOptions = (min, max, stepSize) => ({
    scales: {
      x: {
        ticks: { color: "white" },
      },
      y: {
        ticks: {
          color: "white",
          stepSize,
          beginAtZero: true,
          callback: (value) => (Number.isInteger(value) ? value : null),
        },
        min,
        max,
      },
    },
  });

  const onDashboardClick = () => navigate("/candidatedashboard");

  return (
    <div style={{ marginTop: "20px", marginRight: "20px", marginLeft: "20px" }}>
      <div className="sticky-header">
        <div className="row mt-2">
          <div className="col-10 d-flex align-items-center">
            <Button icon="pi pi-chevron-left" severity="secondary" onClick={onDashboardClick} className="back-button" />
            <label className="form-label Polling text-light labelDashboard marLeftHeader mt-2">क्यू आर स्कॅन/लिंक</label>
          </div>
          <div className="col-2 text-end mt-2">
            <img
              src={home}
              alt="Home"
              className="home-img float-end"
              onClick={onDashboardClick}
              style={{ marginBottom: "4px", marginLeft: "10px" }}
            />
          </div>
        </div>
      </div>

      <div className="row">
        {["hourlyHitCounts", "dailyHitCounts", "yearlyHitCounts"].map((dataKey, index) => (
          <div className="col-12 col-md-4 mb-2" key={index}>
            <div style={{ border: "1px solid #ccc", padding: "10px", borderRadius: "8px" }}>
              <h4 style={{ color: "#c8c8c8" }}>{["आजचे स्कॅन", "तारखांनुसार स्कॅन", "महिन्यानुसार स्कॅन"][index]}</h4>
              {loadingStates[[`hourlyHitLoading`, `dailyHitLoading`, `yearlyHitLoading`][index]] ? (
                <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
              ) : (
                <Line
                  data={{
                    labels: labels[["hourLabels", "dayLabels", "yearLabels"][index]],
                    datasets: [
                      {
                        label: ["Hourly", "Daily", "Yearly"][index] + " Hit Count",
                        data: data[dataKey],
                        backgroundColor: ["rgba(0, 122, 255, 0.3)", "rgba(153, 102, 255, 0.3)", "rgba(255, 99, 132, 0.3)"][index],
                        borderColor: ["#007aff", "rgba(153, 102, 255, 1)", "rgba(255, 99, 132, 1)"][index],
                        borderWidth: 1,
                      },
                    ],
                  }}
                  options={chartOptions([0, 100, 1000][index], [100, 1000, 10000][index], [10, 100, 1000][index])}
                />
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="row">
        {["hourlyLinkShareCounts", "dailyLinkShareCounts", "yearlyLinkShareCounts"].map((dataKey, index) => (
          <div className="col-12 col-md-4 mb-2" key={index}>
            <div style={{ border: "1px solid #ccc", padding: "10px", borderRadius: "8px" }}>
              <h4 style={{ color: "#c8c8c8" }}>{["आजचे शेअर्स", "तारखांनुसार शेअर्स", "महिन्यानुसार शेअर्स"][index]}</h4>
              {loadingStates[[`hourlyLinkShareLoading`, `dailyLinkShareLoading`, `yearlyLinkShareLoading`][index]] ? (
                <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
              ) : (
                <Line
                  data={{
                    labels: labels[["hourLabels", "dayLabels", "yearLabels"][index]],
                    datasets: [
                      {
                        label: ["Hourly", "Daily", "Yearly"][index] + " Link Share Count",
                        data: data[dataKey],
                        backgroundColor: ["rgba(0, 122, 255, 0.3)", "rgba(153, 102, 255, 0.3)", "rgba(255, 99, 132, 0.3)"][index],
                        borderColor: ["#007aff", "rgba(153, 102, 255, 1)", "rgba(255, 99, 132, 1)"][index],
                        borderWidth: 1,
                      },
                    ],
                  }}
                  options={chartOptions([0, 100, 1000][index], [100, 1000, 10000][index], [10, 100, 1000][index])}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GraphForCount;
