import React, { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import home from "../Assets/Images/home_icon.svg";
import { useNavigate } from "react-router-dom";

export default function DeadVoters() {
  const navigate = useNavigate();
  const [candidateName, setCandidateName] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const [scrollMore, setScrollMore] = useState(true);
  const [votersListLoading, setVotersListLoading] = useState(false);
  const [showVoterInfo, setShowVoterInfo] = useState(false);
  const [voterDetailsList, setVoterDetailsList] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const authtoken = localStorage.getItem("token");

  useEffect(() => {
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      let candidateName = decoded.UserName;
      setCandidateName(candidateName);
      getDeadVotersList(1, true, candidateName);
    }
  }, []);
  const getDeadVotersList = (pageno, replace, candidateNm) => {
    if (isApiCallInProgress) return;
    if (replace) {
      setVoterDetailsList([]);
      setTotalCount(0);
    }
    setIsApiCallInProgress(true);
    setVotersListLoading(true);

    const data = {
      boothAddress: "",
      pollingAddress: "",
      fromValue: 0,
      toValue: 0,
      isGetAllValue: false,
      searchPageNumber: pageno,
      searchResultCount: 20,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getdeadvoter?candidateName=${candidateNm}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setVotersListLoading(false);
        if (response.data.response.length > 0) {
          let searchedList = replace ? response.data.response : [...voterDetailsList, ...response.data.response];
          setVoterDetailsList(searchedList);
          setTotalCount(response.data.response[0].totalCount);
        }
        setShowVoterInfo(true);
        if (response.data.response.length > 0) {
          setScrollMore(true);
        } else {
          setScrollMore(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
        setIsApiCallInProgress(false);
        setVotersListLoading(false);
      })
      .finally(() => {
        setIsApiCallInProgress(false);
      });
  };
  const onShowmoreClicked = () => {
    if (!isApiCallInProgress && scrollMore) {
      const nextPageNumber = pageNumber + 1;
      setPageNumber(nextPageNumber);
      getDeadVotersList(nextPageNumber, false, candidateName);
    }
  };
  const onDashboardclick = () => {
    navigate("/candidatedashboard");
  };
  return (
    <div className="app main-container-login">
      <div className="header">
        <div className="row mt-2">
          <div className="col-10">
            <label htmlFor="Constituency" className="form-label Polling text-light labelDashboard">
              मयत मतदार
            </label>
          </div>
          <div className="col-2 text-end">
            <img
              src={home}
              alt="Whatsapp"
              className="home-img float-end"
              onClick={onDashboardclick}
              style={{ marginBottom: "4px", marginLeft: "10px" }}
            />
          </div>
        </div>

        {showVoterInfo ? (
          <BottomScrollListener onBottom={onShowmoreClicked}>
            {(scrollRef) => (
              <div className="mt-2" ref={scrollRef} style={{ height: "650px", overflowY: "auto", overflowX: "hidden" }}>
                {voterDetailsList.map((item, number) => (
                  <div
                    className={
                      voterDetailsList.length == number + 1 && votersListLoading == false
                        ? " card mb-2  voter-list-card last-card-bottom"
                        : "card mb-2  voter-list-card"
                    }
                    key={number}
                  >
                    <div className="row d-flex  mx-auto ">
                      <div className="col-4 ps-0 mb-1">
                        <span className="fw-bold voter-list-label"> {item.slipNumber}</span>
                      </div>
                      <div className="col-5 p-0 mb-1">
                        <span className="voter-list-answer">{item.voterId}</span>
                      </div>

                      <div className="col-4 ps-0 mb-1">
                        <span className="fw-bold voter-list-label">नाव</span>
                      </div>
                      <div className="col-8 ps-0 mb-1">
                        <span className="voter-list-answer">
                          {item.lastName} {item.firstName} {item.middleName}
                        </span>
                      </div>

                      <div className="col-4 ps-0 mb-1">
                        <span className="fw-bold voter-list-label">फोन नंबर</span>
                      </div>
                      <div className="col-8 ps-0 mb-1">
                        <span className="voter-list-answer">{item.mobileNo}</span>
                      </div>

                      <div className="col-4 ps-0 mb-1">
                        <span className="fw-bold voter-list-label">पत्ता</span>
                      </div>
                      <div className="col-8 ps-0 mb-1">
                        <span className="voter-list-answer">
                          {item.wordName}, {item.district}
                        </span>
                      </div>

                      <div className="col-4 ps-0 mb-1">
                        <span className="fw-bold voter-list-label">घर क्रमांक</span>
                      </div>
                      <div className="col-8 ps-0 mb-1">
                        <span className="voter-list-answer">{item.houseNumber}</span>
                      </div>
                      <div className="col-4 ps-0 mb-1">
                        <span className="fw-bold voter-list-label">वय</span>
                      </div>
                      <div className="col-3 ps-0 mb-1 ">
                        <span className="voter-list-answer">{item.age}</span>
                      </div>
                      <div className="col-2 mb-1">
                        <span className="fw-bold voter-list-label"> लिंग</span>
                      </div>
                      <div className="col-3 ps-0 mb-1">
                        <span className="voter-list-answer">{item.gender}</span>
                      </div>
                      <div className="col-4 ps-0 mb-1">
                        <span className="fw-bold voter-list-label">जन्मदिनांक</span>
                      </div>
                      <div className="col-8 ps-0 mb-1">
                        <span className="voter-list-answer">
                          {item.birthDate != "" && item.birthDate != null ? moment(item.birthDate).format("YYYY-MM-DD") : ""}
                        </span>
                      </div>

                      <div className="col-4 ps-0 mb-1">
                        <span className="fw-bold voter-list-label">कुटुंब सदस्य</span>
                      </div>
                      <div className="col-3 ps-0 pe-0 mb-1 ">
                        <span>{item.familyMemberCount}</span>
                      </div>
                      <div className="col-2 mb-1">
                        <span className="fw-bold voter-list-label">जात</span>
                      </div>
                      <div className="col-3 ps-0 mb-1">
                        <span>{item.caste}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </BottomScrollListener>
        ) : null}

        {votersListLoading ? (
          <div className="d-flex justify-content-center">
            <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
